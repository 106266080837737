import HeaderFooterTemplate from '../headerFooterTemplate/HeaderFooterTemplate';
import { Button, Grid } from 'carbon-components-react';
import { Formik, Form } from 'formik';
import Stepper from './Stepper';
import { useEffect, useRef, useState } from 'react';
import { Colors } from '../../utils/styleUtils/Colors';
import LeftArrow from '../../assets/left-arrow.svg';
import CallIcon from '../../assets/call-24px.svg';
import HourglassIcon from '../../assets/hourglass.svg';
import SelectAuthenticationMethod from './SelectAuthenticationMethod';
import formModel from './formModel/model';
import initialValues from './formModel/initialValues';
import BasicInfoForm from './forms/BasicInfoForm';
import FinalForm from './forms/FinalForm';
import AdditionalInfoForm from './forms/AdditionalInfoForm';
import createValidation from './formModel/validationSchema';
import SuccessInfo from './forms/SuccessInfo';
import QRorUploadPhotoPage from '../idVerification/QRorUploadPhotoPage';
import AgreementCheckboxes from '../applicationPage/forms/AgreementCheckboxes';
import HelpModal from './forms/HelpModal';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Dimension } from '../../utils/styleUtils/Dimension';
import useComponentWillMount from '../../hooks/useComponentWillMount';
import { MobilePhotoVerificationStatus } from '../../utils/consts';
import { socket } from '../../utils/socketIoConnection';
import FailInfo from './forms/FailInfo';
import BiometricAgreementModal from '../idVerification/BiometricAgreementModal';

const { formId, formField } = formModel;

const ApplicationPage = props => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [authIndex, setAuthIndex] = useState(0);
    const [selectedAuthMethod, setSelectedAuthMethod] = useState('FORM');
    const [authenticationFinished, setAuthenticationFinished] = useState(false);
    const [submitted, setSubmitted] = useState({
        status: false,
        moneyPickup: '',
        member: undefined,
    });
    const [updateValues, setUpdateValues] = useState({});
    const [helpOpen, setHelpOpen] = useState(false);
    const [biometricModalOpen, setBiometricModalOpen] = useState(false);
    const [mobilePhotoVerificationStatus, setMobilePhotoVerificationStatus] = useState(MobilePhotoVerificationStatus.NOT_STARTED)
    const [verificationStatusMsg, setVerificationStatusMsg] = useState('Trwa wczytywanie zdjęć...');
    const mobile = useRef(false);
    const isLastStep = currentIndex === 3; 
    let currentValidationSchema = authenticationFinished ? createValidation(formField.zgody)[currentIndex] : undefined;
    const { width } = useWindowDimensions();
    const [fail, setFail] = useState(false);
    const [activeLogoLink, setActiveLogoLink] = useState(true);
    
    useComponentWillMount(() => {
        if (props.location.state?.prevPath) {
            const stateUpdated = { ...props.location.state, prevPath: undefined };
            props.history.replace({ ...props.location, state: stateUpdated });
        } else {
            props.history.push('/');
        };
    });

    useEffect(() => {
        window.scrollTo(0, 0);

        const hash = props.location.hash.substring(1);
        if (window.location.hostname !== 'localhost'){
            socket.on("read_status", (data) => {
                if (data.status === MobilePhotoVerificationStatus.STARTED) {
                    mobile.current = true;
                    setMobilePhotoVerificationStatus(MobilePhotoVerificationStatus.STARTED);
                    setVerificationStatusMsg('Trwa wczytywanie zdjęć...');
                } else if (data.status === MobilePhotoVerificationStatus.SUCCESS) {
                    setMobilePhotoVerificationStatus(MobilePhotoVerificationStatus.SUCCESS);
                    handlePhotoVerificationSuccess(data);
                } else if (data.status === MobilePhotoVerificationStatus.PREPROCESSING) setVerificationStatusMsg('Trwa przygotowywanie zdjęć do odczytu...');
                else if (data.status === MobilePhotoVerificationStatus.PROCESSING) setVerificationStatusMsg('Trwa odczytywanie danych ze zdjęć...')
                else if (data.status === MobilePhotoVerificationStatus.POSTPROCESSING) setVerificationStatusMsg('Trwa końcowe sprawdzenie danych...')
                else if (data.status === MobilePhotoVerificationStatus.FRONT_PROCESSING) setVerificationStatusMsg('Trwa odczytywanie danych z przodu dowodu...')
                else if (data.status === MobilePhotoVerificationStatus.BACK_PROCESSING) setVerificationStatusMsg('Trwa odczytywanie danych z tyłu dowodu...')
                else if (data.status === MobilePhotoVerificationStatus.GO_TO_FORM) setAuthenticationFinished(true);
                else if (data.status === MobilePhotoVerificationStatus.FAIL) setMobilePhotoVerificationStatus(MobilePhotoVerificationStatus.FAIL);
            })
            socket.emit("join_to_room", hash);
        }
        fetch('/api/offer/get-zgody', {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(output => output.json())
        .then(zgody => {
            console.log('ZGODY: ', zgody);
            zgody.dane.forEach(elem => {
                formField.zgody[elem.idZgody] = { 
                    name: elem.idZgody, 
                    label: elem.tresc,
                    requiredErrorMsg: 'Ta zgoda jest wymagana', 
                    required: elem.czyObowiazkowa 
                };
                initialValues.zgody = {...initialValues.zgody, [elem.idZgody]: false};
            });
        })
    }, []);

    useEffect(()=>{
        if(authenticationFinished) setActiveLogoLink(false);
        if(submitted.status) setActiveLogoLink(true);
    }, [submitted.status, authenticationFinished])
    
    const renderButtonName = index => {
        switch(index){
            case 3:
                return "Złóż wniosek"
            case 2:
                return "Prześlij dane"
            default:
                return "Dalej"
        }
    }

    const handlePhotoVerificationSuccess = data => {
        console.log('Mobile: ', mobile);
        if (Object.keys(data).length > 0) {
            initialValues.firstName = data.firstName;
            initialValues.lastName = data.lastName;
            initialValues.idNumber = data.idNumber;
            initialValues.expirationDate = data.expirationDate;
            initialValues.issueDate = data.issueDate;
            initialValues.pesel = data.pesel;
            initialValues.documentType = 'dowód osobisty';
            if (data.phone) {
                initialValues.phone = data.phone;
                initialValues.phoneVerified = true;
            };
        }
        if (mobile.current) {
            setAuthenticationFinished(true);
        }
    }

    const renderAuthStep = idx => {
        switch (idx) {
            case 1:
                return (
                <>
                    <QRorUploadPhotoPage 
                        status={mobilePhotoVerificationStatus} 
                        goToForm={() => setAuthenticationFinished(true)} 
                        onReset={() => setMobilePhotoVerificationStatus(MobilePhotoVerificationStatus.NOT_STARTED)}
                        msg={verificationStatusMsg} 
                    />
                    <BiometricAgreementModal
                        open={biometricModalOpen} 
                        onClose={() => setBiometricModalOpen(false)} 
                        setCurrentIndex={() => setCurrentIndex(0)}
                        setAuthIndex={() => setAuthIndex(0)}
                    />
                </>
                );
            default:
                return <></>;
        }
    }

    const renderContent = (idx, setFieldValue) => {
        switch (idx) {
            case 0:
                return <BasicInfoForm formField={formField} />
            case 1:
                return <AdditionalInfoForm formField={formField} />
            case 2:
                return <AgreementCheckboxes formField={formField} setFieldValue={setFieldValue} />
            case 3:
                return <FinalForm formField={formField} location={props.location} onUpdateValues={setUpdateValues} updateValues={updateValues} setFail={setFail}/>;
            default:
                return <></>
        }
    }

    const handleNext = (values, actions) => {
        if (currentIndex === 0 && selectedAuthMethod === 'FORM' && !authenticationFinished) { 
            setAuthenticationFinished(true);
            actions.setTouched({});
            actions.setSubmitting(false);
            window.scrollTo(0, 0);
        } else if (currentIndex === 0 && selectedAuthMethod === 'ID' && !authenticationFinished) {
            setAuthIndex(authIndex + 1);
            setBiometricModalOpen(true);
            actions.setTouched({});
            actions.setSubmitting(false);
            window.scrollTo(0, 0);
        } else if (isLastStep) {
            submitForm(values);
        } else {
            setCurrentIndex(currentIndex + 1);
            actions.setTouched({});
            actions.setSubmitting(false);
            window.scrollTo(0, 0);
        }
    }

    const submitForm = values => {
        console.log('SUBMITTING VALUES: ', updateValues);
        console.log('VALUES: ', values);
        const dateSplit = values.visitDay.split('.');
        const visitDate = dateSplit[2] + '-' + dateSplit[1] + '-' + dateSplit[0];
        fetch(`/api/offer/update-form`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                updateData: {
                    kwota: updateValues.amount,
                    okres: updateValues.numberOfInstallments,
                    kwotaRaty: updateValues.installmentAmount,
                    prowizja: updateValues.commissionValue,
                    wybranyOddzialWojewodztwo: updateValues.branchVoivodeship,
                    wybranyOddzial: updateValues.branch,
                    dataKontaktu: visitDate,
                    oprocentowanie: updateValues.interest,
                    prowizjaProcentowo: updateValues.commission,
                    kwotaBrutto: updateValues.grossAmount,
                    zgody: Object.keys(values.zgody).filter(key => values.zgody[key]).map(id => parseInt(id)),
                    zgodanaror: values.bill === 'yes' 
                },
                link: updateValues.link,
                values,
            }),
        })
        .then(output => output.json())
        .then(json => {
            setSubmitted({
                status: true,
                moneyPickup: values.moneyPickup,
                member: values.member === 'tak',
                phone: values.phone,
                link: updateValues.link,
                branch: updateValues.fullBranch,
                visitDay: values.visitDay,
            });
            window.scrollTo(0, 0);
        })

    }

    const handlePrev = () => {
        if (authIndex > 0 && !authenticationFinished) {
            setAuthIndex(authIndex - 1);
        } else if (currentIndex === 0) {
            setAuthenticationFinished(false);
        } else {
            setCurrentIndex(currentIndex - 1);
        }
        window.scrollTo(0, 0);
    }

    const wrapper = () => (
        <Grid style={styles.root}>
            {
                fail 
                ? (
                    <FailInfo/>
                ) 
                : (
                    submitted.status
                    ? (
                        <SuccessInfo {...submitted} />
                    )
                    : (
                        <>
                            <Stepper current={currentIndex} />
                            <Formik
                                initialValues={initialValues}
                                onSubmit={(values, actions) => handleNext(values, actions)}    
                                validationSchema={currentValidationSchema}
                            >
                                {({ isSubmitting, setFieldValue, handleSubmit }) => (
                                    <Form id={formId} style={styles.form}>
                                        { 
                                            !authenticationFinished 
                                            && authIndex === 0 
                                            && mobilePhotoVerificationStatus === MobilePhotoVerificationStatus.NOT_STARTED 
                                            && (
                                            <SelectAuthenticationMethod
                                                selectedAuthMethod={selectedAuthMethod}
                                                handleMethodChange={setSelectedAuthMethod}
                                                handleSubmit={handleSubmit}
                                            />
                                        )}
                                        { 
                                            authenticationFinished
                                            ? renderContent(currentIndex, setFieldValue) 
                                            : (mobile.current ? <></> : renderAuthStep(authIndex))
                                        }
                                        {
                                            mobile.current && !authenticationFinished && (
                                                <div style={styles.mobileVerificationDiv}>
                                                    <p style={styles.title}>Rozpoczęto weryfikację na urządzeniu mobilnym.</p>
                                                    <img src={HourglassIcon} alt="hourglass" style={styles.hourglassIcon}/>
                                                </div>
                                            )
                                        }
                                        <div style={width > Dimension.sm ? styles.flex : {...styles.flex, flexDirection: 'column-reverse', alignItems: 'center'}}>
                                            <div style={styles.flex1}>
                                                <Button style={width > Dimension.sm ? styles.buttonHelp : {...styles.buttonHelp, marginTop: 16}} onClick={() => setHelpOpen(true)}>
                                                    <img src={CallIcon} alt="" style={styles.icon}/>
                                                    Pomoc
                                                </Button>
                                            </div>
                                            <div>
                                                { (currentIndex > 0 || authIndex > 0 || authenticationFinished) && !isLastStep && (
                                                    <Button 
                                                        style={width > Dimension.sm ? {...styles.buttonCommon, ...styles.buttonPrev} : {...styles.buttonCommon, ...styles.buttonPrev, ...styles.buttonSm}} 
                                                        onClick={handlePrev}
                                                    >
                                                        <img src={LeftArrow} alt="" style={styles.icon}/>
                                                        Wstecz
                                                    </Button>
                                                )}
                                                {
                                                    isLastStep && Object.keys(updateValues).length === 0
                                                    ? <></>
                                                    : <Button 
                                                        style={width > Dimension.sm ? {...styles.buttonCommon, ...styles.buttonNext} : {...styles.buttonCommon, ...styles.buttonNext, ...styles.buttonSm}} 
                                                        disabled={isSubmitting}
                                                        onClick={handleSubmit}
                                                    >
                                                        {renderButtonName(currentIndex)}
                                                    </Button>
                                                }
                                            </div>
                                            <div style={styles.flex1}></div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                            <HelpModal open={helpOpen} onClose={() => setHelpOpen(false)} />
                        </>
                    )
                )
            }
        </Grid>
    )
    return (
        <HeaderFooterTemplate content={wrapper} activeLogoLink={activeLogoLink}/>
    )
}

const styles = {
    root: {
        background: Colors.white,
        borderRadius: 16,
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        width: '100%',
        marginBottom: 32,
        marginTop: 12,
        padding: 32,
        paddingTop: 24,
        boxShadow: `0px 3.40014px 85.0036px ${Colors.boxShadowsColor}`,
        alignItems: 'center',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        alignItems: 'center',
        width: '100%',
    },
    buttonCommon: {
        padding: 13,
        paddingLeft: 24,
        paddingRight: 24,
        marginLeft: 20,
        marginRight: 20,
        borderWidth: 0,
        textAlign: 'center',
        fontSize: 18,
    },
    buttonNext: {
        backgroundColor: Colors.orange,
        borderRadius: 4,
        fontWeight: 600,
        textDecoration: 'none',
        color: Colors.white,
    },
    buttonSm: {
        marginLeft: 8,
        marginRight: 8,
        paddingLeft: 16,
        paddingRight: 16,
    },
    buttonPrev: {
        backgroundColor: Colors.white,
        color: Colors.green,
        fontWeight: 500,
    },
    buttonHelp: {
        background: Colors.white,
        border: `2px solid ${Colors.mystic}`,
        borderRadius: 6,
        color: Colors.lightGreen,
        fontSize: 18,
        fontWeight: 500,
        paddingLeft: 16,
        paddingRight: 16,
    },
    flex: {
        marginTop: 'auto',
        display: 'flex',
        width: '100%',
        paddingTop: 16,
    },
    flex1: {
        flex: 1,
    },
    icon: {
        paddingRight: 12,
    },
    mobileVerificationDiv: {
        display: 'flex',
        flexDirection: 'column',
        background: Colors.mobileColor,
        padding: 24,
        borderRadius: 24,
        marginTop: 'auto',
    },
    hourglassIcon: {
        height: 120,
    },
    title: {
        fontWeight: 600,
        fontSize: 18,
        marginBottom: 16,
    }
}

export default ApplicationPage;
