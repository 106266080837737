export const Colors = Object.freeze({
    // Normal colors
    green: '#00652E',
    white: '#FFFFFF',
    chineseWhite: '#DDE8E5',
    mystic: '#E2EBEB',
    lightGreen: '#338458',
    black: '#212623',
    orange: '#F26133',
    grey: '#808080',
    lightGrey: '#DCDCDC',

    // Box shadows colors
    boxShadowsColor: '#26593d21', // rgba(38, 89, 61, 0.13)
    boxShadowsLighterColor: '#0b21151a', // rgba(11, 33, 21, 0.1)
    boxShadowsAuthenticationColor: '#26593d1a', // rgba(38, 89, 61, 0.1)

    // Background colors
    authenticationBackground: '#dde8e580',
    footerBackground: '#00652e1a',
    failInfoBackground: '#ffd4d480',
    
    // Error colors - red
    error: '#da1e28',
    errorText: '#ff0000',

    // Branch modal colors
    branchesListItemBackground: '#f0fdf74d',
    branchesListItemBorder: '#007a1b4d',
    branchesListItemBackgroundSelected: '#f0fdf7',
    branchesListItemBorderSelected: '#007a1b',
    lightGreyBranchSelect: '#f4f4f4',

    // Other colors
    borderColor: '#EAEAEA',
    borderBottomColor: '#e2e2e2',
    mobileColor: '#f1f1f1',
    verifiedDiv: '#33845833',
    loadingBackground: '#F3F6F7',
    progressBarContainer: '#BCC9CD',
    externalColor: '#006E49'
});
