import { useState } from 'react';
import { Button, Modal } from 'carbon-components-react';
import { Colors } from '../../../utils/styleUtils/Colors';
import CallIcon from '../../../assets/call-24px.svg';
import './HelpModal.scss';
import SendNumber from './SendNumber';
import { Dimension } from "../../../utils/styleUtils/Dimension";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const HelpModal = props => {
    const { width } = useWindowDimensions();
    const [sent, setSent] = useState(false);

    return (
        <Modal className="help-modal" size="lg" open={props.open} onRequestClose={props.onClose} passiveModal>
            <p style={styles.fontMedium}>Masz problem z wypełnieniem formularza? Skontaktuj się z obsługą:</p>
            <span style={styles.phoneSpan}>
                <img src={CallIcon} alt="" style={styles.icon}/>
                <p style={styles.fontNumber}>
                    {width > Dimension.sm ? '81 446 30 00' : <a href="tel:81446-30-00" style={styles.link}>81 446 30 00</a>}
                </p>
            </span>
            <p style={styles.fontNormal}>Od poniedziałku do piątku w godzinach 8.00 - 17.00</p>
            {sent 
                ? <></> 
                : <p style={width > Dimension.sm ? styles.fontBold : {...styles.fontBold, whiteSpace: 'pre-wrap'}}>
                    Lub zostaw numer telefonu, a oddzwonimy
                    </p>
            }
            <SendNumber setNumberSent={setSent}/>
            <Button style={styles.closeButton} onClick={props.onClose}>Zamknij</Button>
        </Modal>
    )
};

const styles = {
    fontMedium: {
        fontWeight: 500,
        fontSize: 18,
        color: Colors.black,
        textAlign: 'center',
    },
    fontNumber: {
        fontWeight: 'bold',
        fontSize: 21,
        color: Colors.black,
        textAlign: 'center',
        whiteSpace: 'nowrap',
    },
    fontBold: {
        fontWeight: 'bold',
        fontSize: 18,
        marginTop: 20,
        color: Colors.black,
        textAlign: 'center',
        whiteSpace: 'nowrap',
    },
    fontNormal: {
        lineHeight: 1.3,
    },
    phoneSpan: {
        marginTop: 24,
        marginBottom: 12,
        display: 'flex',
    },
    icon: {
        marginRight: 4,
    },
    closeButton: {
        backgroundColor: Colors.white,
        fontWeight: 500,
        fontSize: 18,
        color: Colors.green,
        textAlign: 'center',
        padding: 12,
        textDecoration: 'none',
        borderRadius: 4,
        borderWidth: 0,
    },
    link: {
        color: Colors.black,
    },
}

export default HelpModal;
