import { ComposedModal, Grid, Row, Column, ModalBody, ModalHeader } from "carbon-components-react";
import { Colors } from "../../utils/styleUtils/Colors";

const DetailsModal = props => {
    const {
        amount,
        numberOfInstallments,
        rrso,
        installmentAmount,
        interest,
        commissionValue,
        grossAmount
    } = props;
    
    return (
        <ComposedModal size="lg" open={props.open} onClose={props.onClose} style={styles.modal}>
            <ModalHeader style={styles.header}>Szczegóły pożyczki</ModalHeader>
            <ModalBody style={styles.modalBody}>
                <Grid>
                    <Row style={styles.row}>
                        <Column sm={2} md={5} lg={4} style={styles.col}>
                            <p style={styles.name}>Kwota jakiej potrzebujesz</p>
                        </Column>
                        <Column sm={2} md={3} lg={2} style={styles.col}>
                            <p style={styles.value}>
                                {parseFloat(amount).toFixed(0)} zł
                            </p>
                        </Column>
                        <Column sm={2} md={5} lg={4} style={styles.col}>
                            <p style={styles.name}>Kwota pożyczki</p>
                        </Column>
                        <Column sm={2} md={3} lg={2} style={styles.col}>
                            <p style={styles.value}>
                                {parseFloat(grossAmount).toFixed(2)} zł
                            </p>
                        </Column>
                    </Row>
                    <Row style={styles.row}>
                        <Column sm={2} md={5} lg={4} style={styles.col}>
                            <p style={styles.name}>Liczba rat</p>
                        </Column>
                        <Column sm={2} md={3} lg={2} style={styles.col}>
                            <p style={styles.value}>{parseInt(numberOfInstallments)}</p>
                        </Column>
                        <Column sm={2} md={5} lg={4} style={styles.col}>
                            <p style={styles.name}>Prowizja</p>
                        </Column>
                        <Column sm={2} md={3} lg={2} style={styles.col}>
                            <p style={styles.value}>
                                {parseFloat(commissionValue).toFixed(2)} zł
                            </p>
                        </Column>
                    </Row>
                    <Row style={styles.row}>
                        <Column sm={2} md={5} lg={4} style={styles.col}>
                            <p style={styles.name}>Wysokość miesięcznej raty*</p>
                        </Column>
                        <Column sm={2} md={3} lg={2} style={styles.col}>
                            <p style={styles.value}>
                                {parseFloat(installmentAmount).toFixed(2)} zł
                            </p>
                        </Column>
                        <Column sm={2} md={5} lg={4} style={styles.col}>
                            <p style={styles.name}>Oprocentowanie</p>
                        </Column>
                        <Column sm={2} md={3} lg={2} style={styles.col}>
                            <p style={styles.value}>
                            {parseFloat(interest * 100).toFixed(2)} %
                            </p>
                        </Column>
                    </Row>
                    <Row>
                        <Column sm={2} md={5} lg={4} style={styles.col}>
                            <p style={styles.name}>RRSO</p>
                        </Column>
                        <Column sm={2} md={3} lg={2} style={styles.col}>
                            <p style={styles.value}>
                                {parseFloat(rrso).toFixed(2)} %
                            </p>
                        </Column>
                    </Row>
                    <Row>
                        <Column>
                            <p style={styles.note}>
                                * Wysokość miesięcznej raty uzależniona jest od dnia uruchomienia pożyczki i może się nieznacznie różnić
                            </p>
                        </Column>
                    </Row>
                </Grid>
            </ModalBody>
        </ComposedModal>
    )
};

const styles = {
    modal: {
        padding: 16,
    },
    modalBody: {
        margin: 0,
    },
    row: {
        display: 'flex',
        alignItems: 'center',
    },
    col: {
        marginTop: 14,
    },
    header: {
        fontSize: 21,
        marginLeft: 12,
        marginRight: 12,
        borderBottom: Colors.borderBottomColor,
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        paddingBottom: 12,
    },
    name: {
        fontSize: 14,
    },
    value: {
        fontSize: 22,
        whiteSpace: 'nowrap'
    },
    note: {
        fontSize: '0.75rem', 
        marginTop: '0.75rem', 
        marginBottom: '1rem',
    }
}

export default DetailsModal;
