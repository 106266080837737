const FormModel = {
    formId: 'basicInfoForm',
    formField: {
        firstName: {
            name: 'firstName',
            label: 'Imię',
            requiredErrorMsg: 'Imię jest wymagane'
        },
        lastName: {
            name: 'lastName',
            label: 'Nazwisko',
            requiredErrorMsg: 'Nazwisko jest wymagane'
        },
        pesel: {
            name: 'pesel',
            label: 'PESEL',
            requiredErrorMsg: 'PESEL jest wymagany',
            invalidErrorMsg: 'Podany PESEL jest niepoprawny'
        },
        nationality: {
            name: 'nationality',
            label: 'Narodowość',
            requiredErrorMsg: 'Narodowość jest wymagana'
        },
        documentType: {
            name: 'documentType',
            label: 'Typ dokumentu',
            requiredErrorMsg: 'Typ dokumentu jest wymagany'
        },
        idNumber: {
            name: 'idNumber',
            label: 'Numer i seria dokumentu',
            requiredErrorMsg: 'Numer dokumentu jest wymagany',
            invalidErrorMsg: 'Podany numer jest niepoprawny (Dowód: np. ABC 123456, Paszport: np. AB 1234567)',
            checksumErrorMsg: 'Niepoprawny numer dokumentu'
        },
        issueDate: {
            name: 'issueDate',
            label: 'Data wydania',
            requiredErrorMsg: 'Data wydania jest wymagana',
            invalidErrorMsg: 'Podana data jest niepoprawna (np. 14.05.2024)'
        },
        expirationDate: {
            name: 'expirationDate',
            label: 'Termin ważności',
            requiredErrorMsg: 'Termin ważności jest wymagany',
            invalidErrorMsg: 'Podana data jest niepoprawna (np. 14.05.2024)'
        },
        indefinite: {
            name: 'indefinite',
            label: 'Bezterminowy dokument',
        },
        member: {
            name: 'member',
            label: 'Czy jesteś klientem SKOK Chmielewskiego?',
            requiredErrorMsg: 'Ta informacja jest wymagana'
        },
        street: {
            name: 'street',
            label: 'Ulica',
            requiredErrorMsg: 'Ulica jest wymagana'
        },
        houseNumber: {
            name: 'houseNumber',
            label: 'Numer domu',
            requiredErrorMsg: 'Numer domu jest wymagany'
        },
        aptNumber: {
            name: 'aptNumber',
            label: 'Numer mieszkania',
        },
        postcode: {
            name: 'postcode',
            label: 'Kod pocztowy',
            requiredErrorMsg: 'Kod pocztowy jest wymagany',
            invalidErrorMsg: 'Nieprawidłowy kod pocztowy (np. 12-345)'
        },
        city: {
            name: 'city',
            label: 'Miejscowość',
            requiredErrorMsg: 'Miejscowość jest wymagana',
        },
        phone: {
            name: 'phone',
            label: 'Numer telefonu',
            requiredErrorMsg: 'Numer telefonu jest wymagany',
            verifiedErrorMsg: 'Numer nie został zweryfikowany',
        },
        phoneVerified: {
            name: 'phoneVerified',
        },
        email: {
            name: 'email',
            label: 'Adres e-mail',
            invalidErrorMsg: 'Adres e-mail jest nieprawidłowy (np. nazwa@email.pl)',
            requiredErrorMsg: 'Adres e-mail jest wymagany'
        },
        confirmEmail: {
            name: 'confirmEmail',
            label: 'Powtórz adres e-mail',
            invalidErrorMsg: 'Adres e-mail jest nieprawidłowy (np. nazwa@email.pl)',
            requiredErrorMsg: 'Powtórzenie adresu e-mail jest wymagane',
            notMatchingErrorMsg: 'Adresy e-mail nie są takie same'
        },
        incomeSources: {
            name: 'incomeSources',
            label: 'Źródła dochodów',
        },
        incomeSource: {
            name: 'incomeSource',
            label: 'Źródło dochodu',
            requiredErrorMsg: 'Źródło dochodu jest wymagane'
        },
        nettoIncome: {
            name: 'nettoIncome',
            label: 'Miesięczny dochód netto (w PLN)',
            requiredErrorMsg: 'Dochód jest wymagany',
        },
        uopIndefinite: {
            name: 'uopIndefinite',
            label: 'Umowa na czas',
            requiredErrorMsg: 'To pole jest wymagane'
        },
        uopToDate: {
            name: 'uopToDate',
            label: 'Umowa do',
            requiredErrorMsg: 'Data końca umowy jest wymagana',
            invalidErrorMsg: 'Podana data jest niepoprawna (np. 14.05.2024)'
        },
        ownBusinessFrom: {
            name: 'ownBusinessFrom',
            label: 'Od',
            requiredErrorMsg: 'Data rozpoczęcia jest wymagana',
            invalidErrorMsg: 'Podana data jest niepoprawna (np. 14.05.2024)'
        },
        ownBusinessIndustry: {
            name: 'ownBusinessIndustry',
            label: 'Branża',
        },
        ownBusinessNIP: {
            name: 'ownBusinessNIP',
            label: 'NIP',
            requiredErrorMsg: 'NIP jest wymagany'
        },
        ownBusinessSettlementForm: { 
            name: 'ownBusinessSettlementForm',
            label: 'Forma rozliczania',
            requiredErrorMsg: 'Forma rozliczania jest wymagana'
        },
        pensionIndefinite: {
            name: 'pensionIndefinite',
            label: 'Czas',
            requiredErrorMsg: 'Określenie czasu jest wymagane'
        },
        pensionToDate: {
            name: 'pensionToDate',
            label: 'Do',
            requiredErrorMsg: 'Data ta jest wymagana',
            invalidErrorMsg: 'Podana data jest niepoprawna (np. 14.05.2024)'
        },
        preretireStartDate: {
            name: 'preretireStartDate',
            label: 'Od',
            requiredErrorMsg: 'Data ta jest wymagana',
            invalidErrorMsg: 'Podana data jest niepoprawna (np. 14.05.2024)'
        },
        employerName: {
            name: 'employerName',
            label: 'Nazwa zakładu pracy',
            requiredErrorMsg: 'Nazwa zakładu pracy jest wymagana'
        },
        employerNIP: {
            name: 'employerNIP',
            label: 'NIP pracodawcy',
            requiredErrorMsg: 'NIP jest wymagany'
        },
        employerNumber: {
            name: 'employerNumber',
            label: 'Numer telefonu do zakładu pracy',
            requiredErrorMsg: 'Numer telefonu jest wymagany',
        },
        maritalStatus: {
            name: 'maritalStatus',
            label: 'Stan cywilny',
            requiredErrorMsg: 'Stan cywilny jest wymagany'
        },
        education: {
            name: 'education',
            label: 'Wykształcenie',
            requiredErrorMsg: 'Wykształcenie jest wymagane'
        },
        housing: {
            name: 'housing',
            label: 'Status mieszkaniowy',
            requiredErrorMsg: 'Status mieszkaniowy jest wymagany'
        },
        numberOfDependents: {
            name: 'numberOfDependents',
            label: 'Liczba osób na Twoim utrzymaniu',
            requiredErrorMsg: 'Liczba osób jest wymagana',
        },
        monthlyCosts: {
            name: 'monthlyCosts',
            label: 'Stałe miesięczne koszty gospodarstwa domowego (w PLN)',
            requiredErrorMsg: 'Miesięczne koszty są wymagane',
        },
        otherCharges: {
            name: 'otherCharges',
            label: 'Czy masz inne poza bankowe obciążenia finansowe? (np. alimenty, zajęcia komornicze)',
            requiredErrorMsg: 'To pole jest wymagane',
        },
        courtCharges: {
            name: 'courtCharges',
            label: 'Sądowe (w PLN)',
            requiredErrorMsg: 'Proszę wpisać kwotę'
        },
        alimonyCharges: {
            name: 'alimonyCharges',
            label: 'Alimentacyjne (w PLN)',
            requiredErrorMsg: 'Proszę wpisać kwotę'
        },
        loanType: {
            name: 'loanType',
            label: 'Rodzaj wnioskowanej pożyczki',
            requiredErrorMsg: 'Rodzaj pożyczki jest wymagany'
        },
        moneyPickup: {
            name: 'moneyPickup',
            label: '',
            requiredErrorMsg: 'Wybierz sposób odbioru pieniędzy'
        },
        branch: {
            name: 'branch',
            label: '',
            requiredErrorMsg: 'Wybierz oddział i dzień odbioru'
        },
        visitDay: {
            name: 'visitDay',
            label: 'Dzień',
            requiredErrorMsg: 'Dzień odbioru jest wymagany'
        },
        branchModal: {
            name: 'branchModal',
            label: 'Oddział',
            requiredErrorMsg: 'Oddział jest wymagany'
        },
        bill: {
            name: 'bill',
            label: '',
            requiredErrorMsg: 'Informacja jest wymagana'
        },
        selectAllCheckbox: {
            name: 'selectAllCheckbox',
            label: 'Zaznacz wszystkie zgody',
        },
        zgody: {},
        biometricAgreement: {
            name: 'biometricAgreement',
            label: ''
        },
        powiazanieRadio: {
            name: 'powiazanieRadio',
            label: 'osobą zajmującą eksponowane stanowisko polityczne lub członkiem rodziny tej osoby lub osobą znaną jako jej bliski współpracownik. Jestem świadoma/świadomy odpowiedzialności karnej za złożenie fałszywego oświadczenia.*',
            requiredErrorMsg: 'Informacja ta jest wymagana',
        },
    }
};

export default FormModel;
