export const MobilePhotoVerificationStatus = Object.freeze({
    NOT_STARTED: 'NOT_STARTED', 
    STARTED: 'STARTED', 
    PREPROCESSING: 'PREPROCESSING',
    PROCESSING: 'PROCESSING',
    FRONT_PROCESSING: 'FRONT_PROCESSING',
    BACK_PROCESSING: 'BACK_PROCESSING',
    POSTPROCESSING: 'POSTPROCESSING',
    SUCCESS: 'SUCCESS', 
    FAIL: 'FAIL',
    GO_TO_FORM: 'GO_TO_FORM'
});