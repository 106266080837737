import LaptopImg from '../../assets/laptop.png';
import KnowUsBetterImg from '../../assets/know-us-better-color.png';
import { Button, Column, Row } from 'carbon-components-react';
import { Colors } from '../../utils/styleUtils/Colors';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Dimension } from '../../utils/styleUtils/Dimension';

const Articles = () => {
    const { width } = useWindowDimensions();

    return (
        <>
            <p style={width > Dimension.sm ? styles.title : {...styles.title, fontSize: 22}}>Poznaj nas bliżej</p>
            <div style={styles.box}>
                <div style={styles.row}>
                    <a href="/poznaj-nas-blizej">
                        <img src={KnowUsBetterImg} alt="know-us-better" style={width > Dimension.sm ? styles.photo : {...styles.photo, height: 180 }}/>
                    </a>
                    <div style={width > Dimension.sm ? styles.aboutUsDiv : styles.columnContentSm}>
                        <p style={styles.contentText}>Jako Spółdzielcza Kasa Oszczędnościowo-Kredytowa im. Zygmunta Chmielewskiego funkcjonujemy już od 1994 r. Od samego początku naszej działalności kierujemy się zasadą samopomocy finansowej, jednocześnie odpowiadając na potrzeby i oczekiwania Członków naszej Kasy. Naszym głównym celem jest propagowanie oszczędności i gospodarności, zapewnianie pożyczek o umiarkowanym oprocentowaniu oraz edukacja ekonomiczna.</p>
                        <a href="/poznaj-nas-blizej" style={{textDecoration: 0}}>
                            <Button style={width > Dimension.sm ? styles.button : {...styles.button, ...styles.buttonSm}}>Zobacz więcej</Button>
                        </a>
                    </div>
                </div>
            </div>
            <p style={width > Dimension.sm ? styles.title : {...styles.title, fontSize: 22}}>Artykuły</p>
            <div style={styles.box}>
                <Row style={styles.row}>
                    <Column sm={4} md={3} lg={5} style={styles.columnPhoto}>
                        <a href="/bezpieczenstwo">
                            <img src={LaptopImg} alt="Laptop" style={styles.photo}/>
                        </a>
                    </Column>
                    <Column sm={4} md={5} lg={7} style={width > Dimension.sm ? styles.columnContent : styles.columnContentSm}>
                        <p style={styles.contentTitle}>Bezpieczeństwo w sieci</p>
                        <p style={styles.contentText}>Internet daje nam dzisiaj mnóstwo możliwości. Mając dostęp do sieci możemy w łatwy i szybki sposób wyszukiwać potrzebne informacje, kontaktować się ze znajomymi, ale także robić zakupy, opłacać rachunki czy zarządzać swoim kontem w banku.</p>
                        <a href="/bezpieczenstwo" style={{textDecoration: 0}}>
                            <Button style={width > Dimension.sm ? styles.button : {...styles.button, ...styles.buttonSm}}>Zobacz więcej</Button>
                        </a>
                    </Column>
                </Row>
            </div>
        </>
    );
}

const styles = {
    title: {
        fontWeight: 'bold',
        fontSize: 30,
        textAlign: 'center',
        marginTop: 64,
    },
    box: {
        boxSizing: 'border-box',
        borderRadius: 20,
        background: Colors.white,
        marginTop: 44,
    },
    row: {
        marginRight: 0,
        marginLeft: 0,
    },
    aboutUsDiv: {
        padding: 24,
        paddingLeft: 40,
        paddingRight: 40,
        paddingBottom: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    columnPhoto: {
        paddingRight: 0,
        paddingLeft: 0,
    },
    photo: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: 20,
    },
    columnContent: {
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 54,
        paddingRight: 32,
    },
    columnContentSm: {
        padding: 20,
    },
    contentTitle: {
        fontSize: 21,
        fontWeight: 600,
        paddingBottom: 16,
    },
    contentText: {
        fontSize: 16,
        fontWeight: 400,
    },
    button: {
        background: Colors.white,
        border: `1px solid ${Colors.mystic}`,
        borderRadius: 4,
        color: Colors.lightGreen,
        fontSize: 15,
        fontWeight: 600,
        paddingLeft: 16,
        paddingRight: 16,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 16,
        marginBottom: 8,
    },
    buttonSm: {
        width: '100%',
        justifyContent: 'center',
        maxWidth: 'none',
    }
}

export default Articles;
