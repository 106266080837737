import { io } from "socket.io-client";

const switchConnection = () => {
    switch(window.location.hostname) {
        case 'localhost':
            return '';
        default:
            return io(`${window.location.protocol}//${window.location.hostname}/`, {secure: window.location.protocol === "https:"})
    }
}

export const socket = switchConnection();