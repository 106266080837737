import {
    ProgressIndicator,
    ProgressStep,
} from 'carbon-components-react';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Dimension } from '../../utils/styleUtils/Dimension';
import './Stepper.scss';

const Stepper = props => {
    const { width } = useWindowDimensions();

    return (
        <ProgressIndicator
            vertical={width < Dimension.sm}
            currentIndex={props.current}
            spaceEqually={false}
        >
            <ProgressStep
                label="1. Dane podstawowe"
            />
            <ProgressStep
                label="2. Dane dodatkowe"
            />
            <ProgressStep
                label="3. Złożenie wniosku"
            />
            <ProgressStep
                label="4. Spersonalizowana oferta"
            />
        </ProgressIndicator>
    )
};

export default Stepper;
