import { 
    Grid, 
    Row, 
    Column, 
    FileUploader,
    Button,
} from 'carbon-components-react';
import GridLoader from 'react-spinners/GridLoader';
import { useEffect, useState } from 'react';
import { Colors } from '../../utils/styleUtils/Colors';
import './UploadPhotosModal.scss';
import SuccessInfo from './SuccessInfo';
import FailInfo from './FailInfo';
import { MobilePhotoVerificationStatus } from '../../utils/consts';
import { socket } from '../../utils/socketIoConnection'; 
import { resizeImageFn } from '../../utils/resizeImage';

const UploadPhotosContent = props => {
    const [frontPhoto, setFrontPhoto] = useState(undefined);
    const [backPhoto, setBackPhoto] = useState(undefined);
    const [selfiePhoto, setSelfiePhoto] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [missingPhotoError, setMissingPhotoError] = useState(false);

    useEffect(() => {
        if (frontPhoto && backPhoto && selfiePhoto) setMissingPhotoError(false);
    }, [frontPhoto, backPhoto, selfiePhoto])

    useEffect(() => {
        if (props.status === MobilePhotoVerificationStatus.FAIL) setLoading(false);
        if (props.status === MobilePhotoVerificationStatus.SUCCESS) {
            setLoading(false);
            if (!props.mobile) {
                setTimeout(() => {
                    props.goToForm();
                }, 8000)
            } 
        }
    }, [props.status]);

    const  handleVerify = async () => {
        if (frontPhoto && backPhoto && selfiePhoto) {
            console.log('Before resize', frontPhoto.size, backPhoto.size, selfiePhoto.size);
            var frontPhotoResized = await resizeImageFn(frontPhoto);
            var backPhotoResized = await resizeImageFn(backPhoto);
            var selfiePhotoResized = await resizeImageFn(selfiePhoto);
            console.log('After resize', frontPhotoResized.size, backPhotoResized.size, selfiePhotoResized.size);
            window.scrollTo(0, 0);
            setLoading(true);
            if (window.location.hostname !== 'localhost'){
                socket.emit("read_from_photos", {
                    'front': frontPhotoResized,
                    'back': backPhotoResized,
                    'selfie': selfiePhotoResized,
                    'hash': window.location.hash.substring(1),
                    'mobile': !!props.mobile,
                    'phone': props.number
                })
            }
        } else {
            setMissingPhotoError(true);
        }
    }

    const handleReset = () => {
        setFrontPhoto(undefined);
        setBackPhoto(undefined);
        setSelfiePhoto(undefined);
        props.onReset();
    }

    const getContent = () => {
        if (props.status === MobilePhotoVerificationStatus.SUCCESS) {
            return <SuccessInfo mobile={props.mobile}/>
        } else if (props.status === MobilePhotoVerificationStatus.FAIL) {
            return <FailInfo onReset={handleReset} onGoToForm={props.goToForm} />
        } else {
            return (
                <>
                    <p style={styles.textCenter}>W celu weryfikacji tożsamości, prosimy o załączenie trzech zdjęć.</p>
                    <Grid>
                        <Row style={styles.row}>
                            <Column sm={4} lg={4}>
                                <div style={styles.column}>
                                    <p style={styles.textCenter}>Zdjęcie przodu dowodu osobistego</p>
                                    <FileUploader
                                        accept={[
                                            '.jpg',
                                            '.png'
                                        ]}
                                        buttonKind="ghost"
                                        buttonLabel="Dodaj zdjęcie"
                                        filenameStatus="edit"
                                        iconDescription="Clear file"
                                        onChange={e => setFrontPhoto(e.target.files[0])}
                                        onDelete={() => setFrontPhoto(undefined)}
                                    />
                                </div>
                            </Column>
                            <Column sm={4} lg={4}>
                                <div style={styles.column}>
                                    <p style={styles.textCenter}>Zdjęcie tyłu dowodu osobistego</p>
                                    <FileUploader
                                        accept={[
                                            '.jpg',
                                            '.png'
                                        ]}
                                        buttonKind="ghost"
                                        buttonLabel="Dodaj zdjęcie"
                                        filenameStatus="edit"
                                        iconDescription="Clear file"
                                        onChange={e => setBackPhoto(e.target.files[0])}
                                        onDelete={() => setBackPhoto(undefined)}
                                    />
                                </div>
                            </Column>
                            <Column sm={4} lg={4}>
                                <div style={styles.column}>
                                    <p style={styles.textCenter}>Selfie z przodem dowodu osobistego</p>
                                    <FileUploader
                                        accept={[
                                            '.jpg',
                                            '.png'
                                        ]}
                                        buttonKind="ghost"
                                        buttonLabel="Dodaj zdjęcie"
                                        filenameStatus="edit"
                                        iconDescription="Clear file"
                                        onChange={e => setSelfiePhoto(e.target.files[0])}
                                        onDelete={() => setSelfiePhoto(undefined)}
                                    />
                                </div>
                            </Column>
                            { missingPhotoError && (
                                <div style={styles.errorDiv}>
                                    <p>Proszę dodać wszystkie zdjęcia, aby rozpocząć weryfikację.</p>
                                </div>
                            )}
                        </Row>
                        <div style={styles.buttons}>
                            { !props.mobile && <Button style={{...styles.buttonCommon, ...styles.buttonPrev} } onClick={props.onClose} >Anuluj</Button> }
                            <Button style={{...styles.buttonCommon, ...styles.buttonNext}} onClick={handleVerify}>Weryfikuj</Button>
                        </div>
                    </Grid>
                </>
            )
        }
    }

    return (
        loading
        ? (
            <div style={styles.loader}>
                <p style={styles.loadingTitle}>Weryfikujemy Twoją tożsamość</p>
                <p style={styles.loadingSubtitle}>Proces może zająć do kilku minut</p>
                <GridLoader size={36} margin={18} color={Colors.lightGreen} />
                <p style={styles.progressMsg}>{props.msg}</p>
            </div>
        )
        : getContent()
    )
};

const styles = {
    row: {
        paddingTop: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    column: {
        background: Colors.authenticationBackground,
        boxSizing: 'border-box',
        borderRadius: 20,
        padding: 16,
        marginTop: 12,
    },
    buttons: {
        marginTop: '2rem',
        display: 'flex',
        justifyContent: 'center',
    },
    buttonCommon: {
        padding: 13,
        paddingLeft: 24,
        paddingRight: 24,
        marginLeft: 12,
        marginRight: 12,
        borderWidth: 0,
        textAlign: 'center',
        fontSize: 18,
    },
    buttonNext: {
        backgroundColor: Colors.orange,
        borderRadius: 4,
        fontWeight: 600,
        textDecoration: 'none',
        color: Colors.white,
    },
    buttonPrev: {
        backgroundColor: Colors.white,
        color: Colors.green,
        fontWeight: 500,
    },
    loader: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: 16,
        flex: 1,
    },
    loadingTitle: {
        marginTop: 8,
        fontSize: 20,
        fontWeight: 600,
    },
    loadingSubtitle: {
        marginBottom: 24,
    },
    progressMsg: {
        marginTop: 12,
        textAlign: 'center',
    },
    errorDiv: {
        width: '100%',
        marginTop: 16,
        color: Colors.error,
    },
    textCenter: {
        textAlign: 'center',
    }
}

export default UploadPhotosContent;
