import React from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import { TextInput } from 'carbon-components-react';

export default function InputField(props) {
    const { errorText, ...rest } = props;
    const [field, meta] = useField(props);

    function _renderHelperText() {
        const [touched, error] = at(meta, 'touched', 'error');
        if (touched && error) {
            return error;
        }
    }

    return (
        <TextInput
            type="text"
            invalid={meta.touched && meta.error && true}
            invalidText={_renderHelperText()}
            placeholder={rest.labelText}
            {...field}
            {...rest}
        />
    );
}