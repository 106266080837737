import React, { useEffect, useState, useRef } from 'react';
import { Button, Column, Grid, Row } from 'carbon-components-react';
import { useField } from 'formik';
import { Colors } from '../../../utils/styleUtils/Colors';
import InputField from '../formFields/InputField';
import SelectField from '../formFields/SelectField';
import RadioGroupField from '../formFields/RadioGroupField';
import countryList from '../../../utils/countryList';
import CheckboxField from '../formFields/CheckboxField';
import PhoneVerificationModal from './PhoneVerificationModal';
import CheckIcon from '../../../assets/checkmark--filled.svg';
import MaskedInputField from '../formFields/MaskedInputField';

import { useAnalyticsEventTracker } from '../../../hooks/useAnalyticsEventTracker';

const BasicInfoForm = ({ formField }) => {
    const [phoneField,,] = useField({ name: formField.phone.name });
    const [documentType] = useField(formField.documentType);
    const [phoneVerifiedField,,phoneVerifiedHelpers] = useField({ name: formField.phoneVerified.name });
    const [verificationModalOpen, setVerificationModalOpen] = useState(false);
    useAnalyticsEventTracker('Dane podstawowe')

    const initialRender = useRef(true);
    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
            return;
        };
        phoneVerifiedHelpers.setValue(false);
    }, [phoneField.value]);

    const idDocumentField = (documentTypeValue, name, label) => {
        switch (documentTypeValue) {
            case 'dowód osobisty':
                return <MaskedInputField 
                            id={name}
                            name={name}
                            labelText={label}
                            mask="aaa 999999"
                            placeholder={label}
                            upperCase
                        />
            case 'paszport polski':
                return <MaskedInputField 
                            id={name}
                            name={name}
                            labelText={label}
                            placeholder={label}
                            mask="aa 9999999"
                            upperCase
                        />
            default:
                return <InputField
                    id={formField.idNumber.name}
                    name={formField.idNumber.name}
                    labelText={formField.idNumber.label}
                    disabled
                />
        }
    }

    return (
        <Grid style={styles.container}>
            <p style={styles.header}>Wypełnij dane</p>
            <Row>
                <Column sm={0} lg={1}></Column>
                <Column sm={4} md={8} lg={5}>
                    <div style={styles.box}>
                        <p style={styles.title}>Dane z dokumentu</p>
                        <Grid>
                            <Row>
                                <Column sm={4} md={4} style={styles.col}>
                                    <InputField
                                        id={formField.firstName.name}
                                        name={formField.firstName.name}
                                        labelText={formField.firstName.label}
                                    />
                                </Column>
                                <Column sm={4} md={4} style={styles.col}>
                                    <InputField
                                        id={formField.lastName.name}
                                        name={formField.lastName.name}
                                        labelText={formField.lastName.label}
                                    />  
                                </Column>
                            </Row>
                            <Row>
                                <Column sm={4} md={4} style={styles.col}>
                                    <MaskedInputField 
                                        id={formField.pesel.name}
                                        name={formField.pesel.name}
                                        labelText={formField.pesel.label}
                                        mask="99999999999"
                                        placeholder={formField.pesel.label}
                                    />
                                </Column>
                                <Column sm={4} md={4} style={styles.col}>
                                    <SelectField
                                        id={formField.nationality.name}
                                        name={formField.nationality.name}
                                        labelText={formField.nationality.label}
                                        data={countryList.map(elem => ({ label: elem.name_pl, value: elem.code }))}
                                    />  
                                </Column>
                            </Row>
                            <Row>
                                <Column sm={4} md={4} style={styles.col}>
                                    <RadioGroupField
                                        id={formField.documentType.name}
                                        name={formField.documentType.name}
                                        labelText={formField.documentType.label}
                                        data={[
                                            { label: 'Dowód osobisty', value: 'dowód osobisty' },
                                            { label: 'Paszport', value: 'paszport polski' },
                                        ]}
                                        orientation="vertical"
                                    />
                                </Column>
                                <Column sm={4} md={4} style={styles.col}>
                                    {idDocumentField(documentType.value, formField.idNumber.name, formField.idNumber.label)}
                                </Column>
                            </Row>
                            <Row>
                                <Column sm={4} md={4} style={styles.col}>
                                    <MaskedInputField 
                                        id={formField.issueDate.name}
                                        name={formField.issueDate.name}
                                        labelText={formField.issueDate.label}
                                        mask="99.99.9999"
                                        placeholder="dd.mm.rrrr"
                                    />
                                </Column>
                                <Column sm={4} md={4} style={styles.col}>
                                    <MaskedInputField 
                                        id={formField.expirationDate.name}
                                        name={formField.expirationDate.name}
                                        labelText={formField.expirationDate.label}
                                        mask="99.99.9999"
                                        placeholder="dd.mm.rrrr"
                                    />
                                </Column>
                            </Row>
                            <Row>
                                <Column sm={4} md={4} style={styles.col}>
                                    <CheckboxField
                                        id={formField.indefinite.name}
                                        name={formField.indefinite.name}
                                        labelText={formField.indefinite.label}
                                    />
                                </Column>
                            </Row>
                        </Grid>
                    </div>
                </Column>
                <Column sm={4} md={8} lg={5}>
                    <div style={styles.box}>
                        <p style={styles.title}>Adres zamieszkania</p>
                        <Grid>
                            <Row>
                                <Column sm={4} md={4} style={styles.col}>
                                    <RadioGroupField
                                        id={formField.member.name}
                                        name={formField.member.name}
                                        labelText={formField.member.label}
                                        data={[
                                            { label: 'Tak', value: 'tak' },
                                            { label: 'Nie', value: 'nie' },
                                        ]}
                                    />
                                </Column>
                                <Column sm={4} md={4} style={styles.col}>
                                    <InputField
                                        id={formField.street.name}
                                        name={formField.street.name}
                                        labelText={formField.street.label}
                                    />
                                </Column>
                            </Row>
                            <Row>
                                <Column sm={4} md={4} style={styles.col}>
                                    <InputField
                                        id={formField.houseNumber.name}
                                        name={formField.houseNumber.name}
                                        labelText={formField.houseNumber.label}
                                    />
                                </Column>
                                <Column sm={4} md={4} style={styles.col}>
                                    <InputField
                                        id={formField.aptNumber.name}
                                        name={formField.aptNumber.name}
                                        labelText={formField.aptNumber.label}
                                    />
                                </Column>
                            </Row>
                            <Row>
                                <Column sm={4} md={4} style={styles.col}>
                                    <MaskedInputField 
                                        id={formField.postcode.name}
                                        name={formField.postcode.name}
                                        labelText={formField.postcode.label}
                                        mask="99-999"
                                        placeholder="XX-XXX"
                                    />
                                </Column>
                                <Column sm={4} md={4} style={styles.col}>
                                    <InputField
                                        id={formField.city.name}
                                        name={formField.city.name}
                                        labelText={formField.city.label}
                                    />
                                </Column>
                            </Row>
                        </Grid>
                    </div>
                    <div style={styles.box}>
                        <p style={styles.title}>Dane kontaktowe</p>
                        <Grid>
                            <Row>
                                <Column sm={4} md={4} style={styles.col}>
                                    <MaskedInputField 
                                        id={formField.phone.name}
                                        name={formField.phone.name}
                                        labelText={formField.phone.label}
                                        mask="999 999 999"
                                        placeholder="123 456 789"
                                    />
                                </Column>
                                <Column sm={4} md={4} style={styles.col}>
                                    <div style={styles.verifyButtonDiv}>
                                        {
                                            phoneVerifiedField.value 
                                            ? (
                                                <div style={styles.verifiedDiv}>
                                                    <img src={CheckIcon} alt="check-icon" style={styles.checkIcon} />
                                                    <p style={styles.phoneVerifiedText}>Numer zweryfikowany</p> 
                                                </div>
                                            ) : (
                                                <Button 
                                                    style={styles.verifyButton} 
                                                    onClick={() => setVerificationModalOpen(true)} 
                                                    disabled={phoneField.value.replace(/-|_|\s/g, "").length !== 9}
                                                >
                                                    Weryfikuj numer
                                                </Button>
                                            )
                                        }
                                        <PhoneVerificationModal 
                                            open={verificationModalOpen} 
                                            onClose={() => setVerificationModalOpen(false)} 
                                            onVerificationSuccess={() => phoneVerifiedHelpers.setValue(true)}
                                            formField={formField}
                                        />
                                    </div>
                                </Column>
                            </Row>
                            <Row>
                                <Column sm={4} md={4} style={styles.col}>
                                    <InputField
                                        id={formField.email.name}
                                        name={formField.email.name}
                                        labelText={formField.email.label}
                                    />
                                </Column>
                                <Column sm={4} md={4} style={styles.col}>
                                    <InputField
                                        id={formField.confirmEmail.name}
                                        name={formField.confirmEmail.name}
                                        labelText={formField.confirmEmail.label}
                                        onPaste={e => e.preventDefault()}
                                    />
                                </Column>
                            </Row>
                        </Grid>
                    </div>
                </Column>
                <Column sm={0} lg={1}></Column>
            </Row>
        </Grid>
    );
}

const styles = {
    container: {
        marginLeft: 'none',
        marginRight: 'none',
    },
    header: {
        fontWeight: 'bold',
        fontSize: 21,
        color: Colors.black,
        textAlign: 'center',
        marginTop: 20,
    },
    box: {
        border: `1px solid ${Colors.borderColor}`,
        boxSizing: 'border-box',
        borderRadius: 20,
        padding: 4,
        paddingTop: 16,
        paddingBottom: 20,
        marginTop: 20,
    },
    title: {
        fontWeight: 600,
        color: Colors.black,
        fontSize: 18,
        textAlign: 'center',
        marginBottom: 8,
    },
    col: {
        marginTop: 14,
    },
    datePicker: {
        width: '100%',
    },
    verifyButtonDiv: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    verifyButton: {
        background: Colors.white,
        border: `2px solid ${Colors.mystic}`,
        borderRadius: 6,
        color: Colors.lightGreen,
        fontSize: 16,
        fontWeight: 500,
        paddingLeft: 16,
        paddingRight: 16,
        width: '100%',
        justifyContent: 'center',
        minHeight: 0,
        height: 40,
        maxWidth: 'unset',
    },
    verifiedDiv: {
        background: Colors.verifiedDiv,
        display: 'flex',
        padding: 8,
        paddingRight: 12,
        borderRadius: 12,
        alignItems: 'center',
        width: '100%'
    },
    checkIcon: {
        paddingRight: 4,
        filter: 'invert(44%) sepia(10%) saturate(2311%) hue-rotate(95deg) brightness(93%) contrast(83%)',
        height: 26,
    },
    phoneVerifiedText: {
        fontSize: 14,
    }
}

export default BasicInfoForm;
