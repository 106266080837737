import React, { useState, useEffect } from 'react';
import HeaderFooterTemplate from '../headerFooterTemplate/HeaderFooterTemplate';
import Photo1 from '../../assets/slider_photo1.png';
import Photo2 from '../../assets/slider_photo2.png';
import Photo3 from '../../assets/slider_photo3.png';
import Photo4 from '../../assets/slider_photo4.png';
import Photo5 from '../../assets/slider_photo5.png';
import PhotoBackground from '../../assets/photo-background.svg';
import RightArrow from '../../assets/right-arrow.svg';
import { Colors } from '../../utils/styleUtils/Colors';
import QA from './QA';
import Articles from './Articles';
import InitialCreditCalculator from '../creditCalculator/InitialCreditCalculator';
import { Grid, Row, Column } from 'carbon-components-react';
import { Zoom } from 'react-slideshow-image';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import './LandingPage.scss';
import { Dimension } from '../../utils/styleUtils/Dimension';
import { useAnalyticsEventTracker } from '../../hooks/useAnalyticsEventTracker';

const images = [
    Photo1,
    Photo2,
    Photo3,
    Photo4,
    Photo5,
].sort(() => Math.random() - 0.5);

const titles = [
    'czekają na Ciebie', 
    'szybko i wygodnie', 
    'online'
].sort(() => Math.random() - 0.5);

const LandingPage = () => {
    const [currentTitle, setCurrentTitle] = useState(0);
    const [show, setShow] = useState(true);
    const { width } = useWindowDimensions();
    useAnalyticsEventTracker('Startowa')

    useEffect(() => {
        setTimeout(() => setShow(false), 10000);
            setShow(true);
            setCurrentTitle(currentTitle => currentTitle + 1 < titles.length ? currentTitle + 1 : 0);
        const interval = setInterval(() => {
            setTimeout(() => setShow(false), 10000);
            setShow(true);
            setCurrentTitle(currentTitle => currentTitle + 1 < titles.length ? currentTitle + 1 : 0);
        }, 12000);
        return () => {
            clearInterval(interval);
        }
    }, []);

    const zoomOutProperties = {
        scale: 1,
        transitionDuration: 2000,
        arrows: false,
        duration: 10000,
    };

    const getTitleDivStyle = () => {
        if (width > Dimension.oneRow) return styles.titleDiv;
        else if (width > Dimension.md) return styles.titleDivMd;
        else return styles.titleDivSm;
    };

    const content = () => (
        <Grid fullWidth style={width > Dimension.md ? styles.root : styles.rootMobile}>
            {
                width > Dimension.md && (
                    <>
                        <img src={PhotoBackground} alt="couple-backgroud" style={styles.background} />
                        <div style={styles.slideshowContainer} className="slideshow-container">
                            <Zoom {...zoomOutProperties}>
                                {images.map((each, index) => (
                                <div key={index} style={styles.photoContainer}>
                                    <img style={styles.photo} src={each} alt=""/>
                                </div>
                                ))}
                            </Zoom>
                        </div>
                    </>
                )
            }
            <div style={getTitleDivStyle()}>
                <p style={width > Dimension.sm ? {...styles.title, color: Colors.black} : {...styles.title, fontSize: 26, color: Colors.black} }>Praktyczne pożyczki </p>
                <span style={show ? {...styles.transition, ...styles.show} : styles.transition}>
                    <p style={width > Dimension.sm ? {...styles.title, color: Colors.green} : {...styles.title, fontSize: 26, color: Colors.green}}>{titles[currentTitle]}</p>
                </span>
            </div>
            { width > Dimension.oneRow && (
                <div style={styles.titleDiv}>
                    <p style={styles.stepsText}>1. Złóż wniosek online</p>
                    <img src={RightArrow} alt="" style={styles.rightArrow} />
                    <p style={styles.stepsText}>2. Zaakceptuj ofertę</p>
                    <img src={RightArrow} alt="" style={styles.rightArrow} />
                    <p style={styles.stepsText}>3. Odbierz gotówkę lub przelew</p>
                </div>
            )}
            <InitialCreditCalculator />
        </Grid>
    );

    const subcontent = () => (
        <div style={styles.subcontentDiv}>
            <Grid>
                <Row>
                    <Column sm={0} md={1}></Column>
                    <Column sm={4} md={6}>
                        <QA />
                        <Articles />
                    </Column>
                    <Column sm={0} md={1}></Column>
                </Row>
            </Grid>
        </div>
    )

    return (
        <HeaderFooterTemplate content={content} subcontent={subcontent} />
    )
}

const styles = {
    root: {
        marginLeft: '3rem',
        marginTop: '6%',
    },
    rootMobile: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    slideshowContainer: {
        height: '90vh',
        width: '100%',
        position: 'absolute',
        right: 0,
        bottom: 0,
        zIndex: -2,
    },
    photoContainer: {
        width: '100%', 
        height: '100%', 
        textAlign: 'end',
    },
    photo: {
        objectFit: 'cover',
    },
    background: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        height: '82vh',
        zIndex: -2,
    },
    titleDiv: {
        display: 'flex',
        marginTop: 16,
    },
    titleDivMd: {
        marginTop: 16,
        display: 'flex',
        flexDirection: 'column',
    },
    titleDivSm: {
        marginTop: 16,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontWeight: 700,
        fontSize: 48,
        whiteSpace: 'break-spaces',
        lineHeight: 1.2,
    },
    stepsText: {
        fontWeight: 500,
        fontSize: 20,
        color: Colors.black,
        opacity: 0.8,
    },
    rightArrow: {
        marginLeft: 24,
        marginRight: 24,
    },
    subcontentDiv: {
        width: '100%',
        paddingTop: 60,
        paddingBottom: 60,
    },
    transition: {
        transition: '1500ms all ease',
        opacity: 0,
        willChange: 'opacity',
    },
    show: {
        opacity: 1,
    },
}

export default LandingPage;
