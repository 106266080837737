export const validateIdNumber = idNumberValue => {
    //Check length
    if (idNumberValue == null || idNumberValue.length !== 10) return false;
        
    idNumberValue = idNumberValue.replace(/[\s-]/g, '');
    
    const letterValues = [
    '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J',
    'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T',
    'U', 'V', 'W', 'X', 'Y', 'Z'];

    function getLetterValue(letter)
    {
        for (let j=0; j<letterValues.length; j++)
            if (letter === letterValues[j]) return j;
            return -1;
    }
    
    //Check seria
    for (let i=0; i<3; i++)     
        if (getLetterValue(idNumberValue[i]) < 10) return false;

    //Check number
    for (let i=3; i<9; i++)
        if (getLetterValue(idNumberValue[i]) < 0 || getLetterValue(idNumberValue[i]) > 9) return false;
    
    //sprawdz cyfre controlna
    let sum = 7 * getLetterValue(idNumberValue[0]) +
    3 * getLetterValue(idNumberValue[1]) +
    1 * getLetterValue(idNumberValue[2]) +
    7 * getLetterValue(idNumberValue[4]) +
    3 * getLetterValue(idNumberValue[5]) +
    1 * getLetterValue(idNumberValue[6]) +
    7 * getLetterValue(idNumberValue[7]) +
    3 * getLetterValue(idNumberValue[8]);
    sum %= 10;

    if (sum !== getLetterValue(idNumberValue[3])) return false;
    else return true;
}