import { Button } from 'carbon-components-react';
import FailIdIcon from '../../assets/id_check_icon_error.svg';
import ReloadIcon from '../../assets/reload.svg';
import { Colors } from '../../utils/styleUtils/Colors';

const FailInfo = props => {
    return (
        <div style={styles.container}>
            <div style={styles.box}>
                <img src={FailIdIcon} alt="success-id"/>
                <p style={styles.header}>Weryfikacja nieudana</p>
                <p style={styles.text}>Możesz spróbować wgrać zdjęcia jeszcze raz lub przejść do formularza i uzupełnić dane samodzielnie.</p>
            </div>
            <div style={styles.buttons}>
                <Button style={styles.buttonReload} onClick={props.onReset}>
                    <img src={ReloadIcon} alt="reload" style={styles.reloadIcon} />
                    Wgraj zdjęcia
                </Button>
                <Button style={styles.buttonNext} onClick={props.onGoToForm}>Przejdź do formularza</Button>
            </div>
        </div>
    )
}

const styles = {
    container: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    box: {
        background: Colors.failInfoBackground,
        boxSizing: 'border-box',
        borderRadius: 20,
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'fit-content',
        maxWidth: 660,
    },
    header: {
        fontSize: 21,
        fontWeight: 600,
        marginBottom: 12,
        marginTop: 8,
        textAlign: 'center',
    },
    text: {
        textAlign: 'center',
    },
    buttons: {
        marginTop: '1rem',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    buttonReload: {
        background: Colors.white,
        border: `1px solid ${Colors.mystic}`,
        borderRadius: 4,
        color: Colors.lightGreen,
        fontSize: 15,
        fontWeight: 600,
        paddingLeft: 16,
        paddingRight: 16,
        marginTop: 8,
    },
    reloadIcon: {
        marginRight: 12,
    },
    buttonNext: {
        backgroundColor: Colors.orange,
        borderRadius: 4,
        fontWeight: 600,
        fontSize: 15,
        textDecoration: 'none',
        color: Colors.white,
        paddingLeft: 24,
        paddingRight: 24,
        marginTop: 8,
        marginLeft: 16,
        marginRight: 16,
        borderWidth: 0,
        textAlign: 'center',
    }
}

export default FailInfo;
