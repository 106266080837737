import React from 'react';
import { FieldArray, useField } from 'formik';
import SelectField from '../formFields/SelectField';
import { Grid, Row, Column, Button } from 'carbon-components-react';
import RadioGroupField from '../formFields/RadioGroupField';
import InputField from '../formFields/InputField';
import { Colors } from '../../../utils/styleUtils/Colors';
import AddIcon from '../../../assets/add-24px.svg';
import DeleteIcon from '../../../assets/delete-24px.svg';
import formModel from '../formModel/model';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { Dimension } from '../../../utils/styleUtils/Dimension';
import MaskedInputField from '../formFields/MaskedInputField';

const {
    formField: {
        incomeSource,
        uopIndefinite,
        uopToDate,
        ownBusinessFrom,
        ownBusinessIndustry,
        ownBusinessNIP,
        ownBusinessSettlementForm,
        pensionIndefinite,
        pensionToDate,
        employerName,
        employerNIP,
        employerNumber,
        nettoIncome,
    }
} = formModel;

const incomeSources = [
    'umowa o pracę', 
    'umowa zlecenia/dzieło', 
    'własna działalność gospodarcza', 
    'emerytura', 
    'renta',
    'gospodarstwo rolne',
    'świadczenie przedemerytalne',
];

const ownBusinessSettlementForms = [
    'Zasady ogólne',
    'Ryczałt',
    'Karta podatkowa'
]

const initialValues = {
    [incomeSource.name]: '',
    [uopIndefinite.name]: '',
    [uopToDate.name]: '',
    [ownBusinessFrom.name]: '',
    [ownBusinessIndustry.name]: '',
    [ownBusinessNIP.name]: '',
    [ownBusinessSettlementForms.name]: '',
    [pensionIndefinite.name]: '',
    [pensionToDate.name]: '',
    [employerName.name]: '',
    [employerNIP.name]: '',
    [employerNumber.name]: '',
    [nettoIncome.name]: '',
}

export default function IncomeForm(props) {
    const [field] = useField(props);
    const { width } = useWindowDimensions();

    const renderSpecificFields = (obj, index) => {
        switch (obj.incomeSource) {
            case 'umowa o pracę':
                return (
                    <>
                        <Column sm={4} md={(obj.uopIndefinite === 'tak' || obj.uopIndefinite === '') ? 8 : 4} style={styles.col}>
                            <RadioGroupField 
                                name={`incomeSources[${index}].${props.formField.uopIndefinite.name}`}
                                labelText={props.formField.uopIndefinite.label}
                                data={[
                                    { label: 'Czas nieokreślony', value: 'tak' }, 
                                    { label: 'Czas określony', value: 'nie'}
                                ]}
                                orientation="vertical"
                            />
                        </Column>
                        { obj.uopIndefinite === 'nie' && (
                            <Column sm={4} md={4} style={styles.col}>
                                <MaskedInputField 
                                    id={`incomeSources[${index}].${props.formField.uopToDate.name}`}
                                    name={`incomeSources[${index}].${props.formField.uopToDate.name}`}
                                    labelText={props.formField.uopToDate.label}
                                    mask="99.99.9999"
                                    placeholder="dd.mm.rrrr"
                                />
                            </Column>
                        ) }
                        <Column sm={4} md={4} style={styles.col}>
                            <InputField
                                id={`incomeSources[${index}].${props.formField.nettoIncome.name}`}
                                name={`incomeSources[${index}].${props.formField.nettoIncome.name}`}
                                labelText={props.formField.nettoIncome.label}
                                type="number"
                                min={0}
                            />
                        </Column>
                        <Column sm={4} md={4} style={styles.col}>
                            <InputField
                                id={`incomeSources[${index}].${props.formField.employerName.name}`}
                                name={`incomeSources[${index}].${props.formField.employerName.name}`}
                                labelText={props.formField.employerName.label}
                            />
                        </Column>
                        <Column sm={4} md={4} style={styles.col}>
                            <InputField
                                id={`incomeSources[${index}].${props.formField.employerNIP.name}`}
                                name={`incomeSources[${index}].${props.formField.employerNIP.name}`}
                                labelText={props.formField.employerNIP.label}
                            />
                        </Column>
                        <Column sm={4} md={4} style={styles.col}>
                            <MaskedInputField 
                                id={`incomeSources[${index}].${props.formField.employerNumber.name}`}
                                name={`incomeSources[${index}].${props.formField.employerNumber.name}`}
                                labelText={props.formField.employerNumber.label}
                                mask="999 999 999"
                                placeholder="123 456 789"
                            />
                        </Column>
                    </>
                )  
            case 'umowa zlecenia/dzieło':
                return (
                    <>
                        <Column sm={4} md={4} style={styles.col}>
                            <InputField
                                id={`incomeSources[${index}].${props.formField.nettoIncome.name}`}
                                name={`incomeSources[${index}].${props.formField.nettoIncome.name}`}
                                labelText={props.formField.nettoIncome.label}
                                type="number"
                                min={0}
                            />
                        </Column>
                        <Column sm={4} md={4} style={styles.col}>
                            <InputField
                                id={`incomeSources[${index}].${props.formField.employerName.name}`}
                                name={`incomeSources[${index}].${props.formField.employerName.name}`}
                                labelText={props.formField.employerName.label}
                            />
                        </Column>
                        <Column sm={4} md={4} style={styles.col}>
                            <InputField
                                id={`incomeSources[${index}].${props.formField.employerNIP.name}`}
                                name={`incomeSources[${index}].${props.formField.employerNIP.name}`}
                                labelText={props.formField.employerNIP.label}
                            />
                        </Column>
                        <Column sm={4} md={4} style={styles.col}>
                            <MaskedInputField 
                                id={`incomeSources[${index}].${props.formField.employerNumber.name}`}
                                name={`incomeSources[${index}].${props.formField.employerNumber.name}`}
                                labelText={props.formField.employerNumber.label}
                                mask="999 999 999"
                                placeholder="123 456 789"
                            />
                        </Column>
                    </>
                );
            case 'własna działalność gospodarcza':
                return (
                    <>
                        <Column sm={4} md={4} style={styles.col}>
                            <InputField
                                id={`incomeSources[${index}].${props.formField.nettoIncome.name}`}
                                name={`incomeSources[${index}].${props.formField.nettoIncome.name}`}
                                labelText={props.formField.nettoIncome.label}
                                type="number"
                                min={0}
                            />
                        </Column>
                        <Column sm={4} md={4} style={styles.col}>
                            <MaskedInputField 
                                id={`incomeSources[${index}].${props.formField.ownBusinessFrom.name}`}
                                name={`incomeSources[${index}].${props.formField.ownBusinessFrom.name}`}
                                labelText={props.formField.ownBusinessFrom.label}
                                mask="99.99.9999"
                                placeholder="dd.mm.rrrr"
                            />
                        </Column>
                        <Column sm={4} md={4} style={styles.col}>
                            <InputField
                                id={`incomeSources[${index}].${props.formField.ownBusinessIndustry.name}`}
                                name={`incomeSources[${index}].${props.formField.ownBusinessIndustry.name}`}
                                labelText={props.formField.ownBusinessIndustry.label}
                            />
                        </Column>
                        <Column sm={4} md={4} style={styles.col}>
                            <InputField
                                id={`incomeSources[${index}].${props.formField.ownBusinessNIP.name}`}
                                name={`incomeSources[${index}].${props.formField.ownBusinessNIP.name}`}
                                labelText={props.formField.ownBusinessNIP.label}
                            />
                        </Column>
                        <Column sm={4} style={styles.col}>
                            <SelectField
                                id={`incomeSources[${index}].${props.formField.ownBusinessSettlementForm.name}`}
                                name={`incomeSources[${index}].${props.formField.ownBusinessSettlementForm.name}`}
                                labelText={props.formField.ownBusinessSettlementForm.label}
                                data={ownBusinessSettlementForms.map(elem => ({ label: elem, value: elem }))}
                            />
                        </Column>
                    </>
                );
            case 'renta': 
                return (
                    <>
                        <Column sm={4} style={styles.col}>
                            <InputField
                                id={`incomeSources[${index}].${props.formField.nettoIncome.name}`}
                                name={`incomeSources[${index}].${props.formField.nettoIncome.name}`}
                                labelText={props.formField.nettoIncome.label}
                                type="number"
                                min={0}
                            />
                        </Column>
                        <Column sm={4} md={4} style={styles.col}>
                            <RadioGroupField 
                                name={`incomeSources[${index}].${props.formField.pensionIndefinite.name}`}
                                labelText={props.formField.pensionIndefinite.label}
                                data={[
                                    { label: 'Czas nieokreślony', value: 'tak' }, 
                                    { label: 'Czas określony', value: 'nie' }
                                ]}
                                orientation="vertical"
                            />
                        </Column>
                        {
                            obj.pensionIndefinite === 'nie' && (
                                <Column sm={4} md={4} style={styles.col}>
                                    <MaskedInputField 
                                        id={`incomeSources[${index}].${props.formField.pensionToDate.name}`}
                                        name={`incomeSources[${index}].${props.formField.pensionToDate.name}`}
                                        labelText={props.formField.pensionToDate.label}
                                        mask="99.99.9999"
                                        placeholder="dd.mm.rrrr"
                                    />
                                </Column>
                            )
                        }
                    </>
                );
            case 'emerytura':
            case 'gospodarstwo rolne':
                return (
                    <Column sm={4} style={styles.col}>
                        <InputField
                            id={`incomeSources[${index}].${props.formField.nettoIncome.name}`}
                            name={`incomeSources[${index}].${props.formField.nettoIncome.name}`}
                            labelText={props.formField.nettoIncome.label}
                            type="number"
                            min={0}
                        />
                    </Column>
                )
            case 'świadczenie przedemerytalne':
                return (
                    <>
                        <Column sm={4} style={styles.col}>
                            <MaskedInputField 
                                id={`incomeSources[${index}].${props.formField.preretireStartDate.name}`}
                                name={`incomeSources[${index}].${props.formField.preretireStartDate.name}`}
                                labelText={props.formField.preretireStartDate.label}
                                mask="99.99.9999"
                                placeholder="dd.mm.rrrr"
                            />
                        </Column>
                        <Column sm={4} style={styles.col}>
                            <InputField
                                id={`incomeSources[${index}].${props.formField.nettoIncome.name}`}
                                name={`incomeSources[${index}].${props.formField.nettoIncome.name}`}
                                labelText={props.formField.nettoIncome.label}
                                type="number"
                                min={0}
                            />
                        </Column>
                    </>
                )
            default:
                return <></> 
        }
    }

    return (
        <FieldArray 
            name={props.name}
            render={arrayHelpers => {
                return (
                    <>
                        {
                            field.value.map((obj, idx) => (
                                <div key={idx} style={idx === 0 ? {...styles.box, marginTop: 48} : styles.box}>
                                    <p style={styles.title}>Źródło dochodu</p>
                                    { 
                                        idx !== 0 && 
                                        <img src={DeleteIcon} alt="" style={styles.deleteIcon} onClick={() => arrayHelpers.remove(idx)} /> 
                                    }
                                    <Grid>
                                        <Row>
                                            <Column sm={4} style={styles.col}>
                                                <SelectField 
                                                    key={idx}
                                                    id={`incomeSources[${idx}].${props.formField.incomeSource.name}`}
                                                    name={`incomeSources[${idx}].${props.formField.incomeSource.name}`}
                                                    labelText={props.formField.incomeSource.label}
                                                    data={incomeSources.map(elem => ({ label: elem, value: elem }))}
                                                />
                                            </Column>
                                            { renderSpecificFields(obj, idx) }
                                        </Row>
                                    </Grid>
                                </div>
                            ))
                        }
                        {
                            field.value.length < 2 && (
                                <div style={styles.buttonDiv}>
                                    <Button style={styles.button} onClick={() => arrayHelpers.push(initialValues)}>
                                        <img src={AddIcon} alt="" style={styles.icon} />
                                        {width > Dimension.sm ? 'Dodaj kolejne źródło dochodu' : 'Kolejne źródło dochodu' } 
                                    </Button>
                                </div>
                            )
                        }
                    </>
                )
            }}
        />
    );
}

const styles = {
    col: {
        marginTop: 14,
    },
    datePicker: {
        width: '100%',
    },

    box: {
        border: `1px solid ${Colors.borderColor}`,
        boxSizing: 'border-box',
        borderRadius: 20,
        padding: 4,
        paddingTop: 16,
        paddingBottom: 20,
        marginTop: 8,
        position: 'relative',
    },
    title: {
        fontWeight: 600,
        color: Colors.black,
        fontSize: 18,
        textAlign: 'center',
        width: '100%',
        marginBottom: 8,
    },
    button: {
        background: Colors.white,
        border: `1px solid ${Colors.mystic}`,
        borderRadius: 4,
        color: Colors.lightGreen,
        fontSize: 15,
        fontWeight: 600,
        paddingLeft: 16,
        paddingRight: 16,
        marginTop: 12,
    },
    icon: {
        paddingRight: 12,
    },
    deleteIcon: {
        position: 'absolute',
        right: 0,
        marginRight: 16,
        opacity: 0.4,
        cursor: 'pointer',
        top: 0,
        marginTop: 16,
    },
    buttonDiv: {
        textAlign: 'center',
    }
}
