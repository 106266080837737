import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { useField } from 'formik';
import { at } from 'lodash';
import { Colors } from '../../../utils/styleUtils/Colors';
 
const MaskedInputField = props => {
    const [field, meta, helpers] = useField(props);
    const [touched, error] = at(meta, 'touched', 'error');
    const [touchedHelper, setTouchedHelper] = useState(false);

    function _renderHelperText() {
        if ((touched || touchedHelper) && error ) {
            return error;
        }
    }

    function handleChange(e) {
        helpers.setValue(e.target.value);
    }

    return (
        <div>
            <div className="bx--form-item bx--text-input-wrapper">
                <div className="bx--text-input__field-outer-wrapper">
                    <p className="bx--label">{props.labelText}</p>
                    <InputMask 
                        id={props.id} 
                        name={props.name}
                        className={_renderHelperText() ? "bx--text-input bx--text__input bx--text-input--invalid" :  "bx--text-input bx--text__input"}
                        mask={props.mask} 
                        value={ props.upperCase ? field.value.toUpperCase() : field.value }
                        onChange={handleChange}
                        onBlur={() => setTouchedHelper(true)}
                        placeholder={props.placeholder}
                    />
                </div>
            </div>
            <p style={styles.errorText}>{_renderHelperText()}</p>
        </div>
    );
}

const styles = {
    errorText: {
        color: Colors.error,
        display: 'block',
        fontWeight: 400,
        fontSize: '0.75rem',
        lineHeight: 1.34,
        letterSpacing: 0.32,
        margin: '0.25rem 0 0',
    }
}

export default MaskedInputField;
