import { Accordion, AccordionItem } from 'carbon-components-react';
import { Colors } from '../../utils/styleUtils/Colors';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Dimension } from '../../utils/styleUtils/Dimension';
import './QA.scss';

const QA = () => {
    const { width } = useWindowDimensions();

    return (
        <div style={styles.box}>
            <p style={width > Dimension.sm ? styles.title : {...styles.title, fontSize: 22}}>Często zadawane pytania</p>
            <Accordion className="qa-accordion">
                <AccordionItem title="Co mam zrobić, aby szybko otrzymać pożyczkę?">
                    <p style={styles.answer}>Wniosek o pożyczkę możesz złożyć przez 24 godziny na dobę, 7 dni w tygodniu. Aby to zrobić wystarczy wybrać preferowaną kwotę pożyczki oraz okres spłaty, wypełnić formularz i podać wszystkie niezbędne dane osobowe. Informacje te pomogą nam w weryfikacji Twojej tożsamości oraz ocenie zdolności kredytowej. Dzięki temu będziemy też mogli przygotować specjalną ofertę, dopasowaną do Twoich potrzeb i możliwości.</p>
                    <p style={styles.answer}>Jeżeli pozytywnie przejdziesz proces weryfikacji oraz zaakceptujesz przygotowaną przez nas ofertę, zaprosimy Cię do wybranego w trakcie wnioskowania oddziału SKOK Chmielewskiego w celu podpisania dokumentów pożyczkowych. Podczas tej samej wizyty odbierzesz swoją gotówkę. Jeśli jesteś już naszym klientem i posiadasz swój rachunek płatniczy ROR w SKOK Chmielewskiego - pieniądze przelejemy na Twoje konto.</p>
                    <p style={styles.answer}>W przypadku jakichkolwiek problemów lub dodatkowych pytań zachęcamy do kontaktu z naszą infolinią pod numerem 81 446 30 00 czynnym od poniedziałku do piątku w godzinach od 8:00 do 17:00 (z wyłączeniem dni ustawowo wolnych od pracy). Opłata za połączenie telefoniczne z wyżej wskazanym numerem infolinii jest zgodna z cennikiem dostawcy usług telekomunikacyjnych, z którego korzysta osoba dzwoniąca na ten numer.</p>
                </AccordionItem>
                <AccordionItem title="Jakie warunki muszę spełnić?">
                    <p style={styles.answer}>Do złożenia wniosku niezbędny będzie tylko dowód osobisty oraz wskazanie źródła Twoich dochodów. Na tej podstawie będziemy mogli zweryfikować Twoją zdolność kredytową. Pamiętaj, że robimy to z myślą o Twoim bezpieczeństwie - zapobiegamy w ten sposób udzieleniu pożyczki, której miesięczna rata byłaby dla Ciebie zbyt dużym obciążeniem.</p>
                    <p style={styles.answer}>Do udzielenia kredytu konieczne jest też posiadanie aktywnego członkostwa w SKOK Chmielewskiego. Jeżeli jeszcze nie jesteś naszym Klientem - <a href="https://www.skokchmielewskiego.pl/standard/jak-sie-zapisac" target="_blank" rel="noreferrer">sprawdź jak się zapisać.</a></p>
                </AccordionItem>
                <AccordionItem title="Kiedy otrzymam decyzję kredytową?">
                    <p style={styles.answer}>Po wypełnieniu i wysłaniu przez Ciebie wniosku kredytowego nastąpi proces weryfikacji, podczas którego sprawdzimy poprawność Twoich danych oraz historię kredytową. Decyzję otrzymasz niezwłocznie (nie później niż w ciągu 1 dnia roboczego), w drodze rozmowy telefonicznej lub wiadomości tekstowej.</p>
                    <p style={styles.answer}>Jeżeli decyzja będzie pozytywna, podczas rozmowy przedstawimy Ci także ofertę kredytową. Jej warunki zależeć będą od wyniku przeprowadzonej weryfikacji.</p>
                </AccordionItem>
                <AccordionItem title="W jaki sposób mogę podpisać umowę pożyczkową?">
                    <p style={styles.answer}>Dla Twojego bezpieczeństwa umowę pierwszej pożyczki otrzymanej za pośrednictwem serwisu www.praktycznepozyczki.pl, podpiszemy w wybranym przez Ciebie oddziale SKOK im. Z. Chmielewskiego. Zapobiegamy w ten sposób próbom wyłudzenia pieniędzy przez osoby posługujące się skradzioną tożsamością. Kolejne pożyczki będzie można zawierać już w pełni online, za pośrednictwem serwisu www.praktycznepozyczki.pl.</p>
                </AccordionItem>
                <AccordionItem title="W jaki sposób mogę spłacać pożyczkę?">
                    <p style={styles.answer}>Pożyczkę należy spłacać zgodnie z ustalonym harmonogramem. Zrobisz to przelewając kwotę raty na wskazany w umowie numer rachunku pożyczkowego. Szczegółowych informacji udzieli Ci nasz doradca podczas wizyty w oddziale.</p>
                </AccordionItem>
                <AccordionItem title="Czy moje dane są bezpieczne?">
                    <p style={styles.answer}>Dane osobowe przetwarzamy zgodnie z obowiązującymi przepisami prawa krajowego oraz europejskiego. Administratorem Twoich danych jest Spółdzielcza Kasa Oszczędnościowo–Kredytowa im. Zygmunta Chmielewskiego z siedzibą w Lublinie 20-607, ul. Wallenroda 2E. Możesz kontaktować się z nami korespondencyjnie, telefonicznie pod numerem telefonu 81 446 30 00, elektronicznie pod adresem e-mail list@skokchmielewskiego.pl lub osobiście, poprzez wizytę w naszej placówce.</p>
                    <p style={styles.answer}>Dla zapewnienia większego bezpieczeństwa Twoich danych i ułatwienia kontaktu z nami w sprawach dotyczących przetwarzania Twoich danych osobowych, wyznaczyliśmy Inspektora ochrony danych, z którym możesz kontaktować się elektronicznie pod adresem e-mail: iod@skokchmielewskiego.pl</p>
                </AccordionItem>
                <AccordionItem title="Kto udziela pożyczki?">
                    <p style={styles.answer}>Pożyczki udziela Spółdzielcza Kasa Oszczędnościowo-Kredytowa im. Zygmunta Chmielewskiego z siedzibą w Lublinie przy ul. Wallenroda 2E (20-607 Lublin), zarejestrowana w rejestrze przedsiębiorców Krajowego Rejestru Sądowego pod numerem KRS 0000089688, której akta rejestrowe przechowuje Sąd Rejonowy Lublin-Wschód w Lublinie z siedzibą w Świdniku, VI Wydział Gospodarczy Krajowego Rejestru Sądowego, posiadająca nr REGON: 430398277 oraz nr NIP: 7120170636.</p>
                </AccordionItem>
            </Accordion>
        </div>
    )
}

const styles = {
    box: {
        boxSizing: 'border-box',
        borderRadius: 20,
        background: Colors.white,
        padding: 20,
        marginBottom: 40,
    },
    title: {
        fontWeight: 'bold',
        fontSize: 30,
        textAlign: 'center',
    },
    question: {
        fontWeight: 600,
        fontSize: 20,
        color: Colors.black,
    },
    answer: {
        fontWeight: 400,
        fontSize: 16,
        marginTop: 12,
    }
}

export default QA;
