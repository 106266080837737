import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { Colors } from '../../../utils/styleUtils/Colors';
 
const PhoneInput = props => {
    const [validate, setValidate] = useState(false);

    useEffect(() => {
        props.onValidation(isValid(props.value));
    }, [props.value]);

    function isValid(number) {
        const valLengthWithoutDashes = number.replace(/-|_|\s/g, "").length;
        return valLengthWithoutDashes === 9;
    };

    return (
        <div>
            <div className="bx--form-item bx--text-input-wrapper">
                <div className="bx--text-input__field-outer-wrapper">
                    {props.labelText && <p className="bx--label">{props.labelText}</p> }
                    <InputMask 
                        className={validate && !isValid(props.value) ? "bx--text-input bx--text__input bx--text-input--invalid" :  "bx--text-input bx--text__input"}
                        mask="999 999 999"
                        placeholder="123 456 789"
                        value={props.value}
                        onChange={e => props.onChange(e.target.value)}
                        onBlur={() => setValidate(true)}
                    />
                </div>
            </div>
            { validate && !isValid(props.value) && <p style={styles.errorText}>Podany numer jest nieprawidłowy</p> }
        </div>
    );
}

const styles = {
    errorText: {
        color: Colors.error,
        display: 'block',
        fontWeight: 400,
        fontSize: '0.75rem',
        lineHeight: 1.34,
        letterSpacing: 0.32,
        margin: '0.25rem 0 0',
    }
}

export default PhoneInput;
