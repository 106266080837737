import React from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import { FormGroup, RadioButtonGroup, RadioButton } from 'carbon-components-react';
import { Colors } from '../../../utils/styleUtils/Colors';


function RadioGroupField(props) {
    const { label, data, helperText, ...rest } = props;
    const [field, meta, helpers] = useField(props);
    const { value: selectedValue } = field;
    const [touched, error] = at(meta, 'touched', 'error');
    const isError = touched && error && true;

    return (
        <FormGroup 
            legendText={props.labelText}
            style={{ marginBottom: 0 }} 
        >
            <RadioButtonGroup 
                {...field} 
                {...rest} 
                value={selectedValue !== '' && selectedValue} 
                onChange={helpers.setValue}
                defaultSelected={meta.value}
            >
                {data.map((item, index) => (
                    <RadioButton key={index} value={item.value} labelText={item.label} />
                ))}
            </RadioButtonGroup>
            { helperText && <p style={styles.helperText}>{helperText}</p>}
            {isError && <p style={styles.error}>{error}</p>}
        </FormGroup>
    );
}

const styles = {
    error: {
        fontWeight: 400,
        color: Colors.error,
        fontSize: '0.75rem',
    },
    helperText: {
        paddingTop: 4,
        paddingBottom: 8,
        fontWeight: 400,
        color: '#525252',
        fontSize: '0.75rem',
    }
}

export default RadioGroupField;
