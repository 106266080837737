import { Column, Grid, Row } from 'carbon-components-react';
import HeaderFooterTemplate from '../headerFooterTemplate/HeaderFooterTemplate';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Dimension } from '../../utils/styleUtils/Dimension';
import LaptopImg from '../../assets/laptop_article.png';
import { Colors } from '../../utils/styleUtils/Colors';

import { useAnalyticsEventTracker } from '../../hooks/useAnalyticsEventTracker';

const SecurityPage = () => {
    const { width } = useWindowDimensions();
    useAnalyticsEventTracker('Bezpieczenstwo')

    const content = () => (
        <Grid>
            <Row>
                <Column sm={0} md={1}></Column>
                <Column sm={4} md={6}>
                    <div style={styles.box}>
                        <div style={{position: 'relative'}}>
                            <img src={LaptopImg} alt="laptop" style={styles.photo} />
                            <h1 style={width > Dimension.sm ? styles.photoTitle : {...styles.photoTitle, fontSize: 20}}>Bezpieczeństwo w sieci</h1>
                        </div>
                        <Row>
                            <Column sm={0} md={1}></Column>
                            <Column sm={4} md={6}>
                                <div style={styles.content}>
                                    <p style={styles.title}>Jak zadbać o swoje bezpieczeństwo w sieci?</p>
                                    <p>Internet daje nam dzisiaj mnóstwo możliwości. Mając dostęp do sieci możemy w łatwy i szybki sposób wyszukiwać potrzebne informacje, kontaktować się ze znajomymi, ale także robić zakupy, opłacać rachunki czy zarządzać swoim kontem w banku. Internet stał się pomocny w niemal każdej dziedzinie życia, a umiejętne wykorzystywanie jego zalet pozwala na znaczące podniesienie naszego komfortu - w końcu wiele spraw możemy dziś załatwić online, bez konieczności wychodzenia z domu.</p>
                                    <p>Niestety - obok tych możliwości czyhają na nas również niebezpieczeństwa w postaci złośliwego oprogramowania czy oszustów podszywających się np. pod znane instytucje i próbujących wyłudzić nasze dane. Jak więc zadbać o to, aby wszystkie nasze aktywności w Internecie były dla nas jak najbezpieczniejsze? Poniżej prezentujemy 12 zasad, dzięki którym korzystanie z Internetu stanie się czystą przyjemnością!</p>
                                    <p style={styles.title}>Korzystaj z oryginalnego oprogramowania i pamiętaj o regularnych aktualizacjach</p>
                                    <p>Zawsze instaluj oryginalne oprogramowanie pochodzące z legalnego źródła - tylko takie jest na bieżąco aktualizowane. Twoje bezpieczeństwo w sieci w dużej mierze zależy właśnie od tego czy regularnie aktualizujesz system, oprogramowanie antywirusowe, a także wszystkie zainstalowane aplikacje. Producent zawsze dba o to, aby użytkownicy mieli stały dostęp do najbardziej aktualnych wersji oprogramowania. Dzięki regularnym aktualizacjom zadbasz o bieżące usuwanie luk w zabezpieczeniach, które mogłyby ułatwić dostęp do Twoich danych. Pamiętaj również aby korzystać z aktualnych, stabilnych wersji najbardziej popularnych przeglądarek internetowych.</p>
                                    <p style={styles.title}>Pamiętaj o oprogramowaniu antywirusowym</p>
                                    <p>Regularnie aktualizowany i aktywnie działający program antywirusowy jest skutecznym zabezpieczeniem przed zagrożeniami. Pamiętaj jednak, aby korzystać z oprogramowania renomowanego producenta, mieć zainstalowaną zaporę osobistą (ang. personal firewall) oraz oprogramowanie antyspyware.</p>
                                    <p style={styles.title}>Pobieraj pliki tylko z zaufanych źródeł</p>
                                    <p>Aby mieć pewność, że razem z pobieranym plikiem nie ściągniesz na swoje urządzenie szkodliwego oprogramowania, powinieneś korzystać jedynie z zaufanych i legalnych źródeł. Nielegalnie działające strony są jednym z najczęstszych powodów infekcji komputera złośliwym oprogramowaniem.</p>
                                    <p style={styles.title}>Nie korzystaj z publicznych sieci Wi-Fi</p>
                                    <p>Logując się do miejsc, w których podajesz swoje poufne dane, nie korzystaj z otwartych sieci Wi-Fi. Istnieje wtedy ryzyko, że inna osoba, która również korzysta z tej sieci, zyska dostęp do Twoich danych. Nie używaj do tego również urządzeń ogólnodostępnych lub takich, na których zalogowany jest inny użytkownik. Najlepszym wyborem będzie osobisty komputer połączony z zaufaną siecią.</p>
                                    <p style={styles.title}>Zwracaj uwagę na certyfikat SSL</p>
                                    <p>W miejscach, w których podajesz swoje dane (np. wypełniasz formularz, rejestrujesz się w serwisie internetowym, robisz zakupy online), zwróć uwagę na to, czy strona jest zabezpieczona za pomocą protokołu https. Aby to sprawdzić kliknij w symbol kłódki, który znajduje się obok adresu strony w przeglądarce. Jeżeli dana witryna posiada certyfikat SSL otrzymasz komunikat “Połączenie jest bezpieczne”. Oznacza to, że wszystkie informacje pozostają prywatne.</p>
                                    <p style={styles.title}>Pamiętaj o dwuetapowej weryfikacji</p>
                                    <p>Logując się do serwisów, w których udostępniasz swoje dane, powinieneś także pamiętać o dwuetapowym procesie weryfikacji tożsamości. Przykładem może być konieczność wpisania kodu, który po zalogowaniu dostaniesz na numer telefonu lub potwierdzenie logowania w aplikacji mobilnej. Dzięki temu nawet w sytuacji, gdy ktoś odgadnie Twoje hasło, nie uzyska dostępu do Twoich danych. </p>
                                    <p style={styles.title}>Zawsze wylogowuj się z serwisów, z których korzystasz</p>
                                    <p>Kiedy już skończysz korzystać z serwisu wymagającego logowania zawsze pamiętaj o tym, aby się z niego wylogować. Jest to szczególnie ważne w przypadku korzystania z sieci współdzielonych z innymi użytkownikami. Dzięki temu Twoje dane nie dostaną się w niepowołane ręce.</p>
                                    <p style={styles.title}>Twórz hasła trudne do odgadnięcia</p>
                                    <p>Aby zadbać o swoje bezpieczeństwo powinieneś jak najbardziej utrudnić innym dostęp do swoich danych. Dlatego warto korzystać z haseł, które trudno odgadnąć - najlepiej, aby nie była to data urodzenia, imię, krótkie, popularne słowo. Dobrze, aby składało się z wielu znaków (liczb, małych i wielkich liter oraz symboli specjalnych). Szczególnie ważne jest to w przypadku haseł np. do systemu bankowości elektronicznej, poczty, routera oraz wszystkich miejsc, w których udostępniasz swoje dane osobowe. Ważne też, aby nie używać tego samego hasła we wszystkich miejscach!</p>
                                    <p style={styles.title}>Nie otwieraj maili od podejrzanych nadawców</p>
                                    <p>To, co powinno wzbudzić Twoje podejrzenia, to błędy, literówki, nieskładna gramatyka, adresy mailowe niezgodne z oficjalnym adresem (np. banku czy innej instytucji), nadawcy, których nie znasz itd. Nigdy nie otwieraj takich maili, nie klikaj w linki, nie otwieraj załączników i nie odpowiadaj na te wiadomości. Warto także jak najszybciej je usuwać. Fałszywe maile są najczęstszą przyczyną zainfekowania komputera złośliwym oprogramowaniem.</p>
                                    <p>Skanuj pliki zapisane na nośnikach zewnętrznych. Zanim otworzysz plik otrzymany na nośniku zewnętrznym (np. pendrive), pamiętaj, aby przeskanować go za pomocą programu antywirusowego. Dzięki temu zabezpieczysz swój komputer przed ewentualnym zainfekowaniem przez szkodliwe oprogramowanie.</p>
                                    <p style={styles.title}>Chroń wszystkie swoje urządzenia</p>
                                    <p>Pamiętaj, aby chronić nie tylko swój komputer, ale także inne urządzenia, takie jak tablet czy smartfon. Zadbaj o to nie tylko wtedy, gdy logujesz się na nich do miejsc, gdzie udostępniasz swoje dane, ale także wtedy, gdy jedynie łączysz się przez nie ze swoją siecią Wi-Fi.</p>
                                    <p style={styles.title}>Nie udostępniaj swoich danych niezaufanym podmiotom</p>
                                    <p>W dużej mierze to od Ciebie zależy czy Twoje dane będą bezpieczne. Możesz być o to spokojny, jeżeli należycie je chronisz. Pamiętaj, aby nie udostępniać poufnych informacji niezaufanym podmiotom, chroń swoje dokumenty osobiste, a w przypadku ich zgubienia lub kradzieży - niezwłocznie je zastrzeż.</p>
                                </div>
                            </Column>
                            <Column sm={0} md={1}></Column>
                        </Row>
                    </div>
                </Column>
                <Column sm={0} md={1}></Column>
            </Row>
        </Grid>
    );

    return (
        <HeaderFooterTemplate content={content} hideBasicInfo={true} />

    )
};

const styles = {
    box: {
        background: Colors.white,
        boxShadow: `0px 3.40014px 85.0036px ${Colors.boxShadowsColor}`,
        borderRadius: 17,
        width: '100%',
        marginBottom: 32,
    },
    photo: {
        width: '100%',
    },
    photoTitle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontWeight: 'bold',
        fontSize: 30,
        color: Colors.white,
        textAlign: 'center',
    },
    content: {
        paddingTop: 16,
        paddingBottom: 32,
        paddingLeft: 16,
        paddingRight: 16,
    },
    title: {
        fontWeight: 600,
        fontSize: 21,
        paddingBottom: 16,
        paddingTop: 32,
    }
};

export default SecurityPage;
