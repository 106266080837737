function peselDecode(pesel) {
    // http://artpi.pl/?p=8
    // Funkcja dekodujaca nr. Pesel 
    // Wycinamy daty z numeru
    if (!pesel) return { valid: false };
    var rok=parseInt(pesel.substring(0,2),10);
    var miesiac = parseInt(pesel.substring(2,4),10)-1;
    var dzien = parseInt(pesel.substring(4,6),10);
    // Powszechnie uwaza sie, iz daty w numerach pesel obejmuja tylko ludzi urodzonych do 2000 roku. Na szczescie prawodawcy o tym pomysleli i do miesiaca dodawane sa liczby tak, by pesele starczyly az do 23 wieku. 
    if(miesiac>80) {
         rok = rok + 1800;
         miesiac = miesiac - 80;
    }
    else if(miesiac > 60) {
         rok = rok + 2200;
         miesiac = miesiac - 60;
    }
    else if (miesiac > 40) {
         rok = rok + 2100;
         miesiac = miesiac - 40;
    }
    else if (miesiac > 20) {
         rok = rok + 2000;
         miesiac = miesiac - 20;
    }
    else
    {
         rok += 1900;
    }
    // Daty sa ok. Teraz ustawiamy.
    var urodzony=new Date();
    urodzony.setFullYear(rok, miesiac, dzien);
     
    // Teraz zweryfikujemy numer pesel
    // Metoda z wagami jest w sumie najszybsza do weryfikacji.
    var wagi = [9,7,3,1,9,7,3,1,9,7];
    var suma = 0;
     
    for(var i=0;i<wagi.length;i++) {
        suma+=(parseInt(pesel.substring(i,i+1),10) * wagi[i]);
    }
    suma=suma % 10;
    var valid=(suma===parseInt(pesel.substring(10,11),10));
     
    //plec
     let plec;
     if(parseInt(pesel.substring(9,10),10) % 2 === 1) { 
          plec='male';
     } else {
          plec='female';
     }
    return { valid: valid, sex: plec, date: urodzony };
 }
 
 export default peselDecode;
 