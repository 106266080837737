const getBranches = (withAtm) => {
    return fetch('/api/offer/get-branches', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ withAtm: withAtm })
    })
    .then(res => res.json())
    .then(res => res)
    .catch(e => console.log(e))
}

export { getBranches }