import React, { useEffect, useState, useRef } from 'react';
import { Button, InlineLoading, Modal } from 'carbon-components-react';
import { useField } from 'formik';
import { Colors } from '../../../utils/styleUtils/Colors';
import ReactCodeInput from 'react-verification-code-input';
import './PhoneVerificationModal.scss';
import PhoneCheckIcon from '../../../assets/mobile--check.svg';
import { postSendSmsCode } from '../../../utils/postGetMethods/postSendSmsCode';

export const SendingStatus = Object.freeze({NOT_SEND: 'NOT_SEND', SEND_SUCCESS: 'SEND_SUCCESS', SEND_ERROR: 'SEND_ERROR'});
const VerificationStatus = Object.freeze({SUCCESS: 'SUCCESS', FAILED: 'FAILED', UNKNOWN: 'UNKNOWN'});

const PhoneVerificationModal = props => {
    const [verificationCode, setVerificationCode] = useState('');
    const [sending, setSending] = useState(false);
    const [sendingStatus, setSendingStatus] = useState(SendingStatus.NOT_SEND);
    const [verificationStatus, setVerificationStatus] = useState(VerificationStatus.UNKNOWN);
    const [phoneVerifiedField,,] = useField({ name: props.formField.phoneVerified.name });
    const [phoneField,,] = useField({ name: props.formField.phone.name });
    const [disabled, setDisabled] = useState(false);
    const [secondsLeft, setSecondsLeft] = useState(60);
    const intervalRef = useRef();

    useEffect(() => {
        if (verificationStatus === VerificationStatus.SUCCESS) props.onVerificationSuccess();
    }, [verificationStatus]);

    useEffect(() => {
        if (!phoneVerifiedField.value) {
            resetState();
        }
    }, [phoneVerifiedField.value]);

    useEffect(() => {
        resetState();
    }, [phoneField.value]);

    useEffect(() => {
        if (sendingStatus === SendingStatus.SEND_SUCCESS) {
            setDisabled(true);
            const countdownInterval = setInterval(() => {
                setSecondsLeft(prevSeconds => {
                    if (prevSeconds > 0) return prevSeconds - 1;
                    else {
                        setDisabled(false);
                        clearInterval(countdownInterval);
                        return 60;
                    }
                })
            }, 1000)
            intervalRef.current = countdownInterval;
        }
    }, [sendingStatus]);

    function resetState() {
        setVerificationCode('');
        setSending(false);
        setSendingStatus(SendingStatus.NOT_SEND);
        setVerificationStatus(VerificationStatus.UNKNOWN);
        setDisabled(false);
        clearInterval(intervalRef.current);
    };

    async function sendVerificationCode() {
        setDisabled(true);
        setSendingStatus(SendingStatus.NOT_SEND);
        setVerificationStatus(VerificationStatus.UNKNOWN);
        setSending(true);
        const code = (Math.floor(Math.random() * 10000) + 10000).toString().substring(1);
        setVerificationCode(code);
        postSendSmsCode(
            `48${phoneField.value.replace(/-|_|\s/g, "")}`,
            code
        )
        .then(output => {
            if (output.status === 'OK') {
                setSending(false);
                setSendingStatus(SendingStatus.SEND_SUCCESS);
                console.log('Response: ', output);
            } else if (output.status === 'DEV') {
                setSending(false);
                setSendingStatus(SendingStatus.SEND_SUCCESS);
                console.log('DEV: ', code);
            } else {
                setSending(false);
                setSendingStatus(SendingStatus.SEND_ERROR);
                console.log('Error while sending SMS: ', output);
            }
        })
        .catch(error => {
            setSending(false);
            setSendingStatus(SendingStatus.SEND_ERROR);
            console.log('Error while sending data to SMS: ', error);
        })
    }

    function verifyCode(code) {
        code === verificationCode ? setVerificationStatus(VerificationStatus.SUCCESS) : setVerificationStatus(VerificationStatus.FAILED); 
    };

    return (
        <Modal className="verify-phone-modal" size="sm" open={props.open} onRequestClose={props.onClose} passiveModal>
            {
                verificationStatus === VerificationStatus.SUCCESS 
                ? (
                    <div style={styles.successBox}>
                        <img src={PhoneCheckIcon} alt="phone-check-icon" style={styles.successIcon}/>
                        <p style={styles.successText}>Weryfikacja przebiegła pomyślnie, możesz wrócić do formularza!</p>
                    </div>
                )
                : (
                    <>
                        <p style={styles.instruction}>Aby upewnić się, że Twoje dane są prawidłowe i aktualne, prosimy o weryfikację numeru telefonu przez wiadomość SMS.</p> 
                        <p style={styles.instruction}>Po kliknięciu poniższego przycisku, wyślemy do Ciebie czterocyfrowy kod weryfikacyjny.</p>
                        { sending ? <InlineLoading style={styles.sending} description="Wysyłam kod..." /> : <Button style={styles.sendCodeButton} onClick={sendVerificationCode} disabled={disabled}>Wyślij kod</Button> }
                        {
                            sendingStatus === SendingStatus.SEND_SUCCESS && (
                                <div>
                                    <p style={{...styles.instruction, marginBottom: 4}}>Kod został wysłany na Twój telefon! Wpisz go w poniższe pola.</p>
                                    { 
                                        disabled 
                                        ? <p style={styles.smallText}>{`Jeśli nie otrzymałeś/aś kodu, za ${secondsLeft.toString()} s. będziesz mógł ponowić próbę.`}</p>
                                        : <p style={styles.smallText}>Jeśli nie otrzymałeś/aś kodu, ponów próbę.</p>
                                    }
                                    <ReactCodeInput className={`verification-code ${verificationStatus === VerificationStatus.FAILED && 'error'}`} type="number" fields={4} onComplete={verifyCode} />
                                    { verificationStatus === VerificationStatus.FAILED && <p style={styles.errorText}>Podany kod jest niepoprawny.</p> }
                                </div>
                            )
                        }
                    </>
                )
            }
            <Button style={styles.closeButton} onClick={props.onClose}>Zamknij</Button>
        </Modal>
    )
}

const styles = {
    instruction: {
        textAlign: 'center',
        marginBottom: 12,
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 8,
    },
    sendCodeButton: {
        background: Colors.white,
        border: `2px solid ${Colors.mystic}`,
        borderRadius: 6,
        color: Colors.lightGreen,
        fontSize: 16,
        fontWeight: 500,
        paddingLeft: 16,
        paddingRight: 16,
        width: '100%',
        justifyContent: 'center',
        minHeight: 0,
        height: 40,
        marginTop: 12,
        marginBottom: 12,
    },
    sending: {
        justifyContent: 'center',
    },
    errorText: {
        textAlign: 'center',
        color: Colors.errorText,
        marginTop: 8,
        fontSize: 14,
    },
    successBox: {
        background: Colors.authenticationBackground,
        boxSizing: 'border-box',
        borderRadius: 20,
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'fit-content',
        maxWidth: 660,
        marginTop: 16,
    },
    successIcon: {
        height: 80,
        filter: 'invert(44%) sepia(10%) saturate(2311%) hue-rotate(95deg) brightness(93%) contrast(83%)',
    },
    successText: {
        fontWeight: 700,
        fontSize: 18,
        textAlign: 'center',
        marginTop: 24,
    },
    smallText: {
        fontSize: 10,
        textAlign: 'center',
        marginBottom: 24,
    },
    closeButton: {
        backgroundColor: Colors.white,
        fontWeight: 500,
        fontSize: 18,
        color: Colors.green,
        textAlign: 'center',
        padding: 12,
        textDecoration: 'none',
        borderRadius: 4,
        borderWidth: 0,
    }
}

export default PhoneVerificationModal;
