import { Grid, Row, Column } from 'carbon-components-react';
import Logo from '../../assets/logo_black_white.png';
import { Colors } from '../../utils/styleUtils/Colors';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Dimension } from '../../utils/styleUtils/Dimension';

const Footer = () => {
    const { width } = useWindowDimensions();

    return (
        <div style={styles.root}>
            <Grid>
                <Row>
                    <Column sm={4} lg={3} style={width > Dimension.md ? {...styles.flex, ...styles.center} : {paddingLeft: 36}}>
                        <img src={Logo} alt="logo" style={styles.header}/>
                        <p style={styles.version}>Wersja oprogramowania: 1.2.1</p>
                    </Column>
                    <Column sm={4} lg={3} style={width > Dimension.md ? styles.flex : styles.flexSm}>
                        <div>
                            <p style={styles.textBold}>Dokumenty</p>
                            <div style={styles.linkDiv}>
                                <a 
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://www.skokchmielewskiego.pl/polityka-prywatnosci" 
                                    style={styles.link}
                                >Polityka prywatności</a>
                            </div>
                            <div style={styles.linkDiv}>
                                <a 
                                    target="_blank"
                                    rel="nofollow"
                                    href="docs/Regulamin.pdf" 
                                    style={styles.link}
                                >Regulamin</a>
                            </div>
                            <div style={styles.linkDiv}>
                                <a
                                    target="_blank"
                                    rel="nofollow"
                                    href="docs/Przetwarzanie-danych-osobowych-przez-SKOK-Chmieleskiego.pdf" 
                                    style={styles.link}
                                >Przetwarzanie danych osobowych przez SKOK im. Z. Chmielewskiego</a>
                            </div>
                            <div style={styles.linkDiv}>
                                <a
                                    target="_blank"
                                    rel="nofollow"
                                    href="docs/Przetwarzanie-danych-osobowych-przez-BIK.pdf" 
                                    style={styles.link}
                                >Przetwarzanie danych osobowych przez BIK S.A.</a>
                            </div>
                        </div>
                    </Column>
                    <Column sm={4} lg={3} style={width > Dimension.md ? styles.flex : styles.flexSm}>
                        <div>
                            <p style={styles.textBold}>Przydatne linki</p>
                            <div style={styles.linkDiv}>
                                <a href="/poznaj-nas-blizej" style={styles.link}>Poznaj nas bliżej</a>
                            </div>
                            <div style={styles.linkDiv}>
                                <a href="/nasza-misja" style={styles.link}>Nasza misja</a>
                            </div>
                            <div style={styles.linkDiv}>
                                <a href="/bezpieczenstwo" style={styles.link}>Bezpieczeństwo</a>
                            </div>
                        </div>
                    </Column>
                    <Column sm={4} lg={3} style={width > Dimension.md ? styles.flex : styles.flexSm}>
                        <div>
                            <p style={styles.textBold}>Kontakt</p>
                            <p>SKOK im. Z. Chmielewskiego</p>
                            <p>ul. Wallenroda 2E</p>
                            <p>20-607 Lublin</p>
                            <p>Tel.: <a href="tel:81446-30-00" style={styles.link}>81 446 30 00</a></p>
                            <p><a href="https://www.skokchmielewskiego.pl" style={styles.link}>www.skokchmielewskiego.pl</a></p>
                        </div>
                    </Column>
                </Row>
            </Grid>
        </div>
    )
}

const styles = {
    root: {
        width: '100%',
        paddingTop: 60,
        paddingBottom: 60,
        backgroundColor: Colors.footerBackground,
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: 12,
    },
    flexSm: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 24,
        paddingLeft: 36,
    },
    center: {
        justifyContent: 'center',
    },
    textBold: {
        fontSize: 20,
        fontWeight: 700,
        marginBottom: 16,
    },
    linkDiv: {
        paddingTop: 8,
        paddingBottom: 8,
    },
    link: {
        color: Colors.green,
        textDecoration: 'none',
    },
    version: {
        color: Colors.grey,
        fontSize: '0.75rem',
        marginTop: '0.313rem',
        textAlign: 'center'
    }
}

export default Footer;
