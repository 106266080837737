import React from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import { Checkbox } from 'carbon-components-react';

export default function CheckboxField(props) {
    const { errorText, ...rest } = props;
    const [field, meta, helpers] = useField(props);
    const [touched, error] = at(meta, 'touched', 'error');

    return (
        <>
            <Checkbox 
                {...rest} 
                {...field}
                checked={field.value}
                onChange={helpers.setValue}
            />
            { touched && error && <p style={styles.error}>{error}</p>}
        </>
    );
}

const styles = {
    error: {
        fontweight: 400,
        color: 'rgb(218, 30, 40)',
        fontSize: '0.75rem',
    }
}