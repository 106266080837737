import { Column, Grid, Row } from 'carbon-components-react';
import { useField } from 'formik';
import { Colors } from '../../../utils/styleUtils/Colors';
import InputField from '../formFields/InputField';
import SelectField from '../formFields/SelectField';
import RadioGroupField from '../formFields/RadioGroupField';
import IncomeForm from './IncomeForm';

import { useAnalyticsEventTracker } from '../../../hooks/useAnalyticsEventTracker';

const maritalStatuses = [
    'żonaty/mężatka', 
    'kawaler/panna', 
    'rozwiedziony/rozwiedziona',
    'wdowiec/wdowa', 
];

const educationTypes = ['wyższe', 'średnie', 'podstawowe'];
const loanTypes = ['Pożyczka gotówka', 'Pożyczka konsolidacyjna'];

const housingStatuses = [
    'właściciel mieszkania', 
    'wynajmujący mieszkanie', 
    'z rodziną'
]

const AdditionalInfoForm = ({ formField }) => {
    const [fieldOtherCharges] = useField({ name: formField.otherCharges.name });
    useAnalyticsEventTracker('Dane dodatkowe')

    return (
        <Grid style={styles.grid}>
            <Row>
                <Column sm={0} lg={1}></Column>
                <Column sm={4} md={8} lg={5}>
                    <IncomeForm 
                        id={formField.incomeSources.name}
                        name={formField.incomeSources.name}
                        formField={formField}
                    />
                </Column>
                <Column sm={4} md={8} lg={5}>
                    <div style={styles.box}>
                        <p style={styles.title}>Informacje dodatkowe</p>
                        <Grid>
                            <Row>
                                <Column sm={4} md={4} style={styles.col}>
                                    <SelectField
                                        id={formField.maritalStatus.name}
                                        name={formField.maritalStatus.name}
                                        labelText={formField.maritalStatus.label}
                                        data={maritalStatuses.map(elem => ({ label: elem, value: elem }))}
                                    /> 
                                </Column>
                                <Column sm={4} md={4} style={styles.col}>
                                    <SelectField
                                        id={formField.housing.name}
                                        name={formField.housing.name}
                                        labelText={formField.housing.label}
                                        data={housingStatuses.map(elem => ({ label: elem, value: elem }))}
                                    /> 
                                </Column>
                            </Row>
                            <Row>
                                <Column sm={4} md={4} style={styles.col}>
                                    <InputField
                                        id={formField.numberOfDependents.name}
                                        name={formField.numberOfDependents.name}
                                        labelText={formField.numberOfDependents.label}
                                        type="number"
                                        placeholder="Liczba osób"
                                        min={0}
                                    />
                                </Column>
                                <Column sm={4} md={4} style={styles.col}>
                                    <InputField
                                        id={formField.monthlyCosts.name}
                                        name={formField.monthlyCosts.name}
                                        labelText={formField.monthlyCosts.label}
                                        type="number"
                                        placeholder="Wpisz koszty"
                                        min={0}
                                    />
                                </Column>
                            </Row>
                            <Row>
                                <Column sm={4} style={styles.col}>
                                    <RadioGroupField
                                        id={formField.otherCharges.name}
                                        name={formField.otherCharges.name}
                                        labelText={formField.otherCharges.label}
                                        data={[
                                            { label: 'Tak', value: 'tak' },
                                            { label: 'Nie', value: 'nie' },
                                        ]}
                                        helperText="nie podawaj bankowych zobowiązań kredytowych pobieramy je z BIK"
                                    />
                                </Column>
                                {
                                    fieldOtherCharges.value === 'tak' && (
                                        <>
                                            <Column sm={4} md={4} style={styles.col}>
                                                <InputField
                                                    id={formField.courtCharges.name}
                                                    name={formField.courtCharges.name}
                                                    labelText={formField.courtCharges.label}
                                                    type="number"
                                                    placeholder="Wpisz kwotę"
                                                    min={0}
                                                />
                                            </Column>
                                            <Column sm={4} md={4} style={styles.col}>
                                                <InputField
                                                    id={formField.alimonyCharges.name}
                                                    name={formField.alimonyCharges.name}
                                                    labelText={formField.alimonyCharges.label}
                                                    type="number"
                                                    placeholder="Wpisz kwotę"
                                                    min={0}
                                                />
                                            </Column>
                                        </>
                                    )
                                }
                            </Row>
                            <Row>
                                <Column sm={4} style={styles.col}>
                                    <SelectField
                                        id={formField.loanType.name}
                                        name={formField.loanType.name}
                                        labelText={formField.loanType.label}
                                        data={loanTypes.map(elem => ({ label: elem, value: elem }))}
                                    />
                                </Column>
                            </Row>
                        </Grid>
                    </div>
                </Column>
                <Column sm={0} lg={1}></Column>
            </Row>
        </Grid>
    );
}

const styles = {
    grid: {
        width: '100%',
    },
    box: {
        border: `1px solid ${Colors.borderColor}`,
        boxSizing: 'border-box',
        borderRadius: 20,
        padding: 4,
        paddingTop: 16,
        paddingBottom: 20,
        marginTop: 48,
    },
    title: {
        fontWeight: 600,
        color: Colors.black,
        fontSize: 18,
        textAlign: 'center',
        marginBottom: 8,
    },
    col: {
        marginTop: 14,
    },
    datePicker: {
        width: '100%',
    }
}

export default AdditionalInfoForm;
