import { ListItem, UnorderedList } from 'carbon-components-react';
import React, { useEffect } from 'react';
import './_BranchesList.scss';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { Dimension } from '../../../utils/styleUtils/Dimension';
import { Colors } from '../../../utils/styleUtils/Colors';

const BranchesList = props => {
    const { width } = useWindowDimensions();

    useEffect(() => {
        props.selected && document.getElementById(`branches-list-${props.selected.LP}`).scrollIntoView({behavior: 'smooth', block: 'center'});
    }, [props.selected])

    return (
        <div style={styles.container}>
            <p style={width > Dimension.sm ? styles.header : {...styles.header, marginTop: 12}} id="branches-list-header">Lista oddziałów</p>
            <UnorderedList style={width > Dimension.sm ? styles.list : {...styles.list, paddingLeft: 0}}>
                {
                    props.branches.map((item, index) => (
                        <ListItem 
                            key={index} 
                            style={parseInt(props.selected?.LP) === parseInt(item.LP) ? {...styles.listItem, ...styles.listItemSelected} : styles.listItem} 
                            onClick={() => props.onBranchSelect(item)}
                            id={`branches-list-${item.LP}`}
                        >
                            <React.Fragment>
                                <p style={styles.cityText}>{item.MIEJSCOWOSC}</p> 
                                <p style={styles.addressText}>{item.ULICA}</p>
                                <p style={styles.addressText}>{item.KOD} {item.MIEJSCOWOSC}</p>
                                <p style={styles.addressText}>tel. {item['NR KIERUNKOWY']} {item.TELEFON}</p>
                                <div style={styles.openingHoursDiv}>
                                    <p style={styles.openingHoursText}>Godziny otwarcia:</p>
                                    <p style={styles.openingHoursText}>{item['GODZINY PRACY']}</p>
                                </div>
                            </React.Fragment>
                        </ListItem>
                    ))
                }
            </UnorderedList>
        </div>
    );
}

const styles = {
    container: {
        height: '50vh',
        minHeight: 350,
        display: 'flex',
        flexDirection: 'column',
    },
    list: {
        paddingLeft: 40,
        height: '100%',
        overflow: 'auto',
        flex: 1,
    },
    header: {
        fontWeight: 700,
        fontSize: 21,
        textAlign: 'center',
        paddingBottom: 16,
    },
    cityText: {
        fontSize: 16,
        fontWeight: 600,
        paddingBottom: 12,
    },
    openingHoursDiv: {
        paddingTop: 8,
    },
    openingHoursText: {
        fontSize: 14,
        opacity: 0.8,
        lineHeight: 1,
    },
    addressText: {
        lineHeight: 1,
    },
    listItem: {
        padding: 12,
        paddingLeft: 20,
        marginBottom: 8,
        marginRight: 4,
        background: Colors.branchesListItemBackground,
        border: `1px solid ${Colors.branchesListItemBorder}`,
        boxSizing: 'border-box',
        cursor: 'pointer',
    },
    listItemSelected: {
        background: Colors.branchesListItemBackgroundSelected,
        border: `1px solid ${Colors.branchesListItemBorderSelected}`,
        borderLeftWidth: '8px',
    },
}

export default BranchesList;
