import { useEffect, useState } from 'react';
import { Grid } from 'carbon-components-react';
import { Colors } from '../../../utils/styleUtils/Colors';
import { useFormikContext } from 'formik';
import GridLoader from 'react-spinners/GridLoader';
import RadioGroupField from '../formFields/RadioGroupField';
import PersonalizedCalculator from '../../creditCalculator/PersonalizedCalculator';
import BranchModal from './BranchModal';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { Dimension } from '../../../utils/styleUtils/Dimension';
import prepareJson from '../../../utils/prepareJson';
import ProgressBar from './ProgressBar';

import { useAnalyticsEventTracker } from '../../../hooks/useAnalyticsEventTracker';

const FinalForm = ({ formField, location, updateValues, onUpdateValues, setFail }) => {
    const [loading, setLoading] = useState(true);
    const [offer, setOffer] = useState({});
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState(0);
    const { values } = useFormikContext();
    const { width } = useWindowDimensions();

    useAnalyticsEventTracker('Oferta')

    useEffect(() => {
        const data = prepareJson(values, location.state.amount, location.state.installmentAmount, formField);
        fetch('/api/offer/send-form', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(output => output.json())
        .then(async json => {
            if (json.return === 'ERROR') {
                setMessage('Proszę spróbować później...');
                setLoading(false);
            } else {
                const link = json.dane.link;
                let iterations = 60;
                while (iterations > 0) {
                    const responseStatus = await fetch('/api/offer/get-form-status', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ link }),
                    })
                    const dataStatus = await responseStatus.json();
                    setStatus(dataStatus.dane.idStatus);
                    if (dataStatus.dane.idStatus === -5) {
                        const responseWarunki = await fetch('/api/offer/get-form-warunki', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({ link }),
                        });
                        const data = await responseWarunki.json();
                        if (typeof data.dane === 'object' && data.dane !== null) {
                            await fetch(`/api/offer/update-form`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({
                                    updateData: {
                                        kwota: 0,
                                        okres: 0,
                                        kwotaRaty: 0,
                                        prowizja: 0,
                                        wybranyOddzialWojewodztwo: undefined,
                                        wybranyOddzial: undefined,
                                        dataKontaktu: undefined,
                                        oprocentowanie: data.dane.oprocentowanie,
                                        prowizjaProcentowo: 0,
                                        kwotaBrutto: 0,
                                        zgody: Object.keys(values.zgody).filter(key => values.zgody[key]).map(id => parseInt(id)),
                                        zgodanaror: values.bill === 'yes' 
                                    },
                                    link: link,
                                    values,
                                }),
                            })
                            const { list_of_provisions } = await getCommission(
                                data.dane.scoreBD,
                                data.dane.oprocentowanie / 100,
                                data.dane.nowyKlient,
                            );
                            setOffer({
                                amount: location.state.amount,
                                installmentAmount: location.state.installmentAmount,
                                numberOfInstallments: location.state.numberOfInstallments,
                                maxInstallmentAmount: data.dane.maxRata,
                                fees: data.dane.oplaty,
                                score: data.dane.scoreBD,
                                listOfProvisions: list_of_provisions,
                                interest: data.dane.oprocentowanie / 100,
                                link: link
                            })
                            onUpdateValues({
                                interest: data.dane.oprocentowanie,
                                link: link,
                            });
                            setLoading(false);
                            break;
                        } else {
                            setFail(true);
                            setLoading(false);
                            break;
                        }
                    } else if (dataStatus.dane.idStatus > -5) {
                        setFail(true);
                        iterations = 0;
                    };
                    await new Promise(r => setTimeout(r, 5000));
                    iterations--;
                }
                if (iterations === 0) setFail(true);
            }
        })
        .catch(error => {
            console.log('Error while fetching API data: ', error);
        })

        // const data = prepareJson(values, location.state.amount, location.state.installmentAmount);

        // fetch('/api/offer/generate-offer', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify({ values, hash: window.location.hash.substring(1) })
        // })
        // .then(output => output.json())
        // .then(async json => {
        //     const { commission, rrso } = await getCommission(
        //         location.state.amount, 
        //         location.state.amount / location.state.installmentAmount, 
        //         json.dane.scoreBD
        //     );
        //     setOffer({
        //         max_installment: 1400,
        //         amount: location.state.amount,
        //         installment: location.state.installmentAmount,
        //         interest: json.dane.oprocentowanie / 100,
        //         commission: commission,
        //         fees: json.dane.oplaty,
        //         score: json.dane.scoreBD,
        //         rrso: rrso,
        //     });
        //     onUpdateValues({
        //         amount: location.state.amount,
        //         installmentAmount: location.state.installmentAmount,
        //         commission: commission,
        //         numberOfInstallments: parseInt(location.state.amount / location.state.installmentAmount),
        //         branch: '',
        //         branchVoivodeship: '',
        //     });
        //     setLoading(false);
        // })
    }, [])

    async function getCommission(score, interest, newClient) {
        const response = await fetch('/api/offer/get-commission', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ score, interest, newClient })
        });
        const commission = await response.json();
        return commission;
    };

    function updateValuesFromCalculator(newValues) {
        onUpdateValues({...updateValues, ...newValues });
    };

    function updateBranch(branch) {
        if (typeof branch !== "undefined"){
            onUpdateValues({
                ...updateValues, 
                fullBranch: branch,
                branchVoivodeship: branch.WOJEWODZTWO, 
                branch: `${branch.MIEJSCOWOSC}, ${branch.ULICA}`,
            });
        }
    }

    return loading
        ? (
            <div style={styles.loader}>
                <p style={width > Dimension.sm ? styles.loadingTitle : styles.loadingTitleSm}>Generujemy spersonalizowaną ofertę specjalnie dla Ciebie.</p>
                <p style={styles.loadingSubtitle}>Prosimy o chwilę cierpliwości.</p>
                <ProgressBar status={status}/>
            </div>
        )
        : ( message ? 
            <div style={styles.loader}>
                <p style={styles.header}>Niestety nie udało się wygenerować dla Ciebie spersonalizowanej oferty.</p>
                <p style={styles.loadingTitleSm}>{message}</p>
            </div>
            : <Grid style={width > Dimension.sm? {} : {maxWidth: "20rem"}}>
                <p style={styles.header}>Symulowane warunki cenowe przygotowane specjalnie dla Ciebie</p>
                <p style={{...styles.text, marginBottom: 0}}>Warunki cenowe ważne przez 3 dni robocze.</p>
                <p style={styles.text}>Kalkulator prezentuje wszystkie dostępne propozycje. Dostosuj warunki do własnych potrzeb.</p>
                <PersonalizedCalculator data={offer} onUpdateValues={updateValuesFromCalculator} />
                <p style={styles.header}>Sposób wypłaty pożyczki po zawarciu umowy</p>
                <div style={styles.radios}>
                    {
                        values.member === 'tak' ?
                        <RadioGroupField
                            id={formField.moneyPickup.name}
                            name={formField.moneyPickup.name}
                            labelText={formField.moneyPickup.label}
                            data={[
                                { label: 'Przelew', value: 'transfer' },
                                { label: 'Zawarcie umowy i wypłata pożyczki w oddziale', value: 'office' },
                            ]}
                        />
                        :
                        <RadioGroupField
                            id={formField.moneyPickup.name}
                            name={formField.moneyPickup.name}
                            labelText={formField.moneyPickup.label}
                            data={[
                                { label: 'Zawarcie umowy i wypłata pożyczki w oddziale', value: 'office' },
                            ]}
                        />
                    }
                    


                    { values.moneyPickup === 'office' && (
                        <div style={styles.additionalOptions}>
                            <BranchModal formField={formField} onUpdateBranch={updateBranch}/>
                        </div>
                    ) }

                    { values.moneyPickup === 'transfer' && (
                        <div style={styles.additionalOptions}>
                            <RadioGroupField
                                id={formField.bill.name}
                                name={formField.bill.name}
                                labelText={formField.bill.label}
                                data={[
                                    { label: 'Posiadasz rachunek oszczędnościowo-rozliczeniowy (ROR) w naszej Kasie lub jesteś gotowy założyć taki rachunek - skontaktujemy się z Tobą telefonicznie w ciągu 24 godzin* w celu dopełnienia formalności.', value: 'yes'},
                                    { label: 'Nie posiadasz rachunku oszczędnościowo-rozliczeniowego (ROR) w naszej kasie i nie jesteś gotowy na założenie takiego rachunku - zaznacz powyżej "Zawarcie umowy i wypłata pożyczki w oddziale".', value: 'no'}
                                ]}
                                orientation="vertical"
                            />
                            <p style={styles.note}>
                                * Z wyłączeniem sobót oraz dni ustawowo wolnych od pracy
                            </p>
                        </div>
                    )}
                </div>
                
            </Grid>
        )
}

const styles = {
    header: {
        fontWeight: 'bold',
        fontSize: 21,
        color: Colors.black,
        textAlign: 'center',
        marginTop: 36,
    },
    loader: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        flex: 1,
    },
    loadingTitle: {
        fontSize: 20,
        fontWeight: 600,
    },
    loadingTitleSm: {
        textAlign: 'center',
        marginTop: 20,
        fontSize: 18,
        fontWeight: 600,
    },
    loadingSubtitle: {
        marginBottom: 24,
    },
    radios: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    text: {
        fontSize: '1rem',
        margin: '0.5rem 0 1rem 0',
        textAlign: 'center'
    },
    note: {
        fontSize: '0.75rem', 
        marginTop: '0.75rem', 
        marginBottom: '1rem',
    },
    additionalOptions: {
        margin: '3rem 8rem 3rem 8rem'
    }
}

export default FinalForm;
