import CheckboxField from '../formFields/CheckboxField';

const AgreementMarketingCheckboxes = ({ formField }) => {
    return (
        <div style={styles.root}>
            <p style={styles.textBold}>Wyrażenie poniższych zgód sprawi, że będziesz zawsze na bieżąco odnośnie naszej oferty. Poniższe zgody są dobrowolne.</p>
            {
                Object.keys(formField.zgody).map(id => (
                    !formField.zgody[id].required 
                    ? <CheckboxField 
                        id={`zgody.${formField.zgody[id].name}`}
                        name={`zgody.${formField.zgody[id].name}`}
                        labelText={formField.zgody[id].label}/> 
                    : <></>
                ))
            }
        </div>
    )
}

const styles = {
    root: {
        marginTop: 32,
        padding: 16,
    },
    textBold: {
        fontWeight: 600,
        marginBottom: 12,
    },
}

export default AgreementMarketingCheckboxes;
