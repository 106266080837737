import React from 'react';
import Header from './Header';
import BasicInfo from './BasicInfo';
import Footer from './Footer';

const HeaderFooterTemplate = ({ content, subcontent, hideBasicInfo, activeLogoLink }) => {
    return (
        <div>
            <div style={styles.main}>
                <Header activeLogoLink={activeLogoLink}/>
                { content() }
            </div>
            { !hideBasicInfo && <BasicInfo /> }
            { subcontent && subcontent() }
            <Footer />
        </div>
    )
};

const styles = {
    main: {
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    }
}

export default HeaderFooterTemplate;
