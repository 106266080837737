import React from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import { Select, SelectItem } from 'carbon-components-react';


function SelectField(props) {
    const { label, data, placeholder, ...rest } = props;
    const [field, meta] = useField(props);
    const { value: selectedValue } = field;
    const [touched, error] = at(meta, 'touched', 'error');
    const isError = touched && error && true;

    return (
        <Select 
            {...field} 
            {...rest}
            value={selectedValue ? selectedValue : ''}
            invalid={isError}
            invalidText={isError && error}
        >
            <SelectItem hidden value="placeholder" text={placeholder ? placeholder : "Wybierz..."} />
                {data.map((item, index) => (
                    <SelectItem key={index} value={item.value} text={item.label} />
                ))}
        </Select>
    );
}

export default SelectField;
