import { useEffect } from 'react';
import ReactGA from "react-ga4";

export const useAnalyticsEventTracker = (screenName) => {
    const titleApp = 'Praktyczne Pożyczki od SKOK Chmielewskiego'
    useEffect(() => {
        ReactGA._gtag('event', 'screen_view', {
            'screen_name': screenName,
            'page_title': screenName
        });
        return ()=> ReactGA.reset();
    }, [
        screenName,
    ]);
}