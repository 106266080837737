import React, { useEffect, useState, useMemo } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import marker from '../../../assets/marker.png';

const mapStyles = {
    position: 'relative',
    width: '100%',
    height: '100%',
};

const MapContainer = props => {
    const [zoom, setZoom] = useState(6);
    const [center, setCenter] = useState({ lat: 52.114339, lng: 19.423672});

    useEffect(() => {
        if (props.selected) {
            setCenter({ lat: props.selected.LAT, lng: props.selected.LNG });
            setZoom(15);
        };
    }, [props.selected])

    const displayMarkers = branches => {
        return branches.map((point, index) => {
                return <Marker key={index} id={index} position={{
                        lat: point.LAT,
                        lng: point.LNG
                    }}
                    onClick={() => props.onBranchSelect(point)} 
                    icon={{
                        url: marker,
                        // scaledSize: new props.google.maps.Size(46,35)
                        scaledSize: new props.google.maps.Size(37,52)
                    }}
                />
        })
    }

    const markers = useMemo(() => displayMarkers(props.branches), [props.branches]);

    return (
        <Map
            google={props.google}
            zoom={zoom}
            style={mapStyles}
            initialCenter={center}
            center={center}
        >
            {markers}
        </Map>
    );
};


export default GoogleApiWrapper(props => {
    return {
        apiKey: ('AIzaSyAU2OptJhxwIXwDiXrYKURNafp3smSVAkg'),
        language: 'pl',
    }
})(MapContainer);
