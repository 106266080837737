import formModel from './model';
const {
    formField: {
        firstName,
        lastName,
        pesel,
        nationality,
        documentType,
        idNumber,
        issueDate,
        expirationDate,
        indefinite,
        member,
        street,
        houseNumber,
        aptNumber,
        postcode,
        city,
        phoneType,
        phone,
        phoneVerified,
        email,
        confirmEmail,
        incomeSources,
        incomeSource,
        maritalStatus,
        education,
        housing,
        numberOfDependents,
        monthlyCosts,
        otherCharges,
        courtCharges,
        alimonyCharges,
        loanType,
        uopIndefinite,
        uopToDate,
        ownBusinessFrom,
        ownBusinessIndustry,
        ownBusinessNIP,
        ownBusinessSettlementForm,
        pensionIndefinite,
        pensionToDate,
        preretireStartDate,
        employerName,
        employerNIP,
        employerNumber,
        nettoIncome,
        moneyPickup,
        bill,
        branch,
        visitDay,
        branchModal,
        selectAllCheckbox,
        zgody,
        biometricAgreement,
        powiazanieRadio,
    }
} = formModel;

const initialValues =  {
    [firstName.name]: '',
    [lastName.name]: '',
    [pesel.name]: '',
    [nationality.name]: 'PL',
    [documentType.name]: '',
    [idNumber.name]: '',
    [issueDate.name]: '',
    [expirationDate.name]: '',
    [indefinite.name]: false,
    [member.name]: '',
    [street.name]: '',
    [houseNumber.name]: '',
    [aptNumber.name]: '',
    [postcode.name]: '',
    [city.name]: '',
    [phone.name]: '',
    [phoneVerified.name]: false,
    [email.name]: '',
    [confirmEmail.name]: '',
    [incomeSources.name]: [{
        [incomeSource.name]: '',
        [uopIndefinite.name]: '',
        [uopToDate.name]: '',
        [ownBusinessFrom.name]: '',
        [ownBusinessIndustry.name]: '',
        [ownBusinessNIP.name]: '',
        [ownBusinessSettlementForm.name]: '',
        [pensionIndefinite.name]: '',
        [pensionToDate.name]: '',
        [preretireStartDate.name]: '',
        [employerName.name]: '',
        [employerNIP.name]: '',
        [employerNumber.name]: '',
        [nettoIncome.name]: '',
    }],
    [maritalStatus.name]: '',
    [education.name]: '',
    [housing.name]: '',
    [numberOfDependents.name]: '',
    [monthlyCosts.name]: '',
    [otherCharges.name]: '',
    [courtCharges.name]: '',
    [alimonyCharges.name]: '',
    [loanType.name]: '',
    [moneyPickup.name]: '',
    [bill.name]: '',
    [branch.name]: '',
    [visitDay.name]: '',
    [branchModal.name]: '',
    [selectAllCheckbox.name]: false,
    [zgody.name]: {},
    [biometricAgreement.name]: false,
    [powiazanieRadio.name]: '',
};

export default initialValues;
