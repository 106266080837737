import { Accordion, AccordionItem } from 'carbon-components-react';
import CheckboxField from '../formFields/CheckboxField';
import RadioGroupField from '../formFields/RadioGroupField';
import './AgreementCheckboxes.scss';
import { useField } from 'formik';
import { useEffect } from 'react';
import AgreementMarketingCheckboxes from './AgreementMarketingCheckboxes';

import { useAnalyticsEventTracker } from '../../../hooks/useAnalyticsEventTracker';

const AgreementCheckboxes = ({ formField, setFieldValue }) => {
    const [fieldSelectAll] = useField({ name: formField.selectAllCheckbox.name });
    useAnalyticsEventTracker('Zgody')

    useEffect(() => {
        if (fieldSelectAll.value) {
            Object.keys(formField.zgody).forEach(id => {
                if (formField.zgody[id].required) setFieldValue(`zgody.${formField.zgody[id].name}`, true);
            })
        } else {
            Object.keys(formField.zgody).forEach(id => {
                if (formField.zgody[id].required) setFieldValue(`zgody.${formField.zgody[id].name}`, false);
            })
        };
    }, [fieldSelectAll.value]);

    return (
        <div style={styles.root}>
            <p style={styles.textBold}>Po wyrażeniu zgód i naciśnięciu 'Prześlij dane' zaprezentujemy spersonalizowaną ofertę.</p>
            <p style={styles.infoText}>Wyrażenie poniższych zgód/złożenie oświadczeń jest dobrowolne, jednakże bez podania danych zawartych w formularzu i wyrażenia zgód/złożenia oświadczeń oznaczonych gwiazdką (pola wymagane) nie będzie możliwe zrealizowanie usługi, którą jest Pani/Pan zainteresowana/-y i przedstawienie Pani/Panu oferty kredytowej.</p>
            <CheckboxField 
                id={formField.selectAllCheckbox.name}
                name={formField.selectAllCheckbox.name}
                labelText={formField.selectAllCheckbox.label}
            />
            {
                Object.keys(formField.zgody).map(id => (
                    formField.zgody[id].required 
                    ? <CheckboxField 
                        id={`zgody.${formField.zgody[id].name}`}
                        name={`zgody.${formField.zgody[id].name}`}
                        labelText={formField.zgody[id].label}/> 
                    : <></>
                ))
            }
            <RadioGroupField
                id={formField.powiazanieRadio.name}
                name={formField.powiazanieRadio.name}
                data={[
                    { label: 'Nie jestem', value: 'nie' },
                    { label: 'Jestem', value: 'tak' },
                ]}
            />
            <p style={styles.text}>osobą zajmującą eksponowane stanowisko polityczne lub członkiem rodziny tej osoby lub osobą znaną jako jej bliski współpracownik. Jestem świadoma/świadomy odpowiedzialności karnej za złożenie fałszywego oświadczenia.*</p>
            <Accordion className="show-more-accordion">
                <AccordionItem title="Przeczytaj więcej">
                    <p style={styles.detailsText}>Przez osoby zajmujące eksponowane stanowiska polityczne rozumie się osoby fizyczne zajmujące znaczące stanowiska lub pełniące znaczące funkcje publiczne, w tym:</p>
                    <ol type="a" style={styles.ol}>
                        <li>szefów państw, szefów rządów, ministrów, wiceministrów, sekretarzy stanu, podsekretarzy stanu, w tym Prezydenta Rzeczypospolitej Polskiej, Prezesa Rady Ministrów i wiceprezesa Rady Ministrów,</li>
                        <li>członków parlamentu lub podobnych organów ustawodawczych, w tym posłów i senatorów,</li>
                        <li>członków organów zarządzających partii politycznych,</li>
                        <li>członków sądów najwyższych, trybunałów konstytucyjnych oraz innych organów sądowych wysokiego szczebla, których decyzje nie podlegają zaskarżeniu, z wyjątkiem trybów nadzwyczajnych, w tym sędziów Sądu Najwyższego, Trybunału Konstytucyjnego, Naczelnego Sądu Administracyjnego, wojewódzkich sądów administracyjnych oraz sędziów sądów apelacyjnych,</li>
                        <li>członków trybunałów obrachunkowych lub zarządów banków centralnych, w tym Prezesa oraz członków Zarządu NBP,</li>
                        <li>ambasadorów, chargés d'affaires oraz wyższych oficerów sił zbrojnych,</li>
                        <li>członków organów administracyjnych, zarządczych lub nadzorczych przedsiębiorstw państwowych, w tym dyrektorów przedsiębiorstw państwowych oraz członków zarządów i rad nadzorczych spółek z udziałem Skarbu Państwa, w których ponad połowa akcji albo udziałów należy do Skarbu Państwa lub innych państwowych osób prawnych,</li>
                        <li>dyrektorów, zastępców dyrektorów oraz członków organów organizacji międzynarodowych lub osoby pełniące równoważne funkcje w tych organizacjach,</li>
                        <li>dyrektorów generalnych w urzędach naczelnych i centralnych organów państwowych, dyrektorów generalnych urzędów wojewódzkich oraz kierowników urzędów terenowych organów rządowej administracji specjalnej.</li>
                    </ol>
                    <p style={styles.detailsText}>Przez członka rodziny osoby zajmującej eksponowane stanowisko polityczne rozumie się:</p>
                    <ol type="a" style={styles.ol}>
                        <li>małżonka lub osobę pozostającą we wspólnym pożyciu z osobą zajmującą eksponowane stanowisko polityczne,</li>
                        <li>dziecko osoby zajmującej eksponowane stanowisko polityczne i jego małżonka lub osoby pozostającej we wspólnym pożyciu,</li>
                        <li>rodziców osoby zajmującej eksponowane stanowisko polityczne.</li>
                    </ol>
                    <p style={styles.detailsText}>Przez osoby znane jako bliscy współpracownicy osoby zajmującej eksponowane stanowisko polityczne rozumie się:</p>
                    <ol type="a" style={styles.ol}>
                        <li>osoby fizyczne będące beneficjentami rzeczywistymi osób prawnych, jednostek organizacyjnych nieposiadających osobowości prawnej lub trustów wspólnie z osobą zajmującą eksponowane stanowisko polityczne lub utrzymujące z taką osobą inne bliskie stosunki związane z prowadzoną działalnością gospodarczą,</li>
                        <li>osoby fizyczne będące jedynym beneficjentem rzeczywistym osób prawnych, jednostek organizacyjnych nieposiadających osobowości prawnej lub trustu, o których wiadomo, że zostały utworzone w celu uzyskania faktycznej korzyści przez osobę zajmującą eksponowane stanowisko polityczne.</li>
                    </ol>
                </AccordionItem>
            </Accordion>
            <AgreementMarketingCheckboxes formField={formField} />
        </div>
    )
}

const styles = {
    root: {
        marginTop: 32,
        padding: 16,
    },
    text: {
        fontSize: '0.875rem',
        marginTop: '0.5rem'
    },
    infoText: {
        fontSize: '0.875rem',
        margin: '1.5rem 0 1rem 0',
    },
    textBold: {
        fontWeight: 600,
        marginBottom: '0.75rem',
        textAlign: 'center'
    },
    ol: {
        listStyle: 'lower-alpha',
        paddingLeft: 24,
        lineHeight: 1.43,
        letterSpacing: 0.16,
    },
    detailsText: {
        marginTop: 16,
        marginBottom: 8,
    }
}

export default AgreementCheckboxes;
