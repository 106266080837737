const promotedProducts = [
    {
        title: 'Linia pożyczkowa na niespodziewane wydatki do kwoty 500 zł',
        content: [
            'Może zostać uruchomiona już w momencie otwierania rachunku płatniczego.',
            'Nie wymaga stałych wpływów na rachunek. Wystarczą tylko wpłaty minimalne, których wysokość zależy od wykorzystanej kwoty w ramach przyznanego limitu.',
            'Dodatkowe środki są dostępne przez cały czas do dyspozycji. Dzięki temu nie trzeba rezygnować z atrakcyjnej wyprzedaży lub stresować się nagłym nieprzewidzianym wydatkiem.',
            'To wygoda i niezależność – potrzebną kwotę można wypłacić w dowolnym momencie. Nie wymagamy wypłaty maksymalnej kwoty przyznanego limitu.',
            'Każda kolejna spłata powiększa pulę dostępnych środków, z których można ponownie skorzystać.',
            'Odsetki naliczane są tylko za czas korzystania z linii, a ich wielkość zależy od kwoty wypłaconych środków.',
        ]
    },
    {
        title: 'Linia pożyczkowa w ROR lub IKS+ do wysokości jednomiesięcznego wpływu na rachunek',
        content: [
            'Dodatkowe środki, których wysokość zależy od stałego miesięcznego wpływu na rachunek płatniczy, dostępne do dyspozycji przez cały czas.',
            'To wygoda i niezależność – potrzebną kwotę można wypłacić w dowolnym momencie. Nie wymagamy wypłaty maksymalnej kwoty przyznanego limitu.',
            'Każda kolejna spłata powiększa pulę dostępnych środków, z których można ponownie skorzystać.',
            'Odsetki naliczane są tylko za czas korzystania z linii, a ich wielkość zależy od kwoty wypłaconych środków.',
        ]
    },
    {
        title: 'Linia pożyczkowa 6 x 12 (6 x średni wpływ na rachunek płatniczy)',
        content: [
            'Dodatkowe środki nawet do sześciokrotności stałego wpływu na rachunek płatniczy, dostępne do dyspozycji przez cały czas.',
            'To wygoda i niezależność – potrzebną kwotę można wypłacić w dowolnym momencie. Nie wymagamy wypłaty maksymalnej kwoty przyznanego limitu.',
            'Każda kolejna spłata powiększa pulę dostępnych środków, z których można ponownie skorzystać.',
            'Odsetki naliczane są tylko za czas korzystania z linii, a ich wielkość zależy od kwoty wypłaconych środków.',
        ]
    },
    {
        title: 'eSKOK i mSKOK',
        content: [
            'Stały dostęp do swoich środków bez względu na porę dnia.',
            'Wygoda i oszczędność czasu – płatności i przelewy można wykonać w dogodnym dla siebie momencie.',
            'Możliwość sprawdzania na bieżąco stanu konta oraz historii wykonywanych operacji.',
            'Dostęp do salda pożyczki oraz lokaty.',
            'Możliwość założenia lokaty terminowej w dowolnym momencie.'
        ]
    },
    {
        title: 'Karta płatnicza',
        content: [
            'Stały dostęp do swoich środków bez względu na porę dnia.',
            'Wygoda i bezpieczeństwo – nie trzeba nosić ze sobą gotówki.',
            'Możliwość płatności w Internecie bez konieczności logowania się do internetowego rachunku płatniczego.',
            'Usługa cashback – możliwość wypłaty gotówki do kwoty 300 zł przy okazji płatności za zakupy w sklepie, supermarkecie lub na stacji benzynowej.',
            'Możliwość uczestniczenia w atrakcyjnych akcjach promocyjnych organizowanych przez VISA.'
        ]
    },
    {
        title: 'Karta płatnicza EKO',
        content: [
            'Stały dostęp do swoich środków bez względu na porę dnia.',
            'Wygoda i bezpieczeństwo – nie trzeba nosić ze sobą gotówki.',
            'Możliwość płatności w Internecie bez konieczności logowania się do internetowego rachunku płatniczego.',
            'Usługa cashback – możliwość wypłaty gotówki do kwoty 300 zł przy okazji płatności za zakupy w sklepie, supermarkecie lub na stacji benzynowej.',
            'Możliwość uczestniczenia w atrakcyjnych akcjach promocyjnych organizowanych przez VISA.',
            'W przypadku transakcji za granicą lub w zagranicznych sklepach internetowych nie jest pobierana opłata za przewalutowanie.',
            'W każdym miesiącu dwie pierwsze wypłaty z bankomatów za granicą są darmowe.',
            'Karta wykonana jest z ekologicznych materiałów.',
        ]
    },
    {
        title: 'Google Pay',
        content: [
            'Stały dostęp do swoich środków bez względu na porę dnia.',
            'Wygoda i bezpieczeństwo – nie trzeba nosić ze sobą gotówki ani karty płatniczej. Wystarczy smartfon z dostępem do internetu.',
            'Dane karty płatniczej są zaszyfrowane.',
            'Prosta obsługa – wystarczy wybudzić telefon i przyłożyć do czytnika kart.',
            'Możliwość płatności w Internecie bez konieczności logowania się do internetowego rachunku płatniczego oraz podawania danych karty płatniczej.',
        ]
    },
    {
        title: 'Ubezpieczenia',
        content: [
            'ubezpieczenie na życie', 
            'składka wyliczana dla każdego indywidualnie,',
            'ochrona od dnia podpisania deklaracji bez okresu karencji,',
            'ubezpieczenie do pożyczek, kredytów i linii,',
            'możliwość wykupienia ubezpieczenia dla siebie i rodziny.'
        ]
    }
]

export default promotedProducts;