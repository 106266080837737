import { ComposedModal, Grid, ModalBody, ModalHeader, Button } from 'carbon-components-react';
import SendNumber from './SendNumber';
import { Colors } from '../../../utils/styleUtils/Colors';

const PromotedProductModal = props => {
    return (
        <ComposedModal size="lg" open={props.open} onClose={props.onClose} className="promoted-product-modal">
            <ModalHeader style={styles.header}>{props.product.title}</ModalHeader>
            <ModalBody>
                <Grid style={styles.grid}>
                    <ul style={styles.ul}>
                        {
                            props.product.content.map(elem => (
                                <li style={styles.li}>{elem}</li>
                            ))
                        }
                    </ul>
                </Grid>
                <SendNumber number={props.phone} link={props.link} setNumberSent={false}/>
                <Button style={styles.closeButton} onClick={props.onClose}>Zamknij</Button>
            </ModalBody>
        </ComposedModal>
    )
};

const styles = {
    grid: {
        textAlign: 'left',
        width: '100%',
    },
    header: {
        fontSize: 21,
        marginLeft: 12,
        marginRight: 12,
        borderBottom: Colors.borderBottomColor,
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        paddingBottom: 12,
    },
    ul: {
        listStyle: 'initial',
    },
    li: {
        fontSize: 14,
        marginBottom: 6,
    },
    closeButton: {
        backgroundColor: Colors.white,
        fontWeight: 500,
        fontSize: 18,
        color: Colors.green,
        textAlign: 'center',
        padding: 12,
        textDecoration: 'none',
        borderRadius: 4,
        borderWidth: 0,
    }
}

export default PromotedProductModal;
