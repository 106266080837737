import React, { useState, useEffect } from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import { Button, ComposedModal, Grid, Row, Column, ModalBody, ModalHeader, Select, SelectItem, PropTypes } from 'carbon-components-react';
import SelectField from '../formFields/SelectField';
import { Colors } from '../../../utils/styleUtils/Colors';
import Map from '../map/Map';
import BranchesList from '../branchesList/BranchesList';
import calcDistance from '../../../utils/calcDistance';
import LeftArrow from '../../../assets/left-arrow.svg';
import { businessDates } from '../../../utils/businessDaysCalculator';
import { getBranches } from '../../../utils/postGetMethods/getBranches';

const BranchModal = ({ formField, onUpdateBranch }) => {
    const initialBranchSelectProps = {
        id: formField.branch.name,
        name: formField.branch.name,
        labelText: formField.branch.label,
        data: [],
        placeholder: "Wybierz oddział i dzień odbioru"
    };
    const {data, placeholder, ...rest } = initialBranchSelectProps;
    const [field, meta, helpers] = useField(initialBranchSelectProps);
    const [fieldBranchModal, metaBranchModal, helpersBranchModal] = useField({ name: formField.branchModal.name });
    const { value: selectedValue } = field;
    const [touched, error] = at(meta, 'touched', 'error');
    const [open, setOpen] = useState(false);
    const isError = touched && error && true;
    const [branches, setBranches] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState(undefined);

    useEffect(() => {
        getBranches(false)
            .then(branches => {
                setBranches(branches);
            })
    }, []);

    useEffect(() => {
        setSelectedBranch(branches.filter(obj => parseInt(fieldBranchModal.value) === parseInt(obj.LP))[0]);
    }, [fieldBranchModal.value]);

    const handleFindClosestBranch = () => {
        let minDistance = 1000;
        let minDistanceBranch;
        let currentDistance;
        navigator.geolocation.getCurrentPosition(function(position) {
            for (let i=0; i<branches.length; i++) {
                currentDistance = calcDistance(branches[i].LAT, branches[i].LNG, position.coords.latitude, position.coords.longitude);
                if (currentDistance < minDistance) {
                    minDistance = currentDistance;
                    minDistanceBranch = branches[i];
                }
            }
            handleSelectBranch(minDistanceBranch);
        });
    };

    const handleSelectBranch = branch => {
        onUpdateBranch(branch);
        setSelectedBranch(branch);
        helpersBranchModal.setValue(branch.LP);
    };

    const handleConfirmBranch = () => {
        if (typeof selectedBranch !== "undefined"){
            onUpdateBranch(selectedBranch);
            helpers.setValue(fieldBranchModal.value);
            if (fieldBranchModal.value) setOpen(false);
        }
    };

    return (
            <div>
                { open && (
                    <ComposedModal size="lg" open={open} onClose={() => setOpen(false)}>
                        <ModalHeader style={styles.header} id="modal-header">Wybierz oddział</ModalHeader>
                        <ModalBody style={styles.modalBody}>
                            <Grid>
                                <Row>
                                    <Column sm={4} md={3} lg={4} style={styles.col}>
                                        <SelectField 
                                            id={formField.visitDay.name}
                                            name={formField.visitDay.name}
                                            labelText={formField.visitDay.label}
                                            data={businessDates.slice(1, 4).map(elem => ({ label: elem, value: elem }))}
                                            style={styles.select}
                                        />
                                    </Column>
                                    <Column sm={4} md={3} lg={4} style={styles.col}>
                                        <SelectField 
                                            id={formField.branchModal.name}
                                            name={formField.branchModal.name}
                                            labelText={formField.branchModal.label}
                                            data={branches.map(branch => ({ label: `${branch.MIEJSCOWOSC}, ${branch.ULICA}`, value: branch.LP }))}
                                            style={styles.select}
                                        />
                                    </Column>
                                    <Column sm={4} md={3} lg={4} style={styles.colButton}>
                                        <Button style={styles.button} onClick={handleFindClosestBranch}>Znajdź najbliższy oddział</Button>
                                    </Column>
                                </Row>
                                <Row style={styles.row}>
                                    <Column sm={4} md={5} style={styles.mapContainer}>
                                        <Map branches={branches} selected={selectedBranch} onBranchSelect={handleSelectBranch} />
                                    </Column>
                                    <Column sm={4} md={3}>
                                        <BranchesList branches={branches} selected={selectedBranch} onBranchSelect={handleSelectBranch} />
                                    </Column>
                                </Row>
                                <Row style={styles.buttonRow}>
                                    <Button 
                                        style={{...styles.buttonCommon, ...styles.buttonPrev}} 
                                        onClick={() => setOpen(false)}
                                    >
                                        <img src={LeftArrow} alt="" style={styles.icon}/>
                                        Wstecz
                                    </Button>
                                    <Button 
                                        style={{...styles.buttonCommon, ...styles.buttonNext}} 
                                        type="submit"
                                        onClick={handleConfirmBranch}
                                    >
                                        Zatwierdź
                                    </Button>
                                </Row>
                            </Grid>
                        </ModalBody>
                    </ComposedModal>
                ) }
                <div onClick={() => setOpen(true)} style={styles.selectWrapper}>
                    <Select 
                        {...field} 
                        {...rest}
                        value={selectedValue ? selectedValue : ''}
                        invalid={isError}
                        invalidText={isError && error}
                        style={styles.blockedSelect}
                    >
                        <SelectItem hidden value="placeholder" text={placeholder ? placeholder : "Wybierz..."} />
                            {branches
                                .map(branch => ({ label: `${branch.MIEJSCOWOSC}, ${branch.ULICA}`, value: branch.LP }))
                                .map((item, index) => (
                                    <SelectItem key={index} value={item.value} text={item.label} />
                                ))
                            }
                    </Select>
                </div>
            </div>
    );
}

const styles = {
    header: {
        fontWeight: 'bold',
        fontSize: 21,
        textAlign: 'center',
        paddingRight: 0,
        paddingTop: 20,
        paddingBottom: 28,
    },
    modalBody: {
        paddingLeft: 32,
        paddingRight: 32,
        marginBottom: 0,
        paddingBottom: 24,
    },
    blockedSelect: {
        pointerEvents: 'none',
    },
    select: {
        backgroundColor: Colors.lightGreyBranchSelect,
    },
    selectWrapper: {
        cursor: 'pointer',
    },
    button: {
        background: Colors.white,
        border: `1px solid ${Colors.mystic}`,
        borderRadius: 4,
        color: Colors.lightGreen,
        fontSize: 15,
        fontWeight: 600,
        paddingLeft: 16,
        paddingRight: 16,
        display: 'flex',
        alignSelf: 'flex-end',
        width: '100%',
        justifyContent: 'center',
        textAlign: 'center',
    },
    flex: {
        display: 'flex',
    },
    row: {
        paddingTop: 20,
    },
    col: {
        paddingTop: 10,
        paddingBottom: 8,
    },
    colButton: {
        paddingTop: 30,
    },
    mapContainer: {
        position: 'relative', 
        height: '50vh',
        minHeight: 350,
    },
    buttonRow: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 36,
    },
    buttonCommon: {
        padding: 13,
        paddingLeft: 24,
        paddingRight: 24,
        marginLeft: 20,
        marginRight: 20,
        borderWidth: 0,
        textAlign: 'center',
        fontSize: 18,
    },
    buttonNext: {
        backgroundColor: Colors.orange,
        borderRadius: 4,
        fontWeight: 600,
        textDecoration: 'none',
        color: Colors.white,
    },
    buttonPrev: {
        backgroundColor: Colors.white,
        color: Colors.green,
        fontWeight: 500,
    },
    icon: {
        paddingRight: 12,
    },
}

export default BranchModal;
