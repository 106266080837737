import { Button, InlineLoading } from 'carbon-components-react';
import { useState, useRef, useEffect } from 'react';
import { Colors } from '../../utils/styleUtils/Colors';
import { SendingStatus } from '../applicationPage/forms/PhoneVerificationModal';
import PhoneInput from '../applicationPage/forms/PhoneInput';
import { postSendSmsLink } from '../../utils/postGetMethods/postSendSmsLink';

const SendVerificationBySms = () => {
    const [phone, setPhone] = useState('');
    const [phoneValid, setPhoneValid] = useState(false);
    const [sending, setSending] = useState(false);
    const [sendingStatus, setSendingStatus] = useState(SendingStatus.NOT_SEND);
    const [disabled, setDisabled] = useState(false);
    const [secondsLeft, setSecondsLeft] = useState(60);
    const intervalRef = useRef();

    useEffect(() => {
        if (sendingStatus === SendingStatus.SEND_SUCCESS) {
            setDisabled(true);
            const countdownInterval = setInterval(() => {
                setSecondsLeft(prevSeconds => {
                    if (prevSeconds > 0) return prevSeconds - 1;
                    else {
                        setDisabled(false);
                        clearInterval(countdownInterval);
                        return 60;
                    }
                })
            }, 1000)
            intervalRef.current = countdownInterval;
        }
    }, [sendingStatus]);

    function sendSms() {
        const link = `${window.location.protocol}//${window.location.hostname}/weryfikacja?type=sms&number=${phone.replace(/-|_|\s/g, "")}#${window.location.hash.substring(1)}`;
        setSending(false);
        postSendSmsLink(
            `48${phone.replace(/-|_|\s/g, "")}`, 
            link
        )
        .then(output => {
            if (output.status === 'OK') {
                setSending(false);
                setSendingStatus(SendingStatus.SEND_SUCCESS);
                console.log('Response: ', output);
            } else if (output.status === 'DEV') {
                setSending(false);
                setSendingStatus(SendingStatus.SEND_SUCCESS);
                console.log('DEV: ', link)
            } else {
                setSending(false);
                setSendingStatus(SendingStatus.SEND_ERROR);
                console.log('Error while sending SMS: ', output);
            }
        })
        .catch(error => {
            setSending(false);
            setSendingStatus(SendingStatus.SEND_ERROR);
            console.log('Error while sending data to SMS: ', error)
        })
    }

    return (
        <div style={styles.providePhoneDiv}>
            <PhoneInput value={phone} onChange={setPhone} onValidation={setPhoneValid} />
            {
                sending 
                ? <InlineLoading style={styles.sending} description="Wysyłam..." /> 
                : <Button style={styles.sendButton} onClick={sendSms} disabled={disabled || !phoneValid}>Wyślij</Button>
            }
            { sendingStatus === SendingStatus.SEND_SUCCESS && (
                <div>
                    { 
                        disabled 
                        ? <p style={styles.smallText}>{`Odnośnik został wysłany na Twój telefon! Jeśli nie otrzymałeś/aś kodu, za ${secondsLeft.toString()} s. będziesz mógł ponowić próbę.`}</p>
                        : <p style={styles.smallText}>Odnośnik został wysłany na Twój telefon! Jeśli nie otrzymałeś/aś kodu, ponów próbę.</p>
                    }
                </div>
            )}
        </div>
    )
};

const styles = {
    providePhoneDiv: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 16,
        width: 260,
    },
    sendButton: {
        background: Colors.white,
        border: `2px solid ${Colors.mystic}`,
        borderRadius: 6,
        color: Colors.lightGreen,
        fontSize: 16,
        fontWeight: 500,
        paddingLeft: 16,
        paddingRight: 16,
        width: '100%',
        justifyContent: 'center',
        minHeight: 0,
        height: 40,
        marginTop: 12,
        marginBottom: 12,
    },
    smallText: {
        fontSize: 10,
        textAlign: 'center',
        width: '100%',
    }
}

export default SendVerificationBySms;
