import * as Yup from 'yup';
import formModel from './model';
import peselDecode from '../../../utils/decodePesel';
import { validateIdNumber } from '../../../utils/decodeIdNumber';

const {
    formField: {
        firstName,
        lastName,
        pesel,
        nationality,
        documentType,
        idNumber,
        issueDate,
        expirationDate,
        indefinite,
        member,
        street,
        houseNumber,
        aptNumber,
        postcode,
        city,
        phoneType,
        phone,
        email,
        confirmEmail,
        incomeSources,
        incomeSource,
        nettoIncome,
        uopIndefinite,
        uopToDate,
        ownBusinessFrom,
        ownBusinessIndustry,
        ownBusinessNIP,
        ownBusinessSettlementForm,
        pensionIndefinite,
        pensionToDate,
        preretireStartDate,
        employerName,
        employerNIP,
        employerNumber,
        maritalStatus,
        housing,
        numberOfDependents,
        monthlyCosts,
        otherCharges,
        courtCharges,
        alimonyCharges,
        loanType,
        moneyPickup,
        bill,
        branch,
        branchModal,
        visitDay,
        powiazanieRadio,
    }
} = formModel;

const idRegex = /^([A-Za-z]{3}.*\d{5}|[A-Za-z]{2}.*\d{7})$/;
const dateRegex = /^(?:(?:31(\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
const postcodeRegex = /^[0-9]{2}-[0-9]{3}$/;

const isFutureDate = dateStr => {
    if (!dateStr) return true;
    try {
        const splitted = dateStr.split('.');
        const date = new Date(parseInt(splitted[2]), parseInt(splitted[1]) - 1, parseInt(splitted[0]));
        const today = new Date();
        return date > today;
    } catch {
        return false;
    }
};

const isPastDate = dateStr => {
    if (!dateStr) return true;
    try {
        const splitted = dateStr.split('.');
        const date = new Date(parseInt(splitted[2]), parseInt(splitted[1]) - 1, parseInt(splitted[0]));
        const today = new Date();
        return date < today;
    } catch {
        return false;
    }
};

const isTenYears = (expiration, issue) => {
    if (!expiration || !issue) return true;
    try {
        const splittedExpiration = expiration.split('.');
        const dateExpiration = new Date(parseInt(splittedExpiration[2] - 10) , parseInt(splittedExpiration[1]) - 1, parseInt(splittedExpiration[0]));
        const splittedIssue = issue.split('.');
        const dateIssue = new Date(parseInt(splittedIssue[2]), parseInt(splittedIssue[1]) - 1, parseInt(splittedIssue[0]));
        return dateExpiration.getTime() === dateIssue.getTime();
    } catch {
        return false;
    }
}

const createValidation = (zgody = {}) => {
    const zgodyValidation = {};
    Object.keys(zgody).forEach(id => {
        if (zgody[id].required) zgodyValidation[id] = Yup.bool().oneOf([true], zgody[id].requiredErrorMsg);
    });

    return [
        Yup.object().shape({
            [firstName.name]: Yup.string().required(firstName.requiredErrorMsg),
            [lastName.name]: Yup.string().required(lastName.requiredErrorMsg),
            [pesel.name]: Yup.string()
                .length(11, 'PESEL musi składać się z 11 cyfr')
                .required(pesel.requiredErrorMsg)
                .test(
                    'exists',
                    pesel.invalidErrorMsg,
                    val => peselDecode(val).valid
                ),
            [nationality.name]: Yup.string().required(nationality.requiredErrorMsg),
            [documentType.name]: Yup.string().required(documentType.requiredErrorMsg),
            [idNumber.name]: Yup.string()
                .required(idNumber.requiredErrorMsg)
                .matches(idRegex, idNumber.invalidErrorMsg)
                .test(
                    'isIdNumberCorrect',
                    idNumber.checksumErrorMsg,
                    function (val) {
                        if (this.parent.documentType == 'dowód osobisty'){
                            return validateIdNumber(val);
                        } else return true;
                    }
                ),
            [indefinite.name]: Yup.boolean(),
            [issueDate.name]: Yup.string().required(issueDate.requiredErrorMsg)
                .matches(dateRegex, issueDate.invalidErrorMsg)
                .test(
                    'isPastDate',
                    'Proszę podać datę z przeszłości',
                    function (val) {
                        return isPastDate(val);
                    }
                ),
            [expirationDate.name]: Yup.string()
                .when([indefinite.name], {
                    is: false,
                    then: Yup.string().required(expirationDate.requiredErrorMsg)
                })
                .matches(dateRegex, expirationDate.invalidErrorMsg)
                .test(
                    'isFutureDate',
                    'Twój dokument stracił ważność',
                    function (val) {
                        if (this.parent.indefinite) return true;
                        else return isFutureDate(val)
                    }
                )
                .test(
                    'isTenYears',
                    'Różnica między datą wygaśnięcia, a datą wydania powinna wynosić dokładnie dziesięć lat.',
                    function (val) {
                        return isTenYears(val, this.parent.issueDate);
                    }
                ),
            [member.name]: Yup.string().required(member.requiredErrorMsg),
            [street.name]: Yup.string().required(street.requiredErrorMsg),
            [houseNumber.name]: Yup.string().required(houseNumber.requiredErrorMsg),
            [aptNumber.name]: Yup.string(),
            [postcode.name]: Yup.string().required(postcode.requiredErrorMsg).matches(postcodeRegex, postcode.invalidErrorMsg),
            [city.name]: Yup.string().required(city.requiredErrorMsg),
            [phone.name]: Yup.string()
                .required(phone.requiredErrorMsg)
                .test("len", "Niepoprawny numer telefonu", val => {
                    if (val) {
                        const valLengthWithoutDashes = val.replace(/-|_|\s/g, "").length;
                        return valLengthWithoutDashes === 9;
                    }
                  })
                .test(
                    'verified',
                    phone.verifiedErrorMsg,
                    function () {
                        return this.parent.phoneVerified;
                    }
                ),
            [email.name]: Yup.string().email(email.invalidErrorMsg).required(email.requiredErrorMsg),
            [confirmEmail.name]: Yup.string()
                .email(confirmEmail.invalidErrorMsg)
                .required(confirmEmail.requiredErrorMsg)
                .test(
                    'match',
                    confirmEmail.notMatchingErrorMsg,
                    function (val) {
                        return (val || '').toLocaleLowerCase() === (this.parent.email || '').toLocaleLowerCase();
                    }
                ),
        }),
        Yup.object().shape({
            [incomeSources.name]: Yup.array()
                .of(Yup.object().shape({
                    [incomeSource.name]: Yup.string().required(incomeSource.requiredErrorMsg),
                    [nettoIncome.name]: Yup.number()
                        .required(nettoIncome.requiredErrorMsg)
                        .positive('Wartość nie może być ujemna')
                        .moreThan(99, 'Wartość nie może być mniejsza niż 100 PLN.'),
                    [uopToDate.name]: Yup.string()
                        .test(
                            'uop date is required',
                            uopToDate.requiredErrorMsg,
                            function (value) {
                                if (this.parent.incomeSource === 'umowa o pracę') {
                                    if (this.parent.uopIndefinite === 'nie') return !!value;
                                    else return true;
                                } else return true;
                            }
                        )
                        .matches(dateRegex, uopToDate.invalidErrorMsg)
                        .test(
                            'isFutureDate',
                            'Twoja umowa dobiegła końca',
                            function (val) {
                                if (this.parent.incomeSource === 'umowa o pracę') {
                                    if (this.parent.uopIndefinite === 'nie') return isFutureDate(val);
                                };
                                return true;
                            }
                        ),
                    [uopIndefinite.name]: Yup.string()
                        .when([incomeSource.name], {
                            is: 'umowa o pracę',
                            then: Yup.string().required(uopIndefinite.requiredErrorMsg)
                        }),
                    [employerName.name]: Yup.string()
                        .test(
                            'employer name is required',
                            employerName.requiredErrorMsg,
                            function (value) {
                                if (['umowa o pracę', 'umowa zlecenia/dzieło'].includes(this.parent.incomeSource)) return !!value;
                                else return true;
                            }
                        ),
                    [employerNIP.name]: Yup.string()
                        .test(
                            'employer NIP is required',
                            employerNIP.requiredErrorMsg,
                            function (value) {
                                if (['umowa o pracę', 'umowa zlecenia/dzieło'].includes(this.parent.incomeSource)) return !!value;
                                else return true;
                            }
                        )
                        .test(
                            'NIP should contain 10 digits',
                            'NIP powinien składać się z 10 cyfr',
                            function (value) {
                                if (['umowa o pracę', 'umowa zlecenia/dzieło'].includes(this.parent.incomeSource)) return parseInt(value) && value.length === 10;
                                else return true;
                            }
                        ),
                    [employerNumber.name]: Yup.string()
                        .test("len", "Niepoprawny numer telefonu", 
                            function(val){
                                if ((['umowa o pracę', 'umowa zlecenia/dzieło'].includes(this.parent.incomeSource)) && val) {
                                        const valLengthWithoutDashes = val.replace(/-|_|\s/g, "").length;
                                        return valLengthWithoutDashes === 9;
                                } else return true;
                            })    
                    .test(
                            'employer name is required',
                            employerNumber.requiredErrorMsg,
                            function (value) {
                                if (['umowa o pracę', 'umowa zlecenia/dzieło'].includes(this.parent.incomeSource)) return !!value;
                                else return true;
                            }
                        ),
                    [ownBusinessFrom.name]: Yup.string()
                        .when([incomeSource.name], {
                            is: 'własna działalność gospodarcza',
                            then: Yup.string().required(ownBusinessFrom.requiredErrorMsg)
                        })
                        .matches(dateRegex, ownBusinessFrom.invalidErrorMsg)
                        .test(
                            'isPastDate',
                            'Podaj przeszłą datę',
                            function (val) {
                                return this.parent.incomeSource === 'własna działalność gospodarcza' ? isPastDate(val) : true
                            } 
                        ),
                    [ownBusinessIndustry.name]: Yup.string(),
                    [ownBusinessNIP.name]: Yup.string()
                        .when([incomeSource.name], {
                            is: 'własna działalność gospodarcza',
                            then: Yup.string()
                                .required(ownBusinessNIP.requiredErrorMsg)
                                .test(
                                    'NIP should contain 10 digits',
                                    'NIP powinien składać się z 10 cyfr',
                                    function (value) {
                                        return parseInt(value) && value.length === 10;
                                    }
                                )
                        }),
                    [ownBusinessSettlementForm.name]: Yup.string()
                        .when([incomeSource.name], {
                            is: 'własna działalność gospodarcza',
                            then: Yup.string().required(ownBusinessSettlementForm.requiredErrorMsg)
                        }),
                    [pensionIndefinite.name]: Yup.string()
                        .when([incomeSource.name], {
                            is: 'renta',
                            then: Yup.string().required(pensionIndefinite.requiredErrorMsg)
                        }),
                    [pensionToDate.name]: Yup.string()
                        .test(
                            'pension date is required',
                            pensionToDate.requiredErrorMsg,
                            function (value) {
                                if (this.parent.incomeSource === 'renta') {
                                    if (this.parent.pensionIndefinite === 'nie') return !!value;
                                    else return true;
                                } else return true;
                            }
                        )
                        .matches(dateRegex, pensionToDate.invalidErrorMsg)
                        .test(
                            'isFutureDate',
                            'Twoje świadczenie dobiegło końca',
                            function (val) {
                                if (this.parent.incomeSource === 'renta') {
                                    if (this.parent.pensionIndefinite === 'nie') return isFutureDate(val);
                                };
                                return true;
                            }
                        ),
                    [preretireStartDate.name]: Yup.string()
                        .when([incomeSource.name], {
                            is: 'świadczenie przedemerytalne',
                            then: Yup.string().required(preretireStartDate.requiredErrorMsg).matches(dateRegex, preretireStartDate.invalidErrorMsg)
                        }),
                })),
            [maritalStatus.name]: Yup.string().required(maritalStatus.requiredErrorMsg),
            [housing.name]: Yup.string().required(housing.requiredErrorMsg),
            [numberOfDependents.name]: Yup
                                        .number()
                                        .required(numberOfDependents.requiredErrorMsg)
                                        .positive('Wartość nie może być ujemna')
                                        .moreThan(-1),
            [monthlyCosts.name]: Yup.number().required(monthlyCosts.requiredErrorMsg).positive('Wartość nie może być ujemna'),
            [otherCharges.name]: Yup.string().required(otherCharges.requiredErrorMsg),
            [courtCharges.name]: Yup.number()
            .when([otherCharges.name], {
                is: 'tak',
                then: Yup.number().required(courtCharges.requiredErrorMsg).positive('Wartość nie może być ujemna')
            }),
            [alimonyCharges.name]: Yup.number()
            .when([otherCharges.name], {
                is: 'tak',
                then: Yup.number().required(alimonyCharges.requiredErrorMsg).positive('Wartość nie może być ujemna')
            }),
            [loanType.name]: Yup.string().required(loanType.requiredErrorMsg),
        }),
        Yup.object().shape({ 
            'zgody': Yup.object(zgodyValidation),
            [powiazanieRadio.name]: Yup.string().required(powiazanieRadio.requiredErrorMsg),
        }),
        Yup.object().shape({
            [moneyPickup.name]: Yup.string().required(moneyPickup.requiredErrorMsg),
            [branch.name]: Yup.string()
                .when([moneyPickup.name], {
                    is: 'office',
                    then: Yup.string()
                        .required(branch.requiredErrorMsg)
                        .test(
                            'haveVisitDay',
                            branch.requiredErrorMsg,
                            function(val) {
                                if (typeof this.parent.visitDay === "undefined") return false;
                                else return true;
                            }
                        )
                }),
            [branchModal.name]: Yup.string()
                .when([moneyPickup.name], {
                    is: 'office',
                    then: Yup.string().required(branchModal.requiredErrorMsg)
                }),
            [visitDay.name]: Yup.string()
                .when([moneyPickup.name], {
                    is: 'office',
                    then: Yup.string().required(visitDay.requiredErrorMsg)
                }),
            [bill.name]: Yup.string()
                .when([moneyPickup.name], {
                    is: 'transfer',
                    then: Yup.string()
                        .required(bill.requiredErrorMsg)
                        .test(
                            'haveBill',
                            'Zaznacz powyżej "Zawarcie umowy i wypłata pożyczki w oddziale"',
                            function(val) {
                                if (val === 'no') return false;
                                else return true; 
                            }
                        )
                })
        })
    ]
}

export default createValidation;
