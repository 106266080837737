import { Colors } from "../../../utils/styleUtils/Colors";
import { Dimension } from "../../../utils/styleUtils/Dimension";
import { Slider } from "carbon-components-react";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const CalcSingleSlider = ({
    header,
    minValue,
    maxValue,
    maxMaxValue,
    step,
    value,
    setValue,
    handleBlur, 
    stopSlider, 
    unit
}) => {
    const { width } = useWindowDimensions();
    return (
        <div style={styles.rootNormal}>
            <p style={styles.textNormal}> {header} </p>
            {
                stopSlider ?
                    <>
                        <p style={styles.textAboveMax}>- {unit}</p>
                        <hr style={styles.hr} />
                    </>
                    :
                    <>
                        <div style={styles.inline}>
                            <input
                                type="number"
                                value={value.toString()}
                                min={minValue}
                                onBlur={() => handleBlur()}
                                style={
                                    width > Dimension.sm ?
                                        {
                                            ...styles.textInput,
                                            width: value.toString().length * 32 + 18 + 'px'
                                        }
                                        :
                                        {
                                            ...styles.textInput,
                                            fontSize: 36,
                                            width: value.toString().length * 23 + 18 + 'px'
                                        }
                                }
                                onChange={e => setValue(prevState => {
                                    const val = e.target.value.replace(/^0+/, '').replace(/\.|,/g, '');
                                    return parseInt(val) > maxMaxValue ? prevState : parseInt(Math.abs(val));
                                })}
                            />
                            <p style={styles.textMedium}>{unit}</p>
                        </div>
                        <Slider
                            min={minValue}
                            max={maxValue}
                            step={step}
                            value={parseInt(value)}
                            hideTextInput
                            formatLabel={() => <></>}
                            style={styles.slider}
                            onChange={e => setValue(e.value)}
                            onRelease={() => handleBlur()}
                        />
                    </>
            }
        </div>
    )
}

export default CalcSingleSlider


const styles = {
    // Root
    rootNormal: {
        paddingTop: 16,
        paddingBottom: 16,
    },
    rootSm: {
        paddingTop: 16,
        paddingBottom: 0,
        paddingLeft: 24,
        paddingRight: 24,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column', 
    },
    // Text 
    textNormal: {
        fontWeight: 500,
        fontSize: 18,
        color: Colors.black,
        opacity: 0.8,
        whiteSpace: 'nowrap'
    },
    textInput: {
        fontWeight: 500,
        fontSize: 48,
        color: Colors.black,
        lineHeight: 0.8,
        borderRadius: '10px',
        border: `1px solid ${Colors.chineseWhite}` 
    },
    textAboveMax: {
        whiteSpace: 'nowrap',
        fontWeight: 500,
        fontSize: 26,
        color: Colors.black,
        lineHeight: 1.5,
        marginRight: 12, 
        marginLeft: 12,
        paddingBottom: 10,
    },
    textMedium: {
        fontWeight: 500,
        fontSize: 24,
        color: Colors.black,
        lineHeight: 1,
        paddingLeft: 8,
    },
    textSm: {
        fontWeight: 500,
        fontSize: 16,
        color: Colors.black,
        opacity: 0.8,
        paddingRight: 0,
        whiteSpace: 'nowrap'
    },
    // Other
    hr: {
        width: 235,
        height: 1,
        opacity: 0,
    },
    slider: {
        marginTop: 16,
        marginBottom: 32,
        marginLeft: 4,
    },
    inline: {
        display: 'flex',
        alignItems: 'flex-end',
        marginBottom: 8,
    },
}