import React from "react";
import { useSpring, animated } from 'react-spring'
import { useEffect, useState } from 'react';
import { Colors } from "../../../utils/styleUtils/Colors";
import { Grid, Row, Column } from "carbon-components-react";

const ProgressBar = ({ status }) => {
    const [progress, setProgress] = useState(0);
    const [description, setDescription] = useState('');

    const animationUpDown = useSpring({
        loop: true,
        to: [
            {rotateZ: 45},
            {y: 13},
            {rotateZ: 0},
            {rotateZ: -45},
            {y: -13},
            {rotateZ:0},
            {rotateZ: 45},
            {y: 0},
            {rotateZ: 0}
        ],
        from: { y: 0, rotateZ: 0},
        config: { duration: 1000 }
    })
    const [animationXRocket, animateXRocket] = useSpring(() => ({x: 0}))
    const [animationBarWidth, animateBarWidth] = useSpring(() => ({width: 0}))

    const calcProgress = (widthFiller) => Math.ceil((widthFiller / 15) * 100); 

    useEffect(() => {
        switch(status){
            case -40:
                setProgress(calcProgress(3));
                animateXRocket({ 
                    from: {x: '1rem' },
                    to: {x: '3rem'},
                    config: { duration: 1000 }
                })
                animateBarWidth({ 
                    from: {width: '0.2rem'},
                    to: {width: '2.2rem'},
                    config: { duration: 1000 }
                })
                setDescription('Przesyłam informacje do BIK...');
                break;
            case -35:
                setProgress(calcProgress(5));
                animateXRocket({ 
                    from: {x: '3rem' },
                    to: {x: '5rem' },
                    config: { duration: 1000 }
                })
                animateBarWidth({ 
                    from: {width: '2.2rem' },
                    to: {width: '4.2rem' },
                    config: { duration: 1000 }
                })
                setDescription('Odbieram informacje z BIK...');
                break;
            case -30:
                setProgress(calcProgress(9));
                animateXRocket({ 
                    from: {x: '5rem' },
                    to: {x: '9rem' },
                    config: { duration: 1000 }
                })
                animateBarWidth({ 
                    from: {width: '4.2rem' },
                    to: {width: '8.2rem' },
                    config: { duration: 1000 }
                })
                setDescription('Pobrano informacje z BIK');
                break;
            case -20:
                setProgress(calcProgress(11));
                animateXRocket({ 
                    from: {x: '9rem'},
                    to: {x: '11rem'},
                    config: { duration: 1000 }
                })
                animateBarWidth({ 
                    from: {width: '8.2rem'},
                    to: {width: '10.2rem'},
                    config: { duration: 1000 }
                })
                setDescription('Oceniam zdolność kredytową...');
                break;
            case -10:
                setProgress(calcProgress(13));
                animateXRocket({ 
                    from: {x: '11rem'},
                    to: {x: '13rem'},
                    config: { duration: 1000 }
                })
                animateBarWidth({ 
                    from: {width: '10.2rem'},
                    to: {width: '12.2rem'},
                    config: { duration: 1000 }
                })
                setDescription('Wyliczam wstępne warunki...');
                break;
            case -5:
                setProgress(100);
                animateXRocket({ 
                    from: {x: '13rem'},
                    to: {x: '15rem'},
                    config: { duration: 100 }
                })
                animateBarWidth({ 
                    from: {width: '12.2rem'},
                    to: {width: '15rem'},
                    config: { duration: 100 }
                })
                setDescription('Gotowe');
                break;
            default:
                setProgress(calcProgress(1));
                animateXRocket({ 
                    from: {x: '0rem'},
                    to: {x: '1rem'},
                    config: { duration: 1000 }
                })
                animateBarWidth({ 
                    from: {width: '0rem'},
                    to: {width: '0.2rem'},
                    config: { duration: 1000 }
                })
                setDescription('Tworzę zapytanie...');
                break;     
            }
    })

    return (
        <div style={styles.box}>
            <Grid>
                <Column>
                    <Row style={{...styles.row, flexDirection: 'row', flexWrap: 'nowrap'}}>
                        <animated.img src={'svg/loadingRocket.svg'} style={{...styles.svg, ...animationUpDown, ...animationXRocket}} alt=''/>
                        <div style={styles.containerStyles}>
                            <animated.div style={{...styles.fillerStyles, ...animationBarWidth}}/>
                        </div>
                        <p style={{display: 'flex', color: Colors.green, }}>
                            {progress}%
                        </p>
                    </Row>
                    <Row style={styles.row}>
                        <p style={styles.paragraph}>{description}</p>
                    </Row>
                </Column>
            </Grid>
        </div>
    );
};

const styles = {
    box: {
        backgroundColor: Colors.loadingBackground,
        borderRadius: 25,
        width: '25rem',
        height: '7rem',
        justifyContent: 'center',
        textAlign: 'center',
        paddingTop: '2rem',
        paddingBottom: '2rem'
    },
    row: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    containerStyles: {
        height: '0.3rem',
        width: '15rem',
        borderRadius: 20,
        backgroundColor: Colors.progressBarContainer,
        marginRight: '1rem',
        display: 'flex'
    },
    fillerStyles: {
        height: '0.3rem',
        backgroundColor: Colors.green,
        borderRadius: 10,
    },
    svg: {
        height: '2rem',
        paddingTop: 0,
        backgroundColor: Colors.loadingBackground,
        borderRadius: '0px 50px 50px 0px'
    },
    paragraph: {
        marginTop: '1rem',
        display: 'flex',
    }
}

export default ProgressBar;
