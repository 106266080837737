const postSendSmsLink = (phone, link) => {
    return fetch('/api/offer/post-send-sms-link', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            phone: phone,
            message: `W celu weryfikacji tożsamości otwórz poniższy link: ${link}`,
        })
    })
    .then(res => res.json())
}

export { postSendSmsLink }