import { Grid, Row, Column } from 'carbon-components-react';
import { Colors } from '../../utils/styleUtils/Colors';
import LogoColor from '../../assets/skokchmielewskiego-logo-trojkatne.svg';
import ProtectionLogo from '../../assets/protection.svg';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Dimension } from '../../utils/styleUtils/Dimension';

const Header = ({activeLogoLink}) => {
    const { width } = useWindowDimensions();

    const renderLogo = active => {
        switch(active){
            case false:
                return (
                    <img src={LogoColor} alt="logo" style={width > 580 ? styles.logoBrowser : styles.logoMobile } />
                )
            default: 
                return (
                    <a href="/" style={{textDecoration: 'none'}}>
                        <img src={LogoColor} alt="logo" style={width > 580 ? styles.logoBrowser : styles.logoMobile } />
                    </a>
                )
        }
    }

    return (
        <Grid style={styles.root} fullWidth>
            <Row>
                <Column style={width > Dimension.sm ? styles.flex : {...styles.flex, ...styles.center}}>
                    <div style={styles.center}>
                        {renderLogo(activeLogoLink)}
                    </div>
                    <div style={width > Dimension.sm ? styles.verticalLine : {...styles.verticalLine, ...styles.verticalLineMobile}}></div>
                    <div style={styles.header}>
                        <p style={width > Dimension.sm ? {...styles.titleBrowser, color: Colors.black} : {...styles.titleMobile, color: Colors.black}}>Praktyczne</p>
                        <p style={width > Dimension.sm ? {...styles.titleBrowser, color: Colors.green} : {...styles.titleMobile, color: Colors.green}}>Pożyczki</p>
                    </div>
                </Column>
                { width > Dimension.md && (
                    <Column style={styles.end}>
                        <div style={styles.safetyInfo}>
                            <img src={ProtectionLogo} alt="protection" style={styles.safetyText}/>
                            <p style={{...styles.safetyText, ...styles.safetyTextBolded}}>Gwarancja bezpieczeństwa.</p>
                            <p style={styles.safetyText}>Wszystkie dane są szyfrowane.</p>
                        </div>
                    </Column>
                )}
            </Row>
        </Grid>
    )
}

const styles = {
    root: {
        marginLeft: 0,
        marginRight: 0,
    },
    titleBrowser: {
        fontWeight: 800,
        fontSize: 24,
        lineHeight: 1,
    },
    titleMobile: {
        fontWeight: 800,
        fontSize: 18,
        lineHeight: 1,
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
    },
    logoBrowser: {
        height: 104,
    },
    logoMobile: {
        height: 84,
    },
    header: {
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    verticalLine: {
        backgroundColor: Colors.black,
        opacity: 0.2,
        borderRadius: 2,
        height: '60%',
        width: 1.87,
        marginLeft: 20,
        marginRight: 36,
        justifyContent: 'center',
    },
    verticalLineMobile: {
        marginRight: 16,
        marginLeft: 0,
    },
    center: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
    end: {
        justifyContent: 'flex-end',
        alignItems: 'center',
        display: 'flex',
    },
    safetyInfo: {
        background: Colors.chineseWhite,
        borderRadius: 15,
        display: 'flex',
        padding: 8,
        paddingLeft: 16,
        paddingRight: 16,
    },
    safetyText: {
        paddingLeft: 8,
        paddingRight: 4,
        fontSize: 16,
    },
    safetyTextBolded: {
        fontWeight: 700,
    }
}

export default Header;
