import React, { useState } from 'react';
import { Column, Row, Button, Grid } from "carbon-components-react";
import QRCode from 'react-qr-code';
import { Colors } from '../../utils/styleUtils/Colors';
import AttachIcon from '../../assets/attach.svg';
import UploadPhotosModal from './UploadPhotosModal';
import SendVerificationBySms from './SendVerificationBySms';
import { BrowserView, MobileView } from 'react-device-detect';

import { useAnalyticsEventTracker } from '../../hooks/useAnalyticsEventTracker';

const QRorUploadPhotoPage = props => {
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [phoneFieldVisible, setPhoneFieldVisible] = useState(false);
    useAnalyticsEventTracker('Rejestracja ze zdjęcia')

    return (
        <div style={styles.container}>
            <BrowserView>
                <p style={styles.header}>Zeskanuj kod smartfonem</p>
                <p style={styles.normalText}>Zeskanuj poniższy kod QR za pomocą aparatu w smartfonie.</p>
                <p style={styles.normalText}>Dzięki czemu w łatwy i bezpieczny sposób zrobisz oraz załączysz zdjecie dowodu osobistego.</p>
                <div style={styles.box}>
                    <Grid>
                        <Row>
                            <Column sm={4} md={3} style={styles.column}>
                                <div style={styles.qrDiv}>
                                    <QRCode size={156} value={`${window.location.protocol}//${window.location.hostname}/weryfikacja?type=qr#${window.location.hash.substring(1)}`}/>
                                    <p style={styles.qrText}>Masz problem z QR kodem?</p>
                                    <Button style={styles.sendSmsButton} onClick={() => setPhoneFieldVisible(true)}>Wyślij odnośnik SMSem</Button>
                                    { phoneFieldVisible && <SendVerificationBySms /> }
                                </div>
                            </Column>
                            <Column sm={4} md={2} style={styles.column}>
                                <p>lub</p>
                            </Column>
                            <Column sm={4} md={3} style={styles.column}>
                                <Button style={styles.button} onClick={() => setUploadModalOpen(true)}>
                                    <img src={AttachIcon} alt="" style={styles.icon}/>
                                    Wgraj zdjęcie z komputera
                                </Button>
                                <UploadPhotosModal 
                                    open={uploadModalOpen} 
                                    onClose={() => setUploadModalOpen(false)} 
                                    goToForm={props.goToForm}  
                                    onReset={props.onReset}
                                    status={props.status}
                                    msg={props.msg}
                                />
                            </Column>
                        </Row>
                    </Grid>
                </div>
            </BrowserView>
            <MobileView>
                <div style={styles.mobileContainer}>
                    <p style={styles.normalText}>Klikając w poniższy przycisk w łatwy i bezpieczny sposób zrobisz i załączysz zdjęcia dowodu osobistego.</p>
                    <Button style={{...styles.button, marginTop: 16}} onClick={() => setUploadModalOpen(true)}>
                        <img src={AttachIcon} alt="" style={styles.icon}/>
                        Wgraj zdjęcia z telefonu
                    </Button>
                    <UploadPhotosModal 
                        open={uploadModalOpen} 
                        onClose={() => setUploadModalOpen(false)} 
                        goToForm={props.goToForm} 
                        onReset={props.onReset}  
                        msg={props.msg} 
                        status={props.status}
                    />
                </div>
            </MobileView>
        </div>
    )
}

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        flexDirection: 'column',
    },
    mobileContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: 28,
        marginBottom: 28,
    },
    header: {
        color: Colors.black,
        fontWeight: 700,
        fontSize: 21,
        marginBottom: 20,
        marginTop: 16,
        textAlign: 'center',
    },
    normalText: {
        color: Colors.black,
        fontSize: 16,
        textAlign: 'center',
    },
    qrText: {
        color: Colors.black,
        fontSize: 16,
        whiteSpace: 'nowrap',
        marginTop: 20,
    },
    box: {
        background: Colors.white,
        border: `1px solid ${Colors.borderColor}`,
        boxSizing: 'border-box',
        borderRadius: 20,
        marginTop: 24,
        paddingTop: 24,
        paddingBottom: 24,
        paddingRight: 48,
        paddingLeft: 48,
    },
    button: {
        background: Colors.white,
        border: `1px solid ${Colors.mystic}`,
        borderRadius: 4,
        color: Colors.lightGreen,
        fontSize: 15,
        fontWeight: 600,
        paddingLeft: 16,
        paddingRight: 16,
    },
    column: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        paddingRight: 24,
        paddingLeft: 24,
        paddingTop: 12,
        paddingBottom: 12,
    },
    icon: {
        paddingRight: 8,
    },
    qrDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    sendSmsButton: {
        background: 'none',
        color: Colors.lightGreen,
        border: 'none',
        padding: 0,
        minHeight: 'auto',
        fontSize: 16,
        marginTop: 4,
    }
}

export default QRorUploadPhotoPage;
