const getIncomes = incomeSources => {
    const getZrodloDochodu = income => {
        switch (income.incomeSource) {
            case 'umowa o pracę':
                return income.uopIndefinite === 'tak' ? 'umowa o pracę na czas nieokreślony' : 'umowa o pracę na czas określony';
            case 'umowa zlecenia/dzieło':
                return 'umowa zlecenia/dzieło';
            case 'własna działalność gospodarcza':
                return 'własna działalność gospodarcza';
            case 'emerytura':
                return 'emerytura';
            case 'renta':
                return income.pensionIndefinite === 'tak' ? 'renta czas nieokreślony' : 'renta czas określony';
            case 'gospodarstwo rolne':
                return 'gospodarstwo rolne';
            case 'świadczenie przedemerytalne':
                return 'świadczenie przedemerytalne';
            default:
                return '';
        }
    }

    return incomeSources.map(income => {
        let from = '';
        let to = '';
        let nip = '';

        if (income.incomeSource === 'własna działalność gospodarcza') from = income.ownBusinessFrom;
        else if (income.incomeSource === 'świadczenie przedemerytalne') from = income.preretireStartDate;

        if (income.incomeSource === 'umowa o pracę') to = income.uopToDate;
        else if (income.incomeSource === 'renta') to = income.pensionToDate;

        if (income.incomeSource === 'własna działalność gospodarcza') nip = income.ownBusinessNIP;    
        else nip = income.employerNIP;
        
        return {
            zrodloDochodu: getZrodloDochodu(income),
            nazwaZrodloDochodu: income.employerName,
            nipZrodloDochodu: nip,
            telefonZrodloDochodu: income.employerNumber,
            kwotaZrodloDochodu: income.nettoIncome.toString(),
            odKiedyZrodloDochodu: convertToDate(from),
            doKiedyZrodloDochodu: convertToDate(to),
            branzaZrodloDochodu: income.ownBusinessIndustry,
            formaProwadzeniaDzialalnosci: income.ownBusinessSettlementForm,
        }
    })
}

const convertToDate = (dateStr) => {
    if (dateStr) {
        const dateSplit = dateStr.split('.');
        return new Date(parseInt(dateSplit[2]), parseInt(dateSplit[1]) - 1, parseInt(dateSplit[0]));
    } else return null;
}

const prepareJson = (data, amount, installmentAmount, formField) => {
    return {
        DataWyslania: new Date(),
        Zgody: Object.keys(data.zgody)
                    .filter(key => data.zgody[key])
                    .map(id => Object.assign({}, {
                        zgodaid: parseInt(id),
                        tresc: formField.zgody[id].label
                    })),
        DaneWstepne: {
            czlonekSKOK: data.member === 'tak',
            kwota: amount.toString(),
            okres: parseInt(amount / installmentAmount).toString(),
            tyPozyczki: data.loanType,
            imie: data.firstName,
            nazwisko: data.lastName,
            rodzajTelefonu: "komórkowy",
            telefon: data.phone.replace(/-|_|\s/g, ""),
            mail: data.email,
            url: window.location.href,
            zgodanaprzetwzdjec: data.biometricAgreement,
        },
        DaneOsobowe: {
            pesel: data.pesel,
            obywatelstwo: data.nationality,
            dokumentTozsamosci: data.documentType,
            nrDokumentu: data.idNumber.replace(/\s/g,''),
            czyTerminowy: data.indefinite ? 'Bezterminowy' : 'Z terminem ważności',
            dataWydania: convertToDate(data.issueDate),
            terminDokumentu: convertToDate(data.expirationDate),
        },
        DaneAdresowe: {
            wojewodztwo: '',
            kodAdres: data.postcode,
            miejscowosc: data.city,
            ulica: data.street,
            nrDomu: data.houseNumber,
            nrMieszkania: data.aptNumber,
        },
        ZrodlaDochodu: getIncomes(data.incomeSources),
        DaneDodatkowe: {
            stanCywilny: data.maritalStatus,
            statusMieszkania: data.housing,
            liczbaOsob: data.numberOfDependents,
            kosztGospodarstwa: data.monthlyCosts.toString(),
            zobowiazaniaAlimenty: data.alimonyCharges ? data.alimonyCharges.toString() : '',
            zobowiazaniaSad: data.courtCharges ? data.courtCharges.toString() : '',
            wybranyOddzialWojewodztwo: '',
            wybranyOddzial: data.branch,
        }
    }
}

export default prepareJson;
