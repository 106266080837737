import { useEffect, useState } from 'react';
import { Button, Column, Row } from 'carbon-components-react'
import { Colors } from '../../utils/styleUtils/Colors';
import FormIcon from '../../assets/form-icon.svg';
import IdIcon from '../../assets/id-icon.svg';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Dimension } from '../../utils/styleUtils/Dimension';

const SelectAuthenticationMethod = ({selectedAuthMethod, handleMethodChange, handleSubmit}) => {
    const { width } = useWindowDimensions();
    const [clicked, setClicked] = useState('');
    
    const getButtonStyle = method => {
        const smallButtonDim = {
            height: 200,
            width: 232
        };
        if (selectedAuthMethod === method) {
            if (width > Dimension.sm) return {...styles.button, ...styles.buttonSelected};
            else return {...styles.button, ...styles.buttonSelected, ...smallButtonDim }
        } else {
            if (width > Dimension.sm) return styles.button;
            else return {...styles.button, ...smallButtonDim};
        }
    }

    const handleClick = authMethod => {
        if (clicked === authMethod && selectedAuthMethod === authMethod){
            handleSubmit();
        } else {
            handleMethodChange(authMethod);
            setClicked(authMethod);
        }
    }

    return (
        <div style={styles.root}>
            <p style={width > Dimension.sm ? styles.header : {...styles.header, fontSize: 18}}>Wybierz metodę uwierzytelniania</p>
            <Row style={styles.buttonsDiv}>
                <Column sm={4} md={4} lg={5} style={styles.centered}>
                    <Button 
                        style={getButtonStyle('ID')} 
                        onClick={() => handleClick('ID')}
                    >
                        <img src={IdIcon} alt="id"></img>
                        <p style={styles.methodText}>Zdjęcie dowodu osobistego</p>
                    </Button>
                    <div style={width > Dimension.sm ? styles.detailsDiv : {...styles.detailsDiv, width: 292}}>
                        <p style={styles.detailsText}>Dzięki weryfikacji tożsamości uzupełnimy automatycznie Twoje dane i przyspieszymy udzielenie pożyczki.</p>
                    </div>
                </Column>
                <Column sm={0} md={0} lg={2}>
                    <p style={styles.or}>lub</p>
                </Column>
                <Column sm={4} md={4} lg={5} style={styles.centered}>
                    <Button 
                        style={getButtonStyle('FORM')} 
                        onClick={() => handleClick('FORM')}
                    >
                        <div style={styles.recommended}>
                            <p>Rekomendowane</p>
                        </div>
                        <img src={FormIcon} alt="form" />
                        <p style={styles.methodText}>Wypełnienie formularza</p>
                    </Button>
                    <div style={width > Dimension.sm ? styles.detailsDiv : {...styles.detailsDiv, width: 292}}>
                        <p style={styles.detailsText}>Udzielimy Ci informacji o spersonalizowanej ofercie otrzymania pożyczki.</p>
                    </div>
                </Column>
            </Row>
        </div>
    )
}

const styles = {
    root: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        flex: '1 1',
    },
    header: {
        fontWeight: 700,
        fontSize: 21,
        textAlign: 'center',
        paddingTop: 24,
    },
    buttonsDiv: {
        marginTop: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    button: {
        background: Colors.white,
        border: `2px solid ${Colors.mystic}`,
        boxSizing: 'border-box',
        boxShadow: `0px 3.40014px 85.0036px ${Colors.boxShadowsAuthenticationColor}`,
        borderRadius: 20,
        color: Colors.black,
        height: 248,
        width: 276,
        padding: 16,
        margin: 24,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        textAlign: 'center',
    },
    buttonSelected: {
        border: `3px solid ${Colors.green}`,
    },
    recommended: {
        backgroundColor: Colors.lightGreen,
        borderRadius: 16,
        color: Colors.white,
        padding: 4,
        paddingLeft: 16,
        paddingRight: 16,
        fontSize: 14,
        position: 'absolute',
        top: 0,
        marginTop: -16,
    },
    methodText: {
        fontWeight: 500,
        fontSize: 20,
    },
    detailsDiv: {
        background: Colors.authenticationBackground,
        borderRadius: 16,
        width: 348,
        padding: 16,
    },
    centered: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    detailsText: {
        fontSize: 16,
        color: Colors.black,
    }
}

export default SelectAuthenticationMethod;
