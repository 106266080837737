import { Button } from 'carbon-components-react';
import TaskDoneIcon from '../../../assets/task-24px.svg';
import { Colors } from '../../../utils/styleUtils/Colors';
import promotedProducts from '../../../utils/promotedProductsList';
import PromotedProductModal from './PromotedProductModal';
import { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

import { useAnalyticsEventTracker } from '../../../hooks/useAnalyticsEventTracker';


const SuccessInfo = ({ member, moneyPickup, other, phone, link, branch, visitDay }) => {
    const [productModalOpen, setProductModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState({});
    const [selectedProducts, setSelectedProducts] = useState(promotedProducts.sort(() => .5 - Math.random()).slice(0, 3));
    const {width, height} = useWindowDimensions();
    const [confettiPieces, setConfetti] = useState(200);
    useAnalyticsEventTracker('Gratulacje')

    useEffect(()=>{
        setTimeout(() => {
            setConfetti(0);
        }, 3000)
    })

    function handleOpenProductModal(product) {
        setSelectedProduct(product);
        setProductModalOpen(true);
    }

    const renderCongratulations = moneyPickup => {
        switch(moneyPickup){
            case 'transfer':
                return(
                    <>
                        <p style={styles.title}>Gratulujemy, wniosek został zaakceptowany!</p>
                        <p style={styles.center}>Skontaktujemy się z Tobą telefonicznie w ciągu 24 godzin*</p>
                    </>
                );
            case 'office':
                return(
                    <>
                        <p style={styles.title}>Gratulujemy, wniosek został zaakceptowany!</p>
                        <p style={styles.center}>Przed udaniem się do oddziału zapoznaj się z informacjami przesłanymi na Twój e-mail.</p>
                        <p style={styles.center}>W celu zawarcia umowy pożyczki zapraszamy do oddziału:</p>
                        <p style={styles.text}>{branch.ULICA}, {branch.KOD} {branch.MIEJSCOWOSC}</p>
                        <p style={styles.center}> dnia {visitDay}</p>
                        <p style={styles.center}>tel. {branch['NR KIERUNKOWY']} {branch.TELEFON}</p>
                    </>
                );
        }
    }

    return (
        <div style={styles.root}>

            <Confetti
                width={width - 20}
                height={height}
                numberOfPieces={confettiPieces}
            />
            <div style={styles.successDiv}>
                <img src={TaskDoneIcon} alt="" style={styles.icon} />
                {renderCongratulations(moneyPickup)}
            </div>
            <div style={styles.promoDiv}>
                <p style={{...styles.mediumText, paddingBottom: 20}}>Zobacz promocyjne oferty przygotowane dla Ciebie</p>
                {
                    selectedProducts.map(product => (
                        <Button style={styles.button} onClick={() => handleOpenProductModal(product)}>
                            <p style={styles.mediumText}>{product.title}</p>
                        </Button>
                    ))
                }
                { Object.keys(selectedProduct).length > 0 && <PromotedProductModal open={productModalOpen} onClose={() => setProductModalOpen(false)} product={selectedProduct} phone={phone} link={link} /> }
            </div>
            { moneyPickup === 'transfer' &&(
                <p style={styles.note}>* Z wyłączeniem sobót oraz dni ustawowo wolnych od pracy</p>
            )}
        </div>
    )
}

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
    },
    successDiv: {
        background: Colors.authenticationBackground,
        borderRadius: 16,
        padding: 24,
        paddingBottom: 12,
        paddingTop: 12,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        fontWeight: 700,
        fontSize: 21,
        paddingTop: 12,
        paddingBottom: '.5rem',
        textAlign: 'center',
    },
    mediumText: {
        fontSize: 18,
        fontWeight: 500,
        width: '100%',
        textAlign: 'center',
    },
    text: {
        margin: '0.5rem 0 0 0',
        textAlign: 'center'
    },
    center: {
        textAlign: 'center',
    },
    icon: {
        height: 60,
    },
    promoDiv: {
        paddingTop: 36,
        display: 'flex',
        flexDirection: 'column',
    },
    button: {
        background: Colors.white,
        boxShadow: `0px 4px 20px ${Colors.boxShadowsLighterColor}`,
        borderRadius: 4,
        width: '100%',
        maxWidth: 'none',
        marginTop: 8,
        color: Colors.black,
        textAlign: 'center',
        padding: 4,
        paddingLeft: 12,
        paddingRight: 12,
    },
    note: {
        fontSize: '1rem', 
        marginTop: '1.5rem', 
    }
}

export default SuccessInfo;