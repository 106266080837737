import { Grid, Row, Column } from 'carbon-components-react';
import ProtectionLogo from '../../assets/protection.svg';
import ClockLogo from '../../assets/clock.svg';
import MoneyLogo from '../../assets/money.svg';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Dimension } from '../../utils/styleUtils/Dimension';
import { Colors } from '../../utils/styleUtils/Colors';

const BasicInfo = () => {
    const { width } = useWindowDimensions();

    return (
        <div style={width > Dimension.sm ? styles.root : styles.rootSm}>
            <Grid>
                <Row style={width > Dimension.sm ? {} : styles.rowSm}>
                    <Column style={width > Dimension.sm ? styles.flex : {...styles.flex, width: 'fit-content', marginBottom: 24}}>
                        <img src={ProtectionLogo} alt="protection" style={styles.icon}/>
                        <div>
                            <p style={width > Dimension.sm ? styles.textBold : {...styles.textBold, fontSize: 16}}>Bezpieczeństwo</p>
                            <p style={width > Dimension.sm ? styles.text : {...styles.text, fontSize: 16}}>Szyfrujemy Twoje dane</p>
                        </div>
                    </Column>
                    <Column style={width > Dimension.sm ? styles.flex : {...styles.flex, width: 'fit-content', marginBottom: 24}}>
                        <img src={ClockLogo} alt="clock" style={styles.icon}/>
                        <div>
                            <p style={width > Dimension.sm ? styles.textBold : {...styles.textBold, fontSize: 16}}>Oszczędność czasu</p>
                            <p style={width > Dimension.sm ? styles.text : {...styles.text, fontSize: 16}}>Weź pożyczkę przez internet</p>
                        </div>
                    </Column>
                    <Column style={width > Dimension.sm ? styles.flex : {...styles.flex, width: 'fit-content'}}>
                        <img src={MoneyLogo} alt="money" style={styles.icon}/>
                        <div>
                            <p style={width > Dimension.sm ? styles.textBold : {...styles.textBold, fontSize: 16}}>Szybka realizacja</p>
                            <p style={width > Dimension.sm ? styles.text : {...styles.text, fontSize: 16}}>Wypłata tego samego dnia</p>
                        </div>
                    </Column>
                </Row>
            </Grid>
        </div>
    )
}

const styles = {
    root: {
        width: '100%',
        backgroundColor: Colors.white,
        paddingTop: 40,
        paddingBottom: 40,
    },
    rootSm: {
        marginTop: 32,
        backgroundColor: Colors.white,
        paddingTop: 24,
        paddingBottom: 24,
    },
    text: {
        fontSize: 20,
    },
    textBold: {
        fontSize: 20,
        fontWeight: 700,
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    icon: {
        paddingRight: 16,
        height: 28,
    },
    rowSm: {
        flexDirection: 'column',
        width: 'fit-content',
        marginRight: 'auto',
        marginLeft: 'auto',
    }
}

export default BasicInfo;
