import React, { useEffect, useState, useRef } from 'react';
import './UploadPhotosModal.scss';
import UploadPhotosContent from './UploadPhotosContent';
import HeaderFooterTemplate from '../headerFooterTemplate/HeaderFooterTemplate';
import { MobilePhotoVerificationStatus } from '../../utils/consts';
import { socket } from '../../utils/socketIoConnection';
import { Colors } from '../../utils/styleUtils/Colors';

import { useAnalyticsEventTracker } from '../../hooks/useAnalyticsEventTracker';

const UploadPhotosMobile = () => {
    const [msg, setMsg] = useState('');
    const [status, setStatus] = useState(MobilePhotoVerificationStatus.NOT_STARTED);
    useAnalyticsEventTracker('Weryfikacja')

    useEffect(() => {
        if (window.location.hostname !== 'localhost'){
            const hash = window.location.hash.substring(1);
            socket.emit("join_to_room", hash);
            socket.emit("send_started", hash);
            socket.on("read_status",(data) => {
                setStatus(data.status);
                if (data.status === MobilePhotoVerificationStatus.STARTED) {
                    setMsg('Trwa wczytywanie zdjęć...');
                } else if (data.status === MobilePhotoVerificationStatus.PREPROCESSING) setMsg('Trwa przygotowywanie zdjęć do odczytu...');
                else if (data.status === MobilePhotoVerificationStatus.PROCESSING) setMsg('Trwa odczytywanie danych ze zdjęć...')
                else if (data.status === MobilePhotoVerificationStatus.POSTPROCESSING) setMsg('Trwa końcowe sprawdzenie danych...')
                else if (data.status === MobilePhotoVerificationStatus.FRONT_PROCESSING) setMsg('Trwa odczytywanie danych z przodu dowodu...')
                else if (data.status === MobilePhotoVerificationStatus.BACK_PROCESSING) setMsg('Trwa odczytywanie danych z tyłu dowodu...')
            })
        }
    }, [])

    function handleGoToForm() {
        const hash = window.location.hash.substring(1);
        if (window.location.hostname !== 'localhost'){
            socket.emit("send_go_to_form", hash);
        }
        setMsg('Możesz wrócić do przeglądarki na komputerze.')
    };

    return (
        <div className="upload-modal">
            <HeaderFooterTemplate 
                content={() => (
                    <div style={styles.container}>
                        {
                            status === MobilePhotoVerificationStatus.GO_TO_FORM
                            ? <p style={styles.msg}>Możesz wrócić do formularza w przeglądarce.</p>
                            : <UploadPhotosContent 
                                goToForm={handleGoToForm}
                                onReset={() => setStatus(MobilePhotoVerificationStatus.STARTED)} 
                                mobile={true} 
                                number={new URL(window.location.href).searchParams.get('number')} 
                                status={status}
                                msg={msg}
                            />
                        }
                    </div>
                )} />
        </div>
    )
};

const styles = {
    container: {
        background: Colors.white,
        paddingTop: 20,
        paddingBottom: 20,
        borderRadius: 18,
        paddingRight: 8,
        paddingLeft: 8,
    },
    msg: {
        textAlign: 'center',
    }
}

export default UploadPhotosMobile;
