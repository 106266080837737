import SuccessIdIcon from '../../assets/id_check_icon.svg';
import { Colors } from '../../utils/styleUtils/Colors';

const SuccessInfo = ({ mobile }) => {
    return (
        <div style={styles.container}>
            <div style={styles.box}>
                <img src={SuccessIdIcon} alt="success-id"/>
                <p style={styles.header}>Weryfikacja przebiegła pomyślnie</p>
                { 
                    mobile 
                    ? <p style={styles.text}>Możesz wrócić do przeglądarki na komputerze.</p>
                    : <p style={styles.text}>Za chwilę zostaniesz przekierowany do formularza.</p>
                }
                <p style={styles.text}>Sprawdź czy dane odczytane z dowodu osobistego są poprawne oraz uzupełnij pozostałe informacje.</p>
            </div>
        </div>
    )
}

const styles = {
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    box: {
        background: Colors.authenticationBackground,
        boxSizing: 'border-box',
        borderRadius: 20,
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'fit-content',
        maxWidth: 660,
    },
    header: {
        fontSize: 21,
        fontWeight: 600,
        marginBottom: 12,
        marginTop: 8,
        textAlign: 'center',
    },
    text: {
        textAlign: 'center',
    }
}

export default SuccessInfo;
