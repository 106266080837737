import React, {useState} from "react";
import { Link } from 'react-router-dom';
import { Column, ComposedModal, ModalBody, ModalHeader, Row, TextInput, Button } from "carbon-components-react";
import { Colors } from "../../utils/styleUtils/Colors";
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Dimension } from '../../utils/styleUtils/Dimension';
import SendNumber from "../applicationPage/forms/SendNumber";

const AboveMaxModal = props => {
    const {
        open,
        onClose,
        link
    } = props
    const { width } = useWindowDimensions();
    const [sent, setSent] = useState(false);

    return (
        <ComposedModal
            open={open}
            onClose={onClose}
            size="lg"
            style={styles.modal}
        >
            <ModalHeader style={styles.header}>
                <object type="image/svg+xml" data='svg/rocket.svg' alt='' style={styles.bigSvg}/>
            </ModalHeader>

            <ModalBody style={styles.modalBody}>
                <p style={ width > Dimension.sm ? styles.textBold : {...styles.textBold, whiteSpace: 'pre-wrap', textAlign: 'center'}}>
                    Dziękujemy za zainteresowanie naszą ofertą. 
                </p>

                <p style={ width > Dimension.sm ? styles.textBold : {...styles.textBold, whiteSpace: 'pre-wrap', textAlign: 'center'}}>
                    Podana kwota przekracza maksymalny limit (60 000 zł).
                </p>

                <Link
                    to={link}
                    style={ width > Dimension.sm ? styles.button : {...styles.button, ...styles.buttonSm}}
                >
                    Kontynuuj wniosek na kwotę 60 000 zł
                </Link>
                
                <hr style={ width > Dimension.sm ? styles.hr : {...styles.hr, width: 0}}/>

                <p style={ width > Dimension.sm ? styles.text : {...styles.text, whiteSpace: 'pre-wrap', textAlign: 'center'}}>
                    Jeżeli chcesz procedować wniosek na <p style={styles.textBold}>wyższą kwotę</p>, zapraszamy do bezpośredniego kontaktu:
                </p>

                <Row style={width > Dimension.sm ? {display: 'flex', flexDirection: 'row'} : {display: 'flex', flexDirection: 'column'}}>
                    <Column style={width > Dimension.sm ? {...styles.col, width: '32rem'} : styles.col} >
                        <p style={ width > Dimension.sm ? styles.textBold : {...styles.textBold, whiteSpace: 'pre-wrap'}}>
                            Zadzwoń teraz
                        </p>
                        <p style={styles.phoneSpan}>
                            <img src={'svg/phone.svg'} style={styles.svg} alt=''/> 
                                {width > Dimension.sm ? '81 446 30 00' : <a href="tel:81446-30-00" style={styles.link}>81 446 30 00</a>}
                        </p>
                    </Column>
                    <Column style={width > Dimension.sm ? {...styles.col, width: '32rem'} : {...styles.col, paddingTop: '2rem'}}>
                        {sent 
                            ? <></> 
                            : <p style={ width > Dimension.sm ? styles.textBold : {...styles.textBold, whiteSpace: 'pre-wrap'}}>
                                Lub zostaw numer telefonu, a oddzwonimy
                                </p>
                        }
                        <SendNumber setNumberSent={setSent} />
                    </Column>
                </Row>

                <Button style={styles.closeButton} onClick={onClose}>
                    Zamknij
                </Button>
            </ModalBody>
        </ComposedModal>
    );
}

const styles = {
    modal: {
        padding: '1rem',
        paddingBottom: 0
    },
    bigSvg: {
        maxWidth: '5rem',
    },
    header:{
        padding: 0,
        paddingTop: '1.5rem',
        margin: 0,
        fontSize: '1.313rem',
        textAlign: 'center'
    },
    modalBody: {
        padding: 0,
        margin: '0.75rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems:'center'
    },
    svg:{
        marginRight: '0.5rem',
    },
    text: {
        fontSize: '1rem',
        paddingTop: '1.125rem',
        paddingBottom: '0.875rem'
    },
    textBold: {
        fontSize: '1rem',
        fontWeight: 600,
        display: 'inline',
        whiteSpace: 'nowrap',
    },
    textComment: {
        fontSize: '.8rem',
        padding: 0,
        paddingTop: '.5rem',
        margin: 0,
        color: Colors.grey,
        whiteSpace: 'nowrap',
        textAlign: 'center'
    },
    hr:{
        height: '0.063rem',
        width: '59.375rem',
        border: 0,
        borderRadius: '0.188rem',
        backgroundColor: Colors.mystic
    },
    phoneSpan: {
        marginTop: '1.7rem',
        color: Colors.black,
        fontSize: '1.125rem',
        fontWeight: 'bold',
        textAlign: 'center',
        whiteSpace: 'nowrap',
    },
    col: {
        textAlign: 'center',
        margin: 0,
        padding: 0
    },
    button: {
        background: Colors.white,
        border: `2px solid ${Colors.mystic}`,
        borderRadius: '0.375rem',
        color: Colors.lightGreen,
        fontSize: '1rem',
        fontWeight: 500,
        padding: '8px 21px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '1.25rem',
        marginBottom: '1.75rem',
        textDecoration: 'none'
    },
    buttonSm: {
        width: '100%',
        fontSize: '0.9rem',
        textAlign: 'center',
        maxWidth: 'none',
    },
    closeButton: {
        backgroundColor: Colors.white,
        margin: 0,
        padding: '0.75rem',
        fontWeight: 500,
        fontSize: '1.125rem',
        color: Colors.green,
        textAlign: 'center',
        textDecoration: 'none',
        borderRadius: '0.25rem',
        borderWidth: 0,
    },
    link: {
        color: Colors.black,
    },
}

export default AboveMaxModal;