import './app.scss';
import Routing from './routing/Routing';
import CookieConsent from "react-cookie-consent";
import { Colors } from './utils/styleUtils/Colors';

const style = {
  fontFamily: 'Work Sans',
}

function App() {

  return (
      <div className="App" style={style}>
        <Routing />
        <CookieConsent
          location="bottom"
          buttonText="Rozumiem"
          cookieName="myAwesomeCookieName2"
          style={window.location.pathname === '/kalkulator' || window.location.pathname === '/oddzialy' ? {...styles.cookiesBar, display: 'none'} : styles.cookiesBar}
          buttonStyle={styles.cookiesButton}
          expires={150}
        >
          <p style={styles.cookiesText}>
          Nasz serwis wykorzystuje pliki cookies do przechowywania identyfikatora sesji użytkownika. Pliki cookies wykorzystywane są do celów statystycznych - system statystyk Google Analitycs, oraz w mechanizmie wyświetlania niniejszego komunikatu. Możesz zrezygnować ze zbierania informacji poprzez pliki cookies, zmieniając ustawienia Twojej przeglądarki. 
          </p>
        </CookieConsent>
      </div>
  );
}

const styles = {
  cookiesBar: {
    background: Colors.chineseWhite,
    color: Colors.black,
    alignItems: 'center',
  },
  cookiesButton: {
    background: Colors.lightGreen,
    color: Colors.white, 
    fontSize: "0.813rem",
    borderRadius: "0.313rem"
  },
  cookiesText: {
    fontSize: "0.688rem", 
  }
}

export default App;
