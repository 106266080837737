const postSendSmsCode = (phone, code) => {
    return fetch('/api/offer/post-send-sms-code', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            phone: phone,
            message: `${code} to Twój kod weryfikacyjny od SKOK Chmielewskiego.`,
        })
    })
    .then(res => res.json())
}

export { postSendSmsCode }