import { Column, Grid, Row, Accordion, AccordionItem } from 'carbon-components-react';
import ReactPlayer from 'react-player'
import HeaderFooterTemplate from '../headerFooterTemplate/HeaderFooterTemplate';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Dimension } from '../../utils/styleUtils/Dimension';
import KnowUsBetterImg from '../../assets/get-to-know-us.png';
import KrwiodawcyImg from '../../assets/krwiodawcy.jpg';
import WsparcieImg from '../../assets/pan-piotr.jpg';
import BiegiImg from '../../assets/biegi.jpg';
import ChoinkaImg from '../../assets/choinka.jpg';
import './OurMission.scss';
import { Colors } from '../../utils/styleUtils/Colors';

import { useAnalyticsEventTracker } from '../../hooks/useAnalyticsEventTracker';

const KnowUsBetterPage = () => {
    const { width } = useWindowDimensions();
    useAnalyticsEventTracker('Nasza misja')

    const content = () => (
        <Grid>
            <Row>
                <Column sm={0} md={1}></Column>
                <Column sm={4} md={6}>
                    <div style={styles.box}>
                        <div style={{position: 'relative'}}>
                            <img src={KnowUsBetterImg} alt="laptop" style={styles.photo} />
                            <h1 style={width > Dimension.sm ? styles.photoTitle : {...styles.photoTitle, fontSize: 20}}>Nasza misja</h1>
                        </div>
                        <Row>
                            <Column sm={0} md={1}></Column>
                            <Column sm={4} md={6}>
                                <div style={styles.content}>
                                    <p style={styles.paragraph}>Spółdzielcza Kasa Oszczędnościowo-Kredytowa im. Zygmunta Chmielewskiego powstała w celu realizacji i upowszechniania finansowej samopomocy, edukacji finansowej oraz działania na rzecz swoich Członków poprzez zapewnienie im najlepszej oferty finansowej oraz jakości i kultury obsługi. Działalność SKOK im. Z. Chmielewskiego nie ogranicza się jednak tylko do sfery czysto finansowej, ale ukierunkowana jest także na pomoc w organizacji różnych wydarzeń kulturalnych i społecznych. Zawieramy wiele umów sponsorskich z instytucjami kultury, współorganizujemy koncerty, angażujemy się w działania społeczno-edukacyjne, które dotujemy i w których sami aktywnie uczestniczymy. Pomagamy wyposażać szkoły oraz szpitale w potrzebne narzędzia pracy, przyczyniamy się do renowacji zabytków architektonicznych i budynków sakralnych. Czujemy się pośrednio odpowiedzialni za środowisko, które nas otacza - znając jego problemy i potrzeby staramy się na nie właściwie reagować.</p>
                                    <p style={styles.title}>Wspierane akcje</p>
                                    <p style={styles.answer}>Od wielu lat wspieramy różne inicjatywy, promujemy aktywny i zdrowy tryb życia. Chcemy być blisko naszych Członków i lokalnych społeczności, w których żyją. Stąd też wzięło się nasze hasło: SKOK Chmielewskiego – Twój przyjaciel finansowy.</p>
                                    <p style={styles.answer}>Jesteśmy jedynym SKOK-iem, przy którym funkcjonuje Klub Honorowych Dawców Krwi. Wspieramy jego działalność od wielu lat, uczestnicząc w wydarzeniach polegających na oddawaniu krwi i rejestracji dawców szpiku.</p>
                                    <p style={styles.answer}>Od wielu lat jesteśmy współorganizatorem akcji “Podziel się zdrowiem”, która ma na celu promowanie krwiodawstwa. W wydarzeniu można wziąć udział na dwa sposoby: oddając krew lub zgłaszając się do rejestru dawców szpiku. W czerwcu 2021 r. odbyła się już XIII edycja akcji pod hasłem “Dobro uskrzydla”.</p>
                                    <div style={styles.parentPhotoFromActions}>
                                        <img src={KrwiodawcyImg} alt="krwiodawcy" style={styles.photoFromActions}/>
                                    </div>
                                    <p style={styles.answer}>W lutym 2021 r. wsparliśmy także imprezę “Lodowata krew z gorących serc”, zorganizowaną przez Lubelski Klub Morsów wspólnie z Regionalnym Centrum Krwiodawstwa i Krwiolecznictwa w Lublinie. Była to świetna okazja nie tylko do wspólnego morsowania, ale także do oddania krwi oraz nauki pierwszej pomocy.</p>
                                    <div style={styles.parentPhotoFromActions}>
                                        <ReactPlayer style={styles.video} width="100%" url='https://youtu.be/Yk-1rTmrr_w' />
                                    </div>
                                    <p style={styles.answer}>Współpraca z Telewizją TVP Lublin zaowocowała natomiast przyłączeniem się do charytatywnej akcji “Słonik nadziei”, zapewniającej wsparcie materialne potrzebującym. Są to m.in. domy dziecka, szpitale, przedszkola, szkoły, ośrodki pomocy.</p>
                                    <p style={styles.answer}>Wspieramy osoby niepełnosprawne w dążeniu do realizacji ich marzeń. Przekazujemy środki zawodnikom, którzy regularnie startują w zawodach sportowych.</p>
                                    <div style={styles.parentPhotoFromActions}>
                                        <img src={WsparcieImg} alt="wsparcie" style={styles.photoFromActions}/>
                                    </div>
                                    <p style={styles.answer}>Wspieramy inicjatywy Członków naszej Kasy - między innymi biegi uliczne, których celem jest nie tylko propagowanie zdrowego trybu życia, ale także jego ratowanie. Przez lata byliśmy współorganizatorem m.in. biegu ulicznego “Skoczny ceramik”, któremu towarzyszył piknik rodzinny w Ćmielowie.</p>
                                    <div style={styles.parentPhotoFromActions}>
                                        <ReactPlayer style={styles.video} width="100%" url='https://youtu.be/7f2yV51pFi4' />
                                    </div>
                                    <p style={styles.answer}>Wspieramy również Lubelski Klub Biegowy, realizując tym samym nasz cel, którym jest bycie nie tylko dostawcą usług i produktów finansowych, ale również tworzenie bliższej więzi z naszymi członkami i ich rodzinami oraz promowanie zdrowego trybu życia.</p>
                                    <div style={styles.parentPhotoFromActions}>
                                        <img src={BiegiImg} alt="wsparcie" style={styles.photoFromActions}/>
                                    </div>
                                    <p style={styles.answer}>Od wielu lat wspieramy inicjatywę Lubelskiego Klubu Szachowego, jaką jest organizacja Międzynarodowych rozgrywek szachowych im. Jana Zukertorta. Również w tym roku z przyjemnością objęliśmy patronatem kolejną, XIII już edycję tego wydarzenia.</p>
                                    <p style={styles.answer}>Chętnie angażujemy się też w inicjatywy społeczności lokalnych, takie jak bożonarodzeniowe ubieranie choinki na osiedlu im. Adama Mickiewicza w Lublinie. Podczas wydarzenia mieszkańcy stroją choinkę własnoręcznie wykonanymi ozdobami i wspólnie śpiewają kolędy.</p>
                                    <div style={styles.parentPhotoFromActions}>
                                        <img src={ChoinkaImg} alt="wsparcie" style={styles.photoFromActions}/>
                                    </div>
                                </div>
                            </Column>
                            <Column sm={0} md={1}></Column>
                        </Row>
                    </div>
                </Column>
                <Column sm={0} md={1}></Column>
            </Row>
        </Grid>
    );

    return (
        <HeaderFooterTemplate content={content} hideBasicInfo={true} />
    )
};

const styles = {
    box: {
        background: Colors.white,
        boxShadow: `0px 3.40014px 85.0036px ${Colors.boxShadowsColor}`,
        borderRadius: 17,
        width: '100%',
        marginBottom: 32,
    },
    photo: {
        width: '100%',
    },
    parentPhotoFromActions:{
        display: 'flex',
        justifyContent: 'center',
        
    },
    photoFromActions: {
        width: '75%',
        borderRadius: '10px',
        marginBottom: '10px',
        marginTop: '10px',
        boxShadow: `0px 3.40014px 85.0036px ${Colors.boxShadowsColor}`,
    },
    photoTitle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontWeight: 'bold',
        fontSize: 30,
        color: Colors.white,
        textAlign: 'center',
    },
    content: {
        paddingTop: 16,
        paddingBottom: 32,
        paddingLeft: 16,
        paddingRight: 16,
    },
    title: {
        fontWeight: 600,
        fontSize: 21,
        paddingBottom: 16,
        paddingTop: 32,
        textAlign: 'center',
    },
    paragraph: {
        paddingTop: 12,
        paddingBottom: 12,
    },
    videoDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    video: {
        maxWidth: 640,
        paddingTop: 12,
        marginBottom: 24,
    },
    answer: {
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1.5,
        letterSpacing: 0,
        paddingTop: 8,
        paddingBottom: 8,
    }
};

export default KnowUsBetterPage;
