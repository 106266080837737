import { useState } from 'react';
import { Column,  Row } from "carbon-components-react";
import { Colors } from "../../../utils/styleUtils/Colors";
import SendNumber from "./SendNumber";
import { Dimension } from "../../../utils/styleUtils/Dimension";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const FailInfo = () => {
    const { width } = useWindowDimensions();
    const [sent, setSent] = useState(false);

    return (
        <div style={styles.root}>
            <object type="image/svg+xml" data='svg/fail.svg' alt='' style={styles.bigSvg}/>
            <p style={width > Dimension.sm ? styles.textBold : {...styles.textBold, whiteSpace: 'pre-wrap', textAlign: 'center'}}>
                Dziękujemy za zainteresowanie naszą ofertą. Dzisiaj nie udało się wygenerować automatycznej decyzji kredytowej.
            </p>
            <p style={width > Dimension.sm ? styles.text : {...styles.text, whiteSpace: 'pre-wrap', textAlign: 'center'}}>
                Jeżeli chcesz dalej procedować wniosek, zapraszamy do kontaktu bezpośredniego:
            </p>
            <Row>
                <Column style={styles.col}>
                    <p style={width > Dimension.sm ? styles.textBold : {...styles.textBold, whiteSpace: 'pre-wrap'}}>
                        Zadzwoń teraz
                    </p>
                    <p style={styles.phoneSpan}>
                        <img src={'svg/phone.svg'} style={styles.svg} alt=''/>
                            {width > Dimension.sm ? '81 446 30 00' : <a href="tel:81446-30-00" style={styles.link}>81 446 30 00</a>}
                    </p>
                    <p style={styles.textComment}>
                        (36 gr brutto za minutę połączenia)
                    </p>
                </Column>
                <Column style={width > Dimension.sm ? styles.col : {...styles.col, marginTop: 20}}>
                    {sent 
                        ? <></> 
                        : <p style={width > Dimension.sm ? styles.textBold : {...styles.textBold, whiteSpace: 'pre-wrap'}}>
                                Lub zostaw numer telefonu, a oddzwonimy
                            </p>
                    }
                    <SendNumber setNumberSent={setSent} />
                </Column>
            </Row>
        </div>
    )
}

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
    },
    col: {
        textAlign: 'center'
    },
    text: {
        fontSize: '1rem',
        paddingTop: '3rem',
        paddingBottom: '2rem'
    },
    textBold: {
        fontSize: '1rem',
        fontWeight: 600,
        display: 'inline',
        whiteSpace: 'nowrap'
    },
    textComment: {
        fontSize: '.8rem',
        padding: 0,
        paddingTop: '.6rem',
        margin: 0,
        color: Colors.grey,
        whiteSpace: 'nowrap',
        textAlign: 'center'
    },
    svg:{
        marginRight: '0.5rem',
    },
    bigSvg: {
        paddingBottom: '1rem',
        maxHeight: '8rem'
    },
    phoneSpan: {
        marginTop: '1.7rem',
        color: Colors.black,
        fontSize: '1.125rem',
        fontWeight: 'bold',
        textAlign: 'center',
        whiteSpace: 'nowrap',
    },
    link: {
        color: Colors.black,
    },
}

export default FailInfo;