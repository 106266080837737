import { useEffect, useState } from "react";
import { Colors } from "../../utils/styleUtils/Colors";
import { Grid, Row, Column, Select, SelectItem } from 'carbon-components-react';
import Map from "../applicationPage/map/Map";
import BranchesList from "../applicationPage/branchesList/BranchesList";
import { getBranches } from "../../utils/postGetMethods/getBranches";
import { Checkbox } from "carbon-components-react";

const ExternalBranches = () => {
    const [branches, setBranches] = useState([]);
    const [branchesWithATM, setBranchesWithATM] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState(undefined);
    const [branchesField, setBranchesField] = useState(true);
    const [atmField, setAtmField] = useState(true);

    useEffect(() => {
        getBranches(true)
            .then(branches => {
                setBranches(branches);
                setBranchesWithATM(branches.filter(item => item.BANKOMAT));
            })
    }, []);

    const getCurrentBranches = () => {
        if (atmField && !branchesField) return branchesWithATM
        else if (!atmField && !branchesField) return []
        else return branches
    }

    const onChangeSelectField = e => {
        if (e.target.value !== "placeholder") {
            setSelectedBranch(branches.filter(item => item.LP == e.target.value)[0])
        }
    }

    return (
    <div style={styles.background}>
        <Grid style={styles.main}>
            <Row>
                <Column>
                    <p style={styles.textTitle}>Wyszukaj oddział: </p>
                </Column>
            </Row>
            <Row>
                <Column style={{ marginTop: '1rem' }}>
                    <p style={styles.textSubtitle}>Pokaż: </p>
                </Column>
            </Row>
            <Row>
                <Column sm={2} md={1} lg={2} xlg={2} style={styles.flexCenter}>
                    <Checkbox
                        id={'branchField'}
                        checked={branchesField}
                        onChange={setBranchesField}
                        labelText={'Oddziały'}
                    />
                </Column>
                <Column sm={2} md={1} lg={2} xlg={2} style={styles.flexCenter}>
                    <Checkbox
                        id={'atmField'}
                        checked={atmField}
                        onChange={setAtmField}
                        labelText={'Bankomaty'}
                    />
                </Column>
                <Column sm={4} md={3} lg={5} xlg={5} style={styles.flexCenter}>
                    <Select
                        onChange={onChangeSelectField}
                        labelText="Wybierz oddział"
                    >
                        <SelectItem
                            value="placeholder"
                            text={"Wybierz..."}
                        />
                        {branches.map((item, index) => (
                            <SelectItem
                                key={index}
                                value={item.LP}
                                text={`${item.MIEJSCOWOSC} ul. ${item.ULICA}`}
                            />
                        ))}
                    </Select>
                </Column>
            </Row>
            <Row style={{ paddingTop: 20 }}>
                <Column sm={4} md={5} style={styles.mapContainer}>
                    <Map
                        branches={getCurrentBranches()}
                        selected={selectedBranch}
                        onBranchSelect={setSelectedBranch}
                    />
                </Column>
                <Column sm={4} md={3} style={styles.mapContainer}>
                    <BranchesList
                        branches={getCurrentBranches()}
                        selected={selectedBranch}
                        onBranchSelect={setSelectedBranch}
                    />
                </Column>
            </Row>
        </Grid>
    </div>
    );
}

export default ExternalBranches


const styles = {
    background: {
        backgroundColor: Colors.white, 
        height: '100vh', 
        width: '100vw'
    },
    main: {
        minHeight: 600,
        maxWidth: 1000,
        backgroundColor: Colors.white,
        margin: 0,
        padding: '1.5rem'
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center'
    },
    textTitle: {
        color: Colors.black,
        fontWeight: 700,
        fontSize: '1.3rem',
        textAlign: 'center'
    },
    textSubtitle: {
        color: Colors.black,
        fontSize: '1rem'
    },
    mapContainer: {
        position: 'relative',
        minHeight: 350,
    },
}