import React from 'react';
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import UploadPhotosMobile from '../components/idVerification/UploadPhotosMobile';
import LandingPage from '../components/landingPage/LandingPage';
import ApplicationPage from '../components/applicationPage/ApplicationPage';
import SecurityPage from '../components/articles/SecurityPage';
import KnowUsBetterPage from '../components/articles/KnowUsBetterPage';
import OurMission from '../components/articles/OurMission';
import ExternalCalculator from '../components/external/ExternalCalculator';
import ExternalBranches from '../components/external/ExternalBranches';

const Routing = () => {
    const history = createBrowserHistory();

    return (
        <Router history={history}>
            <Switch>
                <Route exact path='/weryfikacja' component={UploadPhotosMobile} />
                <Route exact path='/' component={LandingPage} />
                <Route path='/wniosek' component={ApplicationPage} />
                <Route exact path='/bezpieczenstwo' component={SecurityPage} />
                <Route exact path='/poznaj-nas-blizej' component={KnowUsBetterPage} />
                <Route exact path='/nasza-misja' component={OurMission} />

                <Route exact path='/kalkulator' component={ExternalCalculator} />
                <Route exact path='/oddzialy' component={ExternalBranches} />
            </Switch>
        </Router>
    )
}

export default Routing;
