import { useState } from 'react';
import { Button } from 'carbon-components-react';
import { Colors } from '../../../utils/styleUtils/Colors';
import PhoneInput from './PhoneInput';

const SendNumber = ({ number, link, setNumberSent }) => {
    const [phone, setPhone] = useState(number ? number : '');
    const [phoneValid, setPhoneValid] = useState(!!number);
    const [sent, setSent] = useState(false);

    function handleSendNumber() {
        fetch('/api/offer/send-number', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ telefon: phone, link })
        })
        .then(response => response.json())
        .then(output => {
            setSent(true);
            if (setNumberSent){
                setNumberSent(true);
            }
        })
    }

    return (
        <div style={styles.providePhoneDiv}>
            {
                sent
                ? <p>Dziękujemy. Nasz konsultant skontaktuje się z Tobą.</p>
                : <div style={styles.root}>
                    <div style={styles.row}>
                        <PhoneInput value={phone} onChange={setPhone} onValidation={setPhoneValid} />
                        <Button style={styles.buttonSend} onClick={handleSendNumber} disabled={!phoneValid}>Wyślij</Button>
                    </div>
                    <div style={styles.clauseDiv}>
                        <p style={styles.clause}>Podane przez Panią/Pana dane w zakresie numeru telefonu będą przetwarzane przez SKOK Chmielewskiego wyłącznie w celu udzielenia pomocy w wypełnieniu formularza, przez okres nie dłuższy niż 7 dni. Administratorem danych osobowych jest Spółdzielcza Kasa Oszczędnościowo-Kredytowa im. Zygmunta Chmielewskiego, ul. Wallenroda 2E, 20-607 Lublin. Kontakt z Inspektorem ochrony danych: iod@skokchmielewskiego.pl. Podanie przez Panią/Pana danych jest dobrowolne. Ma Pani/Pan prawo dostępu do swoich danych oraz ich sprostowania. Prosimy o zapoznanie się z pełną informacją o przetwarzaniu danych osobowych przez SKOK Chmielewskiego.</p>
                    </div>
                </div>
            }
        </div>
    )
}

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
    clause: {
        fontSize: '0.7rem'
    },
    clauseDiv: {
        margin: '1rem 3rem 0 3rem',
    },
    providePhoneDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 16,
        marginBottom: 28,
    },
    buttonSend: {
        padding: 13,
        paddingLeft: 24,
        paddingRight: 24,
        marginLeft: 20,
        borderWidth: 0,
        textAlign: 'center',
        fontSize: 18,
        backgroundColor: Colors.orange,
        borderRadius: 4,
        fontWeight: 500,
        textDecoration: 'none',
        color: Colors.white,
    },
}

export default SendNumber;
