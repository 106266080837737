import { useState, useEffect } from "react";
import { Column, Grid, Row } from "carbon-components-react";

import CalcSingleSlider from "./calcComponents/CalcSingleSlider";
import { conditionsCalculator } from "../creditCalculator/conditionsCalculator";
import { Colors } from "../../utils/styleUtils/Colors";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const ExternalCalculator = () => {
    const {
        MIN_AMOUNT,
        MAX_AMOUNT,
        MAX_MAX_AMOUNT,
        MIN_INSTALLMENT_AMOUNT,
        MIN_INSTALLMENTS,
        MAX_INSTALLMENTS,
        MAX_INSTALLMENTS_AMOUNT,
        DEFAULT_AMOUNT,
        DEFAULT_INSTALLMENT_AMOUNT
    } = conditionsCalculator
    const { width } = useWindowDimensions();
    const [amount, setAmount] = useState(DEFAULT_AMOUNT);
    const [installmentAmount, setInstallmentAmount] = useState(DEFAULT_INSTALLMENT_AMOUNT);
    const [numberOfInstallments, setNumberOfInstallments] = useState(DEFAULT_AMOUNT / DEFAULT_INSTALLMENT_AMOUNT);

    useEffect(() => {
        if (amount > MAX_AMOUNT) {
            setInstallmentAmount(DEFAULT_INSTALLMENT_AMOUNT);
            setNumberOfInstallments(DEFAULT_AMOUNT / DEFAULT_INSTALLMENT_AMOUNT);
        }
    })

    const handleBlur = () => {
        let tmpAmount = amount;
        let tmpInstallmentsAmount = installmentAmount;
        if (tmpAmount < MIN_AMOUNT) tmpAmount = MIN_AMOUNT;
        else if (tmpAmount > MAX_MAX_AMOUNT) tmpAmount = MAX_AMOUNT;
        else if (Math.ceil(tmpAmount / tmpInstallmentsAmount) > MAX_INSTALLMENTS) tmpInstallmentsAmount = tmpAmount / MAX_INSTALLMENTS;
        else if (Math.ceil(tmpAmount / tmpInstallmentsAmount) < MIN_INSTALLMENTS) tmpInstallmentsAmount = tmpAmount / MIN_INSTALLMENTS;
        else if (tmpAmount < tmpInstallmentsAmount) tmpInstallmentsAmount = tmpAmount / MIN_INSTALLMENTS;
        else if (tmpInstallmentsAmount < MIN_INSTALLMENT_AMOUNT) tmpInstallmentsAmount = MIN_INSTALLMENT_AMOUNT;
        else if (tmpInstallmentsAmount > MAX_INSTALLMENTS_AMOUNT) tmpInstallmentsAmount = MAX_INSTALLMENTS_AMOUNT;
        setAmount(tmpAmount);
        setInstallmentAmount(Math.ceil(tmpInstallmentsAmount));
        setNumberOfInstallments(Math.ceil(tmpAmount / tmpInstallmentsAmount));
    }

    const handleBlurNumber = () => {
        let tmpAmount = amount;
        let tmpNumberOfInstallments = numberOfInstallments;
        if (tmpAmount / tmpNumberOfInstallments > MAX_INSTALLMENTS_AMOUNT) tmpNumberOfInstallments = tmpAmount / MAX_INSTALLMENTS_AMOUNT;
        else if (tmpAmount / tmpNumberOfInstallments < MIN_INSTALLMENT_AMOUNT) tmpNumberOfInstallments = tmpAmount / MIN_INSTALLMENT_AMOUNT;
        setAmount(tmpAmount);
        setInstallmentAmount(Math.ceil(tmpAmount / tmpNumberOfInstallments))
        setNumberOfInstallments(tmpNumberOfInstallments);
    }

    return (
        <div style={styles.background}>
            <Grid style={styles.calculatorDiv}>
                <Row>
                    <Column style={{ ...styles.textCenter, marginTop: '1rem' }}>
                        <p style={styles.textTitle}>
                            Praktyczne pożyczki zdalnie
                        </p>
                    </Column>
                </Row>
                <Row>
                    <Column style={{ ...styles.textCenter, marginTop: '0.5rem' }}>
                        <p style={styles.textSubtitle}>
                            1. Złóż wniosek online &#62; 2. Zaakceptuj ofertę &#62; 3. Odbierz gotówkę lub przelew
                        </p>
                    </Column>
                </Row>
                <Row style={{ ...styles.flexCenter, padding: '0 1.5rem 1.5rem 2.5rem' }} >
                    <Column md={width > 940 ? 2 : 4} lg={3} xlg={3} style={styles.flexCenter}>
                        <CalcSingleSlider
                            header={"Ile chcesz pożyczyć?"}
                            minValue={MIN_AMOUNT}
                            maxValue={MAX_AMOUNT}
                            maxMaxValue={MAX_MAX_AMOUNT}
                            step={100}
                            value={amount}
                            setValue={setAmount}
                            handleBlur={handleBlur}
                            stopSlider={false}
                            unit={"zł"}
                        />
                    </Column>
                    <Column md={width > 940 ? 2 : 4} lg={3} xlg={3} style={styles.flexCenter}>
                        <CalcSingleSlider
                            header={"Jaką ratę możesz płacić?"}
                            minValue={MIN_INSTALLMENT_AMOUNT}
                            maxValue={MAX_INSTALLMENTS_AMOUNT}
                            maxMaxValue={MAX_INSTALLMENTS_AMOUNT}
                            step={1}
                            value={installmentAmount}
                            setValue={setInstallmentAmount}
                            handleBlur={handleBlur}
                            stopSlider={amount > MAX_AMOUNT}
                            unit={"zł"}
                        />
                    </Column>
                    <Column md={width > 940 ? 2 : 4} lg={3} xlg={3} style={styles.flexCenter}>
                        <CalcSingleSlider
                            header={"Okres spłaty"}
                            minValue={MIN_INSTALLMENTS}
                            maxValue={MAX_INSTALLMENTS}
                            maxMaxValue={MAX_INSTALLMENTS}
                            step={1}
                            value={numberOfInstallments}
                            setValue={setNumberOfInstallments}
                            handleBlur={handleBlurNumber}
                            stopSlider={amount > MAX_AMOUNT}
                            unit={"mies."}
                        />
                    </Column>
                    <Column md={width > 940 ? 2 : 4} lg={3} xlg={3} style={{ ...styles.flexCenter, alignItems: 'center' }}>
                        <a
                            href="https://praktycznepozyczki.pl"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={styles.button}
                        > Składam wniosek </a>
                    </Column>
                </Row>
            </Grid>
        </div>
    )
}


const styles = {
    background: {
        backgroundColor: Colors.white, 
        height: '100vh', 
        width: '100vw'
    },
    calculatorDiv: {
        maxWidth: 1000,
        background: Colors.white,
        margin: 0,
        paddingLeft: 0,
        paddingRight: 0,
    },
    button: {
        textDecoration: 'none',
        backgroundColor: Colors.orange,
        borderRadius: 4,
        border: 0,
        width: '13rem',
        height: '4rem',
        fontSize: 18,
        fontWeight: 550,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: Colors.white,
        cursor: 'pointer',
    },
    textTitle: {
        color: Colors.externalColor,
        fontSize: '1.3rem',
        fontWeight: 700,
    },
    textSubtitle: {
        color: Colors.black,
        fontSize: '1rem',
        fontWeight: 500
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'center'
    },
    textCenter: {
        textAlign: 'center'
    }
}

export default ExternalCalculator