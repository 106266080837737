import moment from 'moment-business-days';

const today = new Date();
const checkDays = 14;
const arr = [...Array(checkDays).keys()];

export const businessDates = arr.map(elem => {
    let tmp = new Date();
    tmp.setDate(today.getDate() + elem);
    return ('0' + tmp.getDate()).slice(-2)+'.'+('0' + (tmp.getMonth()+1)).slice(-2)+'.'+tmp.getFullYear();
}).filter(date => {
    return moment(date, 'DD.MM.YYYY').isBusinessDay();
})