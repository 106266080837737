import { Column, Grid, Row } from 'carbon-components-react';
import HeaderFooterTemplate from '../headerFooterTemplate/HeaderFooterTemplate';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Dimension } from '../../utils/styleUtils/Dimension';
import KnowUsBetterImg from '../../assets/get-to-know-us.png';
import { Colors } from '../../utils/styleUtils/Colors';

import { useAnalyticsEventTracker } from '../../hooks/useAnalyticsEventTracker';

const KnowUsBetterPage = () => {
    const { width } = useWindowDimensions();
    useAnalyticsEventTracker('Poznaj nas blizej')

    const content = () => (
        <Grid>
            <Row>
                <Column sm={0} md={1}></Column>
                <Column sm={4} md={6}>
                    <div style={styles.box}>
                        <div style={{position: 'relative'}}>
                            <img src={KnowUsBetterImg} alt="laptop" style={styles.photo} />
                            <h1 style={width > Dimension.sm ? styles.photoTitle : {...styles.photoTitle, fontSize: 20}}>Poznaj nas bliżej</h1>
                        </div>
                        <Row>
                            <Column sm={0} md={1}></Column>
                            <Column sm={4} md={6}>
                                <div style={styles.content}>
                                    <p style={styles.paragraph}>Jako Spółdzielcza Kasa Oszczędnościowo-Kredytowa im. Zygmunta Chmielewskiego funkcjonujemy już od 1994 r. Od samego początku naszej działalności kierujemy się zasadą samopomocy finansowej, jednocześnie odpowiadając na potrzeby i oczekiwania Członków naszej Kasy. Naszym głównym celem jest propagowanie oszczędności i gospodarności, zapewnianie pożyczek o umiarkowanym oprocentowaniu oraz edukacja ekonomiczna.</p>
                                    <p style={styles.paragraph}>Stawiamy na dynamiczny rozwój, dlatego od lat systematycznie rozbudowujemy naszą ofertę produktową, aby jak najlepiej odpowiadać na potrzeby finansowe naszych Klientów. Wśród naszych produktów znajdują się nie tylko pożyczki, kredyty i lokaty, ale także rachunki płatnicze wraz z kartą Visa, usługa bankowości elektronicznej eSKOK - z aplikacją mobilną mSKOK - oraz usługa Google Pay.</p>
                                    <p style={styles.paragraph}>Dzięki naszym działaniom, nastawionym przede wszystkim na dobro Klienta, zgromadziliśmy wokół siebie wspaniałą społeczność, stając się jednocześnie jedną z największych Kas w Polsce. Obecnie posiadamy ponad 90 oddziałów, zlokalizowanych w całym kraju. Wszędzie, gdzie działają nasze placówki, staramy się angażować w życie lokalnych społeczności - od wielu lat wspieramy różne inicjatywy, promując aktywny i zdrowy tryb życia.</p>
                                    <p style={styles.paragraph}>Jesteśmy instytucją, która nie kieruje się wyłącznie chęcią zysku. Pieniądze mają przede wszystkim służyć - tym, do których należą. Kasa powstała "nie dla zysku, nie dla filantropii, lecz dla użyteczności publicznej". Patronem naszej Kasy jest Zygmunt Chmielewski - zasłużony działacz spółdzielczości, który wniósł ogromny wkład w rozwój kas w okresie międzywojennym. Logo SKOK przedstawia plaster miodu, który symbolizuje oszczędność, gospodarność oraz pracę zapewniającą dobrobyt i realizację zamierzeń.</p>
                                    <p style={styles.paragraph}>Jedną z podstawowych cech, jaka różni SKOK od innych instytucji, jest niezwykle przejrzysta i demokratyczna struktura. Najwyższą władzą SKOK-u jest Zebranie Przedstawicieli. Rada Nadzorcza, powoływana przez Zebranie Przedstawicieli, sprawuje kontrolę i nadzór nad działalnością Kasy. Organem statutowym, który zajmuje się prowadzeniem działalności finansowej jest Zarząd, który powołuje Komisje Kredytowe. Główna siedziba SKOK im. Z. Chmielewskiego mieści się w Lublinie, natomiast nasze oddziały zlokalizowane są w całym województwie lubelskim, a także: kujawsko-pomorskim, łódzkim, mazowieckim, podkarpackim, podlaskim, śląskim, świętokrzyskim, warmińsko-mazurskim, wielkopolskim i zachodniopomorskim.</p>
                                </div>
                            </Column>
                            <Column sm={0} md={1}></Column>
                        </Row>
                    </div>
                </Column>
                <Column sm={0} md={1}></Column>
            </Row>
        </Grid>
    );

    return (
        <HeaderFooterTemplate content={content} hideBasicInfo={true} />
    )
};

const styles = {
    box: {
        background: Colors.white,
        boxShadow: `0px 3.40014px 85.0036px ${Colors.boxShadowsColor}`,
        borderRadius: 17,
        width: '100%',
        marginBottom: 32,
    },
    photo: {
        width: '100%',
    },
    photoTitle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontWeight: 'bold',
        fontSize: 30,
        color: Colors.white,
        textAlign: 'center',
    },
    content: {
        paddingTop: 16,
        paddingBottom: 32,
        paddingLeft: 16,
        paddingRight: 16,
    },
    title: {
        fontWeight: 600,
        fontSize: 21,
        paddingBottom: 16,
        paddingTop: 32,
        textAlign: 'center',
    },
    paragraph: {
        paddingTop: 12,
        paddingBottom: 12,
    },
};

export default KnowUsBetterPage;
