import { 
    ComposedModal, 
    ModalBody, 
    ModalHeader, 
    Button
} from 'carbon-components-react';
import initialValues from '../applicationPage/formModel/initialValues';

import { Colors } from '../../utils/styleUtils/Colors';
import LeftArrow from '../../assets/left-arrow.svg';
import './UploadPhotosModal.scss';

const BiometricAgreementModal = ({open, onClose, setCurrentIndex, setAuthIndex }) => {

    const handlePrev = () => {
        onClose();
        setCurrentIndex();
        setAuthIndex();
    }
    const handleSubmit = () => {
        initialValues.biometricAgreement = true;
        onClose();
    } 

    return (
        <ComposedModal 
            preventCloseOnClickOutside 
            className="upload-modal" 
            size="md" 
            open={open} 
            onClose={onClose} 
            style={styles.modal}
        >
            <ModalHeader 
                style={styles.header} 
                onClick={handlePrev}
            >
                Zgoda na przetwarzanie danych biometrycznych
            </ModalHeader>
            <ModalBody style={styles.modalContent}>
                <p style={styles.biometricParagraph}>
                    Wyrażam zgodę na przetwarzanie moich danych biometrycznych w postaci wizerunku mojej twarzy przez Spółdzielczą Kasę Oszczędnościowo-Kredytową im. Zygmunta Chmielewskiego z siedzibą w Lublinie, ul. Wallenroda 2E jako administratora, w celu zidentyfikowania i weryfikacji mojej tożsamości. Oświadczam, że udzielenie niniejszej zgody ma charakter dobrowolny. Oświadczam również, że zostałem poinformowany o możliwości wycofania wyrażonej zgody w dowolnym momencie. Wycofanie zgody nie ma wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej wycofaniem.
                </p>
                <div style={styles.buttonsDiv}>
                    <Button 
                        style={{...styles.commonButton, ...styles.prevButton}} 
                        onClick={handlePrev}
                    >
                        <img src={LeftArrow} alt="" style={styles.icon}/>
                        Wstecz
                    </Button>
                    <Button 
                        style={{...styles.commonButton, ...styles.agreeButton}} 
                        onClick={handleSubmit}
                    >
                        Wyrażam zgodę
                    </Button>
                </div>
            </ModalBody>
        </ComposedModal>
    )
};

const styles = {
    modal: {
        padding: 16,
    },
    modalContent: {
        paddingRight: '1rem',
        textAlign: 'center',
        marginBottom: '2rem',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    biometricParagraph: {
        textAlign: 'left',
        padding: '1rem'
    },
    header: {
        fontSize: 21,
        marginLeft: 12,
        marginRight: 12,
        borderBottom: Colors.borderBottomColor,
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        paddingBottom: 12,
    },
    commonButton: {
        padding: 13,
        paddingLeft: 24,
        paddingRight: 24,
        marginLeft: 20,
        marginRight: 20,
        borderWidth: 0,
        textAlign: 'center',
        fontSize: 18,
    },
    buttonsDiv: {
        marginTop: '1rem'
    },
    agreeButton: {
        backgroundColor: Colors.orange,
        borderRadius: 4,
        fontWeight: 600,
        textDecoration: 'none',
        color: Colors.white,
    },
    prevButton: {
        backgroundColor: Colors.white,
        color: Colors.green,
        fontWeight: 500,
    },
    icon: {
        paddingRight: 12,
    },
}

export default BiometricAgreementModal;