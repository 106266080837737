import { 
    ComposedModal, 
    ModalBody, 
    ModalHeader, 
} from 'carbon-components-react';
import './UploadPhotosModal.scss';
import UploadPhotosContent from './UploadPhotosContent';
import { Colors } from '../../utils/styleUtils/Colors';

const UploadPhotosModal = props => {
    return (
        <ComposedModal className="upload-modal" size="lg" open={props.open} onClose={props.onClose} style={styles.modal}>
            <ModalHeader style={styles.header}>Weryfikacja tożsamości</ModalHeader>
            <ModalBody style={styles.modalContent}>
                <UploadPhotosContent 
                    goToForm={props.goToForm} 
                    msg={props.msg} 
                    status={props.status} 
                    onReset={props.onReset}
                    onClose={props.onClose}
                />
            </ModalBody>
        </ComposedModal>
    )
};

const styles = {
    modal: {
        padding: 16,
    },
    modalContent: {
        paddingRight: '1rem',
        textAlign: 'center',
        marginBottom: '2rem',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    header: {
        fontSize: 21,
        marginLeft: 12,
        marginRight: 12,
        borderBottom: Colors.borderBottomColor,
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        paddingBottom: 12,
    },
}

export default UploadPhotosModal;
