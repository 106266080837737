import { Slider, Row, Column, Button } from 'carbon-components-react';
import { useState, useEffect } from 'react';
import { Colors } from '../../utils/styleUtils/Colors';
import DetailsModal from './DetailsModal';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Dimension } from '../../utils/styleUtils/Dimension';
import { conditionsCalculator } from './conditionsCalculator';
import { irr, nper, pmt, pv } from 'financial';

const PersonalizedCalculator = ({data, onUpdateValues}) => {
    const {
        amount,
        installmentAmount,
        numberOfInstallments,
        maxInstallmentAmount,
        listOfProvisions,
        fees,
        score,
        interest,
        link
    } = data;
    
    const params = {
        AMOUNT: 'AMOUNT',
        INSTALLMENT_AMOUNT: 'INSTALLMENT_AMOUNT',
        NUMBER_OF_INSTALLMENTS: 'NUMBER_OF_INSTALLMENTS'
    }

    const { 
        MIN_AMOUNT, 
        MAX_AMOUNT, 
        MIN_INSTALLMENT_AMOUNT, 
        MIN_INSTALLMENTS, 
        MAX_INSTALLMENTS, 
    } = conditionsCalculator;
    
    const MAX_INSTALLMENT_AMOUNT = maxInstallmentAmount;

    const [state, setState] = useState({
        amount: 0,
        installmentAmount: 0,
        numberOfInstallments: 0,
        commission: 0,
        rrso: 0
    });

    const [lastCorrectValues, setLastCorrectValues] = useState({});
    const [detailsOpen, setDetailsOpen] = useState(false);
    const closeDetails = () => setDetailsOpen(false);
    const { width } = useWindowDimensions();

    useEffect(() => {
        let preAmount = amount;
        let preNumberOfInstallments = numberOfInstallments;
        let preCommission = getCommission(preNumberOfInstallments);
        let preCommissionValue = getCommissionValueMultiplication(preAmount, preCommission);
        let preGrossAmount = getGrossAmountAdd(preAmount, preCommissionValue);
        let preInstallmentAmount = getInstallmentAmount(preNumberOfInstallments, preGrossAmount);
        let preRrso = 0;

        if(preInstallmentAmount < MIN_INSTALLMENT_AMOUNT || preInstallmentAmount > MAX_INSTALLMENT_AMOUNT){
            preInstallmentAmount = handleMinMax(preInstallmentAmount, params.INSTALLMENT_AMOUNT);
            preNumberOfInstallments = getNumberOfInstallments(preInstallmentAmount, preGrossAmount);
            preNumberOfInstallments = handleMinMax(preNumberOfInstallments, params.NUMBER_OF_INSTALLMENTS);
            preCommission = getCommission(preNumberOfInstallments);
            preCommissionValue = getCommissionValueMultiplication(preAmount, preCommission);
            preGrossAmount = getGrossAmountAdd(preAmount, preCommissionValue);

            preNumberOfInstallments = getNumberOfInstallments(preInstallmentAmount, preGrossAmount);
            preNumberOfInstallments = handleMinMax(preNumberOfInstallments, params.NUMBER_OF_INSTALLMENTS);
            preCommission = getCommission(preNumberOfInstallments);
            preCommissionValue = getCommissionValueMultiplication(preAmount, preCommission);
            preGrossAmount = getGrossAmountAdd(preAmount, preCommissionValue);

            preNumberOfInstallments = getNumberOfInstallments(preInstallmentAmount, preGrossAmount);
            if (preNumberOfInstallments < MIN_INSTALLMENTS || preNumberOfInstallments > MAX_INSTALLMENTS || isNaN(preNumberOfInstallments)) {
                preNumberOfInstallments = handleMinMax(preNumberOfInstallments, params.NUMBER_OF_INSTALLMENTS);
                preCommission = getCommission(preNumberOfInstallments);
                preGrossAmount = getGrossAmountPV(preInstallmentAmount, preNumberOfInstallments);
                preAmount = getAmountFromGrossAmount(preGrossAmount, preCommission);
                preRrso = getRrso(preAmount, preInstallmentAmount, preNumberOfInstallments);
            } else {
                preCommission = getCommission(preNumberOfInstallments);
                preCommissionValue = getCommissionValueMultiplication(preAmount, preCommission);
                preGrossAmount = getGrossAmountAdd(preAmount, preCommissionValue);
                preRrso = getRrso(preAmount, preInstallmentAmount, preNumberOfInstallments);
            }
        } else {
            preRrso = getRrso(preAmount, preInstallmentAmount, preNumberOfInstallments);
        }

        let newValues = {
            amount: preAmount,
            installmentAmount: preInstallmentAmount,
            numberOfInstallments: preNumberOfInstallments,
            commission: preCommission,
            rrso: preRrso, 
            grossAmount: preGrossAmount,
            commissionValue: preCommissionValue
        }
        setState(newValues);
        onUpdateValues(newValues);
        setLastCorrectValues({...newValues});
    }, [link])

    const handleMinMax = (number, type) => {
        switch(type){
            case params.AMOUNT:
                if (number < MIN_AMOUNT) number = MIN_AMOUNT;
                else if (number > MAX_AMOUNT) number = MAX_AMOUNT;
                return number;
            case params.INSTALLMENT_AMOUNT:
                if (number < MIN_INSTALLMENT_AMOUNT) number = MIN_INSTALLMENT_AMOUNT;
                else if (number > MAX_INSTALLMENT_AMOUNT) number = MAX_INSTALLMENT_AMOUNT;
                return number;
            case params.NUMBER_OF_INSTALLMENTS:
                if (number < MIN_INSTALLMENTS) number = MIN_INSTALLMENTS;
                else if (number > MAX_INSTALLMENTS) number = MAX_INSTALLMENTS;
                else if (isNaN(number)) number = MAX_INSTALLMENTS;
                return number;
            default:
                return number;
        }
    }

    const getAmountFromGrossAmount = (grossAmount, commission) => Math.round(grossAmount / (1 + (commission / 100)));
    const getInstallmentAmount = (numberOfInstallments, grossAmount) => Math.round((-pmt(interest/12, numberOfInstallments, grossAmount) + Number.EPSILON) * 100) / 100;
    const getNumberOfInstallments = (installmentAmount, grossAmount) => Math.round(nper(interest/12, -installmentAmount, grossAmount));
    const getGrossAmountAdd = (amount, commissionValue) => Math.round(amount + commissionValue);
    const getGrossAmountPV = (installmentAmount, numberOfInstallments) => Math.round(pv(interest/12, numberOfInstallments, -installmentAmount));
    const getCommissionValueMultiplication = (amount, commission) => Math.round((amount * (commission / 100) + Number.EPSILON) * 100) / 100;
    const getCommission = (numberOfInstallments) => Math.round((listOfProvisions[numberOfInstallments] + Number.EPSILON) * 100) / 100; 
    const getRrso = (amount, installmentAmount, numberOfInstallments) => {
        let cf = [-amount].concat([...Array(numberOfInstallments).keys()].fill(installmentAmount));
        let rrso = (((1 + irr(cf)) ** 12) - 1) * 100; 
        rrso = Math.round((rrso + Number.EPSILON) * 100) / 100;
        return rrso;
    }
    
    const printValues = (method, values) => {
        console.log('Method: ', method);
        console.log('Values: ', values);
    }

    const handleBlurAmount = () => {
        if (state.amount !== lastCorrectValues.amount){
            let amount = handleMinMax(state.amount, params.AMOUNT);

            let installmentAmount = state.installmentAmount;
            let numberOfInstallments = state.numberOfInstallments;
            let commission = state.commission;
            let commissionValue = state.commissionValue;
            let grossAmount = state.grossAmount;
            let rrso = state.rrso;
    
            commissionValue = getCommissionValueMultiplication(amount, commission);
            grossAmount = getGrossAmountAdd(amount, commissionValue);
            installmentAmount = getInstallmentAmount(numberOfInstallments, grossAmount);
            
            if (installmentAmount < MIN_INSTALLMENT_AMOUNT || installmentAmount > MAX_INSTALLMENT_AMOUNT){
    
                installmentAmount = handleMinMax(installmentAmount, params.INSTALLMENT_AMOUNT);
    
                numberOfInstallments = getNumberOfInstallments(installmentAmount, grossAmount);
                numberOfInstallments = handleMinMax(numberOfInstallments, params.NUMBER_OF_INSTALLMENTS);
                commission = getCommission(numberOfInstallments);
                commissionValue = getCommissionValueMultiplication(amount, commission);
                grossAmount = getGrossAmountAdd(amount, commissionValue);
    
                numberOfInstallments = getNumberOfInstallments(installmentAmount, grossAmount);
                numberOfInstallments = handleMinMax(numberOfInstallments, params.NUMBER_OF_INSTALLMENTS);
                commission = getCommission(numberOfInstallments);
                commissionValue = getCommissionValueMultiplication(amount, commission);
                grossAmount = getGrossAmountAdd(amount, commissionValue);
    
                numberOfInstallments = getNumberOfInstallments(installmentAmount, grossAmount);
                if (numberOfInstallments < MIN_INSTALLMENTS || numberOfInstallments > MAX_INSTALLMENTS || isNaN(numberOfInstallments)) {
                    amount = lastCorrectValues.amount;
                    installmentAmount = lastCorrectValues.installmentAmount;
                    numberOfInstallments = lastCorrectValues.numberOfInstallments;
                    commission = lastCorrectValues.commission;
                    commissionValue = lastCorrectValues.commissionValue;
                    grossAmount = lastCorrectValues.grossAmount;
                    rrso = lastCorrectValues.rrso;
                } else {
                    commission = getCommission(numberOfInstallments);
                    commissionValue = getCommissionValueMultiplication(amount, commission);
                    grossAmount = getGrossAmountAdd(amount, commissionValue);
                    rrso = getRrso(amount, installmentAmount, numberOfInstallments);
                }
            } else {
                rrso = getRrso(amount, installmentAmount, numberOfInstallments);
            }
    
            let newValues = {
                amount: amount,
                installmentAmount: installmentAmount,
                numberOfInstallments: numberOfInstallments,
                grossAmount: grossAmount,
                commission: commission,
                rrso: rrso,
                commissionValue: commissionValue
            }
            
            setState(newValues);
            onUpdateValues(newValues);
            setLastCorrectValues({...newValues});
            printValues('amount', newValues);
        }
    }

    const handleBlurInstallmentAmount = () => {
        if (state.installmentAmount !== lastCorrectValues.installmentAmount){
            let installmentAmount = handleMinMax(state.installmentAmount, params.INSTALLMENT_AMOUNT);
        
            let amount = state.amount;
            let numberOfInstallments = state.numberOfInstallments;
            let commission = state.commission;
            let commissionValue = state.commissionValue;
            let grossAmount = state.grossAmount;
            let rrso = state.rrso;

            grossAmount = getGrossAmountPV(installmentAmount, numberOfInstallments);
            amount = getAmountFromGrossAmount(grossAmount, commission);

            if (amount < MIN_AMOUNT || amount > MAX_AMOUNT){
                amount = handleMinMax(amount, params.AMOUNT);
    
                commissionValue = getCommissionValueMultiplication(amount, commission);
                grossAmount = getGrossAmountAdd(amount, commissionValue);
                numberOfInstallments = getNumberOfInstallments(installmentAmount, grossAmount);
                numberOfInstallments = handleMinMax(numberOfInstallments, params.NUMBER_OF_INSTALLMENTS);
                commission = getCommission(numberOfInstallments);
                
                commissionValue = getCommissionValueMultiplication(amount, commission);
                grossAmount = getGrossAmountAdd(amount, commissionValue);
                numberOfInstallments = getNumberOfInstallments(installmentAmount, grossAmount);
                numberOfInstallments = handleMinMax(numberOfInstallments, params.NUMBER_OF_INSTALLMENTS);
                commission = getCommission(numberOfInstallments);
    
                commissionValue = getCommissionValueMultiplication(amount, commission);
                grossAmount = getGrossAmountAdd(amount, commissionValue);
                numberOfInstallments = getNumberOfInstallments(installmentAmount, grossAmount);
    
                if (numberOfInstallments < MIN_INSTALLMENTS || numberOfInstallments > MAX_INSTALLMENTS || isNaN(numberOfInstallments)){
                    amount = lastCorrectValues.amount;
                    installmentAmount = lastCorrectValues.installmentAmount;
                    numberOfInstallments = lastCorrectValues.numberOfInstallments;
                    commission = lastCorrectValues.commission;
                    commissionValue = lastCorrectValues.commissionValue;
                    grossAmount = lastCorrectValues.grossAmount;
                    rrso = lastCorrectValues.rrso;
                } else {
                    commission = getCommission(numberOfInstallments);
                    commissionValue = getCommissionValueMultiplication(amount, commission);
                    grossAmount = getGrossAmountAdd(amount, commissionValue);
                    rrso = getRrso(amount, installmentAmount, numberOfInstallments);
                }
            } else {
                commissionValue = getCommissionValueMultiplication(amount, commission);
                grossAmount = getGrossAmountAdd(amount, commissionValue);
                rrso = getRrso(amount, installmentAmount, numberOfInstallments);
            }
    
            let newValues = {
                amount: amount,
                installmentAmount: installmentAmount,
                numberOfInstallments: numberOfInstallments,
                grossAmount: grossAmount,
                commission: commission,
                rrso: rrso,
                commissionValue: commissionValue
            }
    
            setState(newValues);
            onUpdateValues(newValues);
            setLastCorrectValues({...newValues});
            printValues('installmentAmount', newValues)
        }
    }

    const handleBlurNumberOfInstallments = () => {
        if (state.numberOfInstallments !== lastCorrectValues.numberOfInstallments){
            let numberOfInstallments = handleMinMax(state.numberOfInstallments, params.NUMBER_OF_INSTALLMENTS);

            let amount = state.amount;
            let installmentAmount = state.installmentAmount;
            let commission = state.commission;
            let commissionValue = state.commissionValue;
            let grossAmount = state.grossAmount;
            let rrso = state.rrso;
    
            commission = getCommission(numberOfInstallments);

            grossAmount = getGrossAmountPV(installmentAmount, numberOfInstallments);
            amount = getAmountFromGrossAmount(grossAmount, commission);
    
            if (amount < MIN_AMOUNT || amount > MAX_AMOUNT){
                amount = handleMinMax(amount, params.AMOUNT);
    
                commissionValue = getCommissionValueMultiplication(amount, commission);
                grossAmount = getGrossAmountAdd(amount, commissionValue);
                installmentAmount = getInstallmentAmount(numberOfInstallments, grossAmount);
    
                if (installmentAmount < MIN_INSTALLMENT_AMOUNT || installmentAmount > MAX_INSTALLMENT_AMOUNT){
                    amount = lastCorrectValues.amount;
                    installmentAmount = lastCorrectValues.installmentAmount;
                    numberOfInstallments = lastCorrectValues.numberOfInstallments;
                    commission = lastCorrectValues.commission;
                    commissionValue = lastCorrectValues.commissionValue;
                    grossAmount = lastCorrectValues.grossAmount;
                    rrso = lastCorrectValues.rrso;
                } else {
                    rrso = getRrso(amount, installmentAmount, numberOfInstallments);
                }
    
            } else {
                commissionValue = getCommissionValueMultiplication(amount, commission);
                grossAmount = getGrossAmountAdd(amount, commissionValue);
                rrso = getRrso(amount, installmentAmount, numberOfInstallments);
            }
            let newValues = {
                amount: amount,
                installmentAmount: installmentAmount,
                numberOfInstallments: numberOfInstallments,
                grossAmount: grossAmount,
                commission: commission,
                rrso: rrso,
                commissionValue: commissionValue
            }
            
            setState(newValues);
            onUpdateValues(newValues);
            setLastCorrectValues({...newValues});
            printValues('numberOfInstallments', newValues);
        }
    }

    const handleChange = (name, val) => setState({...state, [name]: val});

    return (
        <div style={width > Dimension.md ? styles.container : styles.containerMd}>
            <div style={width > Dimension.smMd ? styles.calculatorDiv : {...styles.calculatorDiv, marginLeft: 0, marginRight: 0}}>
                <Row style={width > Dimension.smMd ? {} : { flexDirection: 'column' }}>
                    <Column style={width > Dimension.smMd ? styles.box : {...styles.box, ...styles.flexCenter}}>
                        <p style={styles.normalText}>Ile chcesz pożyczyć?</p>
                        <div style={styles.inline}>
                            <input 
                                style={{...styles.bigText, width: parseInt(state.amount).toString().length * 23 + 20 + 'px'}} 
                                type="number" 
                                min={MIN_AMOUNT}
                                value={parseInt(state.amount).toString()} 
                                onChange={e => setState(prevState => {
                                    let val = e.target.value.replace(/^0+/, '');
                                    val = Math.abs(val) > MAX_AMOUNT ? MAX_AMOUNT : Math.abs(val);
                                    return {...prevState, amount: val};
                                })}
                                onBlur={() => handleBlurAmount()}
                            />
                            <p style={styles.mediumText}>zł</p>
                        </div>
                        <Slider 
                            min={MIN_AMOUNT}
                            max={MAX_AMOUNT}
                            step={500}
                            value={state.amount}
                            hideTextInput
                            formatLabel={() => <></>}
                            style={styles.slider}
                            onChange={res => handleChange("amount", res.value)}
                            onRelease={() => handleBlurAmount()}
                        />
                    </Column>
                    <Column style={width > Dimension.smMd ? styles.box : {...styles.box, ...styles.flexCenter}}>
                        <p style={styles.normalText}>Jaką ratę możesz płacić?</p>
                        <div style={styles.inline}>
                            <input 
                                style={{...styles.bigText, width: (Math.round(state.installmentAmount * 100) / 100).toString().length * 23 + 20 + 'px'}} 
                                type="number" 
                                step="0.1"
                                min={MIN_INSTALLMENT_AMOUNT}
                                value={(Math.round(state.installmentAmount * 100) / 100).toString()} 
                                onChange={e => setState(prevState => {
                                    let val = e.target.value.replace(/^0+/, '');
                                    val = Math.abs(val) > MAX_INSTALLMENT_AMOUNT ? MAX_INSTALLMENT_AMOUNT : Math.abs(val);
                                    return {...prevState, installmentAmount: val};
                                })}
                                onBlur={() => handleBlurInstallmentAmount()}
                            />
                            <p style={styles.mediumText}>zł</p>
                        </div>
                        <Slider 
                            min={MIN_INSTALLMENT_AMOUNT}
                            max={MAX_INSTALLMENT_AMOUNT}
                            value={state.installmentAmount}
                            step="0.5"
                            hideTextInput
                            formatLabel={() => <></>}
                            style={styles.slider}
                            onChange={res => handleChange("installmentAmount", res.value)}
                            onRelease={() => handleBlurInstallmentAmount()}
                        />
                    </Column>
                    <Column style={width > Dimension.smMd ? styles.box : {...styles.box, ...styles.flexCenter}}>
                        <p style={styles.normalText}>Okres spłaty</p>
                        <div style={styles.inline}>
                        <input 
                                style={{...styles.bigText, width: state.numberOfInstallments.toString().length * 23 + 20 + 'px'}} 
                                type="number" 
                                min={MIN_INSTALLMENTS}
                                value={parseInt(state.numberOfInstallments).toString()} 
                                onChange={e => setState(prevState => {
                                    let val = e.target.value.replace(/^0+/, '');
                                    val = Math.abs(val) > MAX_INSTALLMENTS? MAX_INSTALLMENTS: Math.abs(val);
                                    return {...prevState, numberOfInstallments: val}
                                })}
                                onBlur={() => handleBlurNumberOfInstallments()}
                            />
                            <p style={styles.mediumText}>mies.</p>
                        </div>
                        <Slider 
                            min={MIN_INSTALLMENTS}
                            max={MAX_INSTALLMENTS}
                            value={state.numberOfInstallments}
                            hideTextInput
                            formatLabel={() => <></>}
                            style={styles.slider}
                            onChange={res => handleChange("numberOfInstallments", res.value)}
                            onRelease={() => handleBlurNumberOfInstallments()}
                        />
                    </Column>
                </Row>
            </div>
            <div style={{...styles.calculatorDiv, ...styles.detailsDiv}}>
                    <Button style={styles.button} onClick={() => setDetailsOpen(true)}>
                        Szczegóły kosztów
                    </Button>
                    <DetailsModal 
                        open={detailsOpen}
                        onClose={closeDetails}
                        amount={state.amount}
                        numberOfInstallments={state.numberOfInstallments}
                        rrso={state.rrso}
                        installmentAmount={state.installmentAmount}
                        interest={interest}
                        commission={state.commission}
                        commissionValue={state.commissionValue}
                        grossAmount={state.grossAmount}
                    />
            </div>
        </div>
    )
}

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
    },
    containerMd: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    calculatorDiv: {
        background: Colors.white,
        boxShadow: `0px 3.40014px 85.0036px ${Colors.boxShadowsColor}`,
        borderRadius: 16,
        marginTop: 16,
        marginLeft: 12,
        marginRight: 12,
        paddingTop: 12,
        paddingLeft: 20,
        display: 'flex',
    },
    detailsDiv: {
        alignItems: 'center',
        paddingRight: 20,
    },
    normalText: {
        fontWeight: 500,
        fontSize: 15,
        color: Colors.black,
        opacity: 0.8,
        paddingRight: 24,
    },
    bigText: {
        fontWeight: 500,
        fontSize: 36,
        color: Colors.black,
        lineHeight: 0.8,
        border: 'none'
    },
    mediumText: {
        fontWeight: 500,
        fontSize: 18,
        color: Colors.black,
        lineHeight: 1,
        paddingLeft: 4,
        paddingBottom: 8,
    },
    slider: {
        marginTop: 16,
        marginBottom: 32,
        marginLeft: 4,
    },
    inline: {
        display: 'flex',
        alignItems: 'flex-end',
        marginBottom: 4,
    },
    box: {
        paddingRight: 12,
    },
    button: {
        background: Colors.white,
        border: `2px solid ${Colors.mystic}`,
        borderRadius: 4,
        color: Colors.lightGreen,
        fontSize: 15,
        fontWeight: 600,
        paddingLeft: 16,
        paddingRight: 16,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 8,
    },
    flexCenter: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    }
}

export default PersonalizedCalculator;
