const conditionsCalculator = Object.freeze({
                                                MIN_AMOUNT: 1000,
                                                MAX_AMOUNT: 60000,
                                                MAX_MAX_AMOUNT: 999999,
                                                MIN_INSTALLMENT_AMOUNT: 50,
                                                MIN_INSTALLMENTS:9,
                                                MAX_INSTALLMENTS:84,
                                                MAX_INSTALLMENTS_AMOUNT:5000,
                                                DEFAULT_AMOUNT:12000,
                                                DEFAULT_INSTALLMENT_AMOUNT: 1000
                                            })
export { conditionsCalculator }
