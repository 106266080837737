import React, { useEffect, useState } from 'react';
import { Grid, Slider } from 'carbon-components-react';
import { Link } from 'react-router-dom';
import { Colors } from '../../utils/styleUtils/Colors';
import crypto from 'crypto';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Dimension } from '../../utils/styleUtils/Dimension';
import { conditionsCalculator } from './conditionsCalculator';
import { parse } from 'path';
import AboveMaxModal from './AboveMaxModal';

const InitialCreditCalculator = () => {
    const { 
            MIN_AMOUNT, 
            MAX_AMOUNT, 
            MAX_MAX_AMOUNT,
            MIN_INSTALLMENT_AMOUNT, 
            MIN_INSTALLMENTS, 
            MAX_INSTALLMENTS, 
            MAX_INSTALLMENTS_AMOUNT,
            DEFAULT_AMOUNT,
            DEFAULT_INSTALLMENT_AMOUNT
        } = conditionsCalculator    
    const { width } = useWindowDimensions();
    const [amount, setAmount] = useState(DEFAULT_AMOUNT);
    const [installmentAmount, setInstallmentAmount] = useState(DEFAULT_INSTALLMENT_AMOUNT);
    const [numberOfInstallments, setNumberOfInstallments] = useState(DEFAULT_AMOUNT / DEFAULT_INSTALLMENT_AMOUNT);
    const hash = crypto.randomBytes(8).toString('hex');
    const [modalOpen, setModalOpen] = useState(false);
    
    useEffect(() => {
        if (amount > MAX_AMOUNT){
            setInstallmentAmount(DEFAULT_INSTALLMENT_AMOUNT);
            setNumberOfInstallments(DEFAULT_AMOUNT / DEFAULT_INSTALLMENT_AMOUNT);
        }
    })

    const handleBlur = () => {
        let tmpAmount = amount;
        let tmpInstallmentsAmount = installmentAmount;
        if (tmpAmount < MIN_AMOUNT) tmpAmount = MIN_AMOUNT;
        else if (tmpAmount > MAX_MAX_AMOUNT) tmpAmount = MAX_AMOUNT;
        else if (Math.ceil(tmpAmount / tmpInstallmentsAmount)> MAX_INSTALLMENTS) tmpInstallmentsAmount = tmpAmount / MAX_INSTALLMENTS;
        else if (Math.ceil(tmpAmount / tmpInstallmentsAmount)<MIN_INSTALLMENTS) tmpInstallmentsAmount = tmpAmount / MIN_INSTALLMENTS;
        else if (tmpAmount < tmpInstallmentsAmount) tmpInstallmentsAmount = tmpAmount / MIN_INSTALLMENTS; 
        else if (tmpInstallmentsAmount < MIN_INSTALLMENT_AMOUNT) tmpInstallmentsAmount = MIN_INSTALLMENT_AMOUNT;
        else if (tmpInstallmentsAmount > MAX_INSTALLMENTS_AMOUNT) tmpInstallmentsAmount = MAX_INSTALLMENTS_AMOUNT;
        setAmount(tmpAmount);
        setInstallmentAmount(Math.ceil(tmpInstallmentsAmount));
        setNumberOfInstallments(Math.ceil(tmpAmount / tmpInstallmentsAmount));
    }

    const handleBlurNumber = () => {
        let tmpAmount = amount;
        let tmpNumberOfInstallments = numberOfInstallments;
        if (tmpAmount / tmpNumberOfInstallments > MAX_INSTALLMENTS_AMOUNT) tmpNumberOfInstallments = tmpAmount / MAX_INSTALLMENTS_AMOUNT;
        else if (tmpAmount / tmpNumberOfInstallments < MIN_INSTALLMENT_AMOUNT) tmpNumberOfInstallments = tmpAmount / MIN_INSTALLMENT_AMOUNT;
        setAmount(tmpAmount);
        setInstallmentAmount(Math.ceil(tmpAmount / tmpNumberOfInstallments))
        setNumberOfInstallments(tmpNumberOfInstallments);
    }

    return (
        <div style={styles.root}>
            <Grid style={width > Dimension.oneRow ? styles.calculatorDiv : {...styles.calculatorDiv, flexDirection: 'column', marginTop: '2rem', width: '100%'}}>
                <div style={width > Dimension.sm ? styles.flex : styles.flexSm}>
                    <div style={width > Dimension.sm ? styles.box : {...styles.box, ...styles.centered, flexDirection: 'column', paddingBottom: 0}}>
                        <p style={width > Dimension.sm ? styles.normalText : {...styles.normalText, fontSize: 16, paddingRight: 0}}>Ile chcesz pożyczyć?</p>
                        <div style={styles.inline}>
                            <input 
                                style={width > Dimension.sm ? {...styles.inputText, width: amount.toString().length * 32 + 18 + 'px'} : {...styles.inputText, fontSize: 36, width: amount.toString().length * 23 + 18 + 'px'}} 
                                type="number" 
                                value={amount.toString()} 
                                onChange={e => setAmount(prevState => {
                                    const val = e.target.value.replace(/^0+/, '').replace(/\.|,/g, '');
                                    return parseInt(val) > MAX_MAX_AMOUNT ? prevState : parseInt(Math.abs(val));
                                })}
                                onBlur={() => handleBlur()}
                                min={MIN_AMOUNT}
                            />
                            <p style={styles.mediumTextInput}>zł</p>
                        </div>
                        <Slider 
                            min={MIN_AMOUNT}
                            max={MAX_AMOUNT}
                            step={100}
                            value={parseInt(amount)}
                            hideTextInput
                            formatLabel={() => <></>}
                            style={styles.slider}
                            onChange={e => setAmount(e.value)}
                            onRelease={() => handleBlur()}
                        />
                    </div>
                    <div style={width > Dimension.sm ? {...styles.box, paddingRight: 0, paddingLeft: 0 }: {...styles.box, ...styles.centered, flexDirection: 'column', paddingBottom: 0}}>
                        <p style={width > Dimension.sm ? {...styles.normalText, paddingLeft: 0, paddingRight: 6} : {...styles.normalText, fontSize: 16, paddingRight: 0}}>Jaką ratę możesz płacić?</p>
                        {amount > MAX_AMOUNT ? 
                            <>
                                <p style={styles.aboveMaxText}>- zł</p>
                                <hr style={styles.hr}/>
                            </>
                        :
                            <>
                                <div style={styles.inline}>
                                    <input 
                                        style={width > Dimension.sm ? {...styles.inputText, width: installmentAmount.toString().length * 32 + 18 + 'px'} : {...styles.inputText, fontSize: 36, width: installmentAmount.toString().length * 23 + 18 + 'px'}} 
                                        type="number"
                                        value={installmentAmount.toString()} 
                                        onChange={e => setInstallmentAmount(prevState => {
                                            const val = e.target.value.replace(/^0+/, '').replace(/\.|,/g, '');
                                            return parseInt(val) > MAX_MAX_AMOUNT ? prevState : Math.abs(val);
                                        })}
                                        onBlur={() => handleBlur()}
                                        min={MIN_INSTALLMENT_AMOUNT}
                                    />
                                    <p style={styles.mediumTextInput}>zł</p>
                                </div>
                                <Slider 
                                    min={MIN_INSTALLMENT_AMOUNT}
                                    max={MAX_INSTALLMENTS_AMOUNT}
                                    step={1}
                                    value={parseInt(installmentAmount)}
                                    hideTextInput
                                    formatLabel={() => <></>}
                                    style={styles.slider}
                                    onChange={e => setInstallmentAmount(e.value)}
                                    onRelease={() => handleBlur()}
                                />
                            </>
                        }
                    </div>
                </div>
                <div style={width > Dimension.sm ? (width > Dimension.oneRow ? styles.flex : styles.bottomDiv) : styles.flexSm }>
                    <div style={width > Dimension.sm ? styles.box : {...styles.box, ...styles.centered, flexDirection: 'column', paddingBottom: 0}}>
                        <p style={width > Dimension.sm ? styles.normalText : {...styles.normalText, fontSize: 16, paddingRight: 0}}>Okres spłaty</p>
                        {amount > MAX_AMOUNT ?
                            <>
                                <p style={styles.aboveMaxText}>- mies.</p>
                                <hr style={styles.hr}/>
                            </>
                        :
                            <>
                                <div style={styles.inline}>
                                    <input
                                        style={width > Dimension.sm ? {...styles.inputText, width: numberOfInstallments.toString().length * 32 + 18 + 'px'} : {...styles.inputText, fontSize: 36, width: numberOfInstallments.toString().length * 23 + 18 + 'px'}} 
                                        type="number"
                                        value={numberOfInstallments.toString()}
                                        min={MIN_INSTALLMENTS}
                                        onChange={e => setNumberOfInstallments(prevState => {
                                            const val = e.target.value.replace(/^0+/, '').replace(/\.|,/g, '');
                                            return parseInt(val) > MAX_INSTALLMENTS ? prevState : Math.abs(val);
                                        })}
                                        onBlur={()=> handleBlurNumber()}
                                    />
                                    <p style={styles.mediumText}>mies.</p>
                                </div>
                                <Slider
                                    min={MIN_INSTALLMENTS}
                                    max={MAX_INSTALLMENTS}
                                    step={1}
                                    value={parseInt(numberOfInstallments)}
                                    hideTextInput
                                    formatLabel={() => <></>}
                                    style={styles.slider}
                                    onChange={e => setNumberOfInstallments(e.value)}
                                    onRelease={() => handleBlurNumber()}
                                />
                            </>
                        }
                    </div>
                    <div style={{...styles.box, ...styles.centered}}>
                        {amount > MAX_AMOUNT ? 
                            <button
                                onClick={() => setModalOpen(true)}
                                style={width > Dimension.oneRow  ? styles.button : (width > Dimension.md ? {...styles.button, marginRight: 40} : {...styles.button, fontSize: 15})}
                            >
                                Składam wniosek
                            </button>
                        :
                            <Link 
                                to={{pathname: '/wniosek', hash: hash, state: { amount, installmentAmount, numberOfInstallments ,prevPath: window.location.pathname }}} 
                                style={width > Dimension.oneRow  ? styles.button : (width > Dimension.md ? {...styles.button, marginRight: 40} : {...styles.button, fontSize: 15})}
                            >
                                Składam wniosek
                            </Link>
                        }
                        <AboveMaxModal
                            open={modalOpen}
                            onClose={()=> setModalOpen(false)}
                            link={{pathname: '/wniosek', hash: hash, state: { amount: 60000, installmentAmount: 715, numberOfInstallments: 84, prevPath: window.location.pathname }}}
                        />
                    </div>
                </div>
            </Grid>
            <div style={width > Dimension.oneRow ? styles.exampleInfoLg : (width > Dimension.md ? styles.exampleInfo : {...styles.exampleInfo, textAlign: 'center'})}>
                <p style={width > Dimension.sm ? styles.exampleText : {...styles.exampleText, fontSize: 14}}>Powyższa kalkulacja jest przykładowa. </p>
                <p style={width > Dimension.sm ? styles.exampleText : {...styles.exampleText, fontSize: 14}}>Złóż wniosek, aby sprawdzić ofertę dopasowaną do Ciebie.</p>
            </div>
            <div style={styles.regulations}>
                <div style={{maxWidth: '51rem', marginBottom: '0.3rem'}}>
                    <p style={{...styles.exampleText, fontSize: width > Dimension.sm ? 10 : 9}}>
                        Przykład reprezentatywny: Całkowita kwota pożyczki 13 100,00 zł, umowa 
                        zawarta w dniu 12.05.2022 r. na okres 42 miesięcy z oprocentowaniem 
                        zmiennym rocznym 10,50%, prowizją od kwoty pożyczki w wysokości 1 100,40 
                        zł (tj. 8,40%), opłatą przygotowawczą w wysokości 0 zł. Rzeczywista 
                        Roczna Stopa Oprocentowania wynosi 17,04%, całkowita kwota do zapłaty 
                        wynosi 16 817,16 zł, całkowity koszt pożyczki wynosi 3 717,16 zł (w tym 
                        kwota odsetek 2 616,76 zł ), a kwota raty miesięcznej wynosi 374,21 zł.
                    </p>
                </div>
                <p>
                    <a href="docs/Regulamin.pdf" target="_blank" style={styles.link}>Zapoznaj się z regulaminem</a>
                </p>
                <p>
                    <a href="docs/Przetwarzanie-danych-osobowych-przez-SKOK-Chmieleskiego.pdf" target="_blank" style={styles.link}>Poznaj jak przetwarzane są dane osobowe przez SKOK im. Z. Chmielewskiego</a>
                </p>
                <p>
                    <a href="docs/Przetwarzanie-danych-osobowych-przez-BIK.pdf" target="_blank" style={styles.link}>Poznaj jak przetwarzane są dane osobowe przez BIK S.A.</a>
                </p>
            </div>
        </div>
    )
}

const styles = {
    root: {
        maxWidth: 1100,
    },
    calculatorDiv: {
        width: 'fit-content',
        display: 'flex',
        background: Colors.white,
        boxShadow: `0px 3.40014px 85.0036px ${Colors.boxShadowsColor}`,
        borderRadius: 16,
        marginTop: '2rem',
        marginLeft: 0,
        marginRight: 0,
        paddingLeft: 0,
        paddingRight: 0,
    },
    exampleInfo: {
        width: '100%',
        padding: 12,
        marginTop: 12,
    },
    exampleInfoLg: {
        width: '100%',
        padding: 12,
        marginTop: 12,
        display: 'flex',
        whiteSpace: 'break-spaces',
    },
    exampleText: {
        fontWeight: 500,
        fontSize: 16,
        color: Colors.black,
        opacity: 0.8,
    },
    normalText: {
        fontWeight: 500,
        fontSize: 20,
        color: Colors.black,
        opacity: 0.8,
        paddingRight: 24,
    },
    inputText: {
        fontWeight: 500,
        fontSize: 52,
        color: Colors.black,
        lineHeight: 0.8,
        borderRadius: '10px',
        border: `1px solid ${Colors.chineseWhite}` 
    },
    bigText: {
        fontWeight: 500,
        fontSize: 52,
        color: Colors.black,
        lineHeight: 0.8,
    },
    mediumTextInput: {
        fontWeight: 500,
        fontSize: 24,
        color: Colors.black,
        lineHeight: 1,
        paddingLeft: 2,
        paddingBottom: 10,
    },
    aboveMaxText: {
        whiteSpace: 'nowrap',
        fontWeight: 500,
        fontSize: 36,
        color: Colors.black,
        lineHeight: 1.5,
        marginRight: 12, 
        marginLeft: 12,
        paddingBottom: 10,
    },
    hr: {
        width: 235,
        height: 1,
        opacity: 0,
    },
    mediumText: {
        fontWeight: 500,
        fontSize: 24,
        color: Colors.black,
        lineHeight: 1,
        paddingLeft: 8,
    },
    slider: {
        marginTop: 16,
        marginBottom: 32,
        marginLeft: 4,
    },
    inline: {
        display: 'flex',
        alignItems: 'flex-end',
        marginBottom: 8,
    },
    box: {
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 24,
        paddingRight: 24,
    },
    button: {
        backgroundColor: Colors.orange,
        borderRadius: 4,
        border: 0,
        width: '220px',
        height: '65px',
        fontSize: 18,
        fontWeight: 550,
        textAlign: 'center',
        padding: 24,
        paddingLeft: 28,
        paddingRight: 28,
        textDecoration: 'none',
        color: Colors.white,
        cursor: 'pointer'
    },
    centered: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
    flex: {
        display: 'flex',
    },
    flexSm: {
        display: 'flex',
        flexDirection: 'column',
    },
    bottomDiv: {
        display: 'flex',
        paddingBottom: 16,
        justifyContent: 'space-between'
    },
    regulations: {
        marginLeft: '0.75rem',
    },
    link: {
        color: Colors.green,
        textDecoration: 'none',
        fontSize: '.85rem'
    },
}

export default InitialCreditCalculator;
